import AddLowerTierParticipationDialog from 'components/contract/AddLowerTierParticipationDialog/AddLowerTierParticipationDialog';
import DeleteLowerTierParticipationDialog from 'components/contract/DeleteLowerTierParticipationDialog';
import EditContractDialog from 'components/contract/EditContractDialog';
import EditLowerTierParticipationDialog from 'components/contract/EditLowerTierParticipationDialog/EditLowerTierParticipationDialog';
import ViewLowerTierParticipationDialog from 'components/contract/ViewLowerTierParticipationDialog';
import SmallBusinessClassificationDisplayName from 'components/shared/SmallBusinessClassificationDisplayName';
import StyledTable from 'components/shared/Table';
import { SmallBusinessClassificationName } from 'constants/classificationNames';
import { SmallBusinessRequirementOption } from 'constants/smallBusinessRequirementOptions';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { FC, MouseEvent, useState } from 'react';
import NumberFormat from 'react-number-format';
import { SmallBusinessFederalReportFormOptionSelection } from 'recoil/smallBusinessFederalReportFormOption/atom';
import {
  GetSingleProjectQuery,
  GetSmallBusinessClassificationsQuery,
  LowerTierParticipationClassificationSummary,
  LowerTierParticipationFileSummary,
  SmallBusinessClassification,
  SmallBusinessClient,
  SmallBusinessReportClassificationSummary,
  SmallBusinessReportContractSummary,
  SmallBusinessReportSettings,
} from 'types/generated/graphql';
import { formatDate } from 'utils/general';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Grid,
  IconButton,
  Link,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

const classificationColumn: SxProps<Theme> = {
  fontWeight: 'bold',
};

const classificationColumnInactive: SxProps<Theme> = {
  fontWeight: 'normal',
  color: '#c1c1c1',
};

const actionsContainer: SxProps<Theme> = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: { justifyContent: 'flex-start' },
});

const companyLink: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.secondary.contrastText,
});

const activeClassificationIcon: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.success.main,
  fontSize: '1rem',
});

const classificationIconInactive: SxProps<Theme> = {
  color: 'rgba(0,0,0,0)',
};

const hiddenCell: SxProps<Theme> = {
  position: 'sticky',
  left: '0px',
  zIndex: 100,
  width: '56px',
};

const lowerTierParentCell: SxProps<Theme> = {
  padding: '0 !important',
};

const secondTierCell: SxProps<Theme> = {
  width: '270px',
};

const selfWorkHiddenCell: SxProps<Theme> = {
  width: '192px',
};

const lowerTierHiddenCell: SxProps<Theme> = {
  width: '289px',
};

const lowerTierCompanyNameCell: SxProps<Theme> = {
  width: '222px',
};

const lowerTierExecutionDateCell: SxProps<Theme> = {
  width: '297px',
};

const lowerTierIsLargeBusiness: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.error.light,
  fontSize: '1rem',
});

type PickedSmallBusinessClassification = Pick<
  SmallBusinessClassification,
  'id' | 'name' | 'abbreviation' | 'sortOrder'
>;

type SmallBusinessClassificationSelection = GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'];

type SmallBusinessContractTableProps = {
  classifications: PickedSmallBusinessClassification[];
  contractSummaryData: SmallBusinessReportContractSummary[];
  project?: GetSingleProjectQuery['project'];
  editable?: boolean;
  smallBusinessReportSettings?: SmallBusinessReportSettings;
  smallBusinessReportClassificationSummaries?: SmallBusinessReportClassificationSummary[];
  allSmallBusinessClassifications: SmallBusinessClassificationSelection;
  selectedReportOption?: SmallBusinessFederalReportFormOptionSelection;
};

const SmallBusinessContractTable: FC<SmallBusinessContractTableProps> = ({
  classifications,
  contractSummaryData,
  project,
  editable,
  smallBusinessReportSettings,
  smallBusinessReportClassificationSummaries,
  allSmallBusinessClassifications,
  selectedReportOption,
}) => {
  const [contractToEdit, setContractToEdit] = useState<SmallBusinessReportContractSummary | null>(null);
  const [isEditContractDialogOpen, setIsEditContractDialogOpen] = useState(false);

  const [lowerTierParticipationToAdd, setLowerTierParticipationToAdd] =
    useState<LowerTierParticipationClassificationSummary | null>(null);
  const [lowerTierParticipation, setLowerTierParticipation] = useState<LowerTierParticipationClassificationSummary>();
  const [isAddLowerTierParticipationDialogOpen, setIsAddLowerTierParticipationDialogOpen] = useState(false);
  const [isDeleteLowerTierParticipationDialogOpen, setIsDeleteLowerTierParticipationDialogOpen] = useState(false);
  const [isViewLowerTierParticipationDialogOpen, setIsViewLowerTierParticipationDialogOpen] = useState(false);
  const [isEditLowerTierParticipationDialogOpen, setIsEditLowerTierParticipationDialogOpen] = useState(false);
  const smallBusinessClient = project?.smallBusinessClient as SmallBusinessClient;

  const isNonFederal = project?.smallBusinessRequirement === SmallBusinessRequirementOption.NON_FEDERAL;
  const isHpSelfReporting = project?.smallBusinessRequirement === SmallBusinessRequirementOption.HP_SELF_REPORTING;

  const contractId = lowerTierParticipationToAdd
    ? lowerTierParticipationToAdd.contractId
    : lowerTierParticipation?.contractId;

  const contractSummaryDataByContractId = contractSummaryData.find((contract) => contract.contractId === contractId);

  const handleEditContract = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, dataIndex: number) => {
    event.stopPropagation();
    event.preventDefault();
    setContractToEdit(contractSummaryData[dataIndex]);
    setIsEditContractDialogOpen(true);
  };

  const handleAddLowerTierParticipation = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    dataIndex: number,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setLowerTierParticipationToAdd(
      contractSummaryData[dataIndex] as unknown as LowerTierParticipationClassificationSummary,
    );
    setIsAddLowerTierParticipationDialogOpen(true);
  };

  const handleDeleteLowerTierParticipation = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    lowerTierParticipationSummary: LowerTierParticipationClassificationSummary,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setLowerTierParticipation(lowerTierParticipationSummary);
    setIsDeleteLowerTierParticipationDialogOpen(true);
  };

  const handleViewLowerParticipationDocument = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    lowerTierParticipationSummary: LowerTierParticipationClassificationSummary,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setLowerTierParticipation(lowerTierParticipationSummary);
    setIsViewLowerTierParticipationDialogOpen(true);
  };

  const handleEditLowerParticipation = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    lowerTierParticipationSummary: LowerTierParticipationClassificationSummary,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setLowerTierParticipation(lowerTierParticipationSummary);
    setIsEditLowerTierParticipationDialogOpen(true);
  };

  const highlightContractsAndLowerTierParticipationRow = (contractSummary: SmallBusinessReportContractSummary) => {
    const lowerTierParticipationClassificationSummaries =
      contractSummary?.lowerTierParticipationClassificationSummaries.filter((summary) =>
        summary.smallBusinessClassifications.some(
          (smallBusinessClassification) => smallBusinessClassification.abbreviation === 'LB',
        ),
      ) ?? [];

    const contractSummaryIncludesSmallBusiness = contractSummary?.smallBusinessClassifications.some(
      (classification) => classification.abbreviation !== 'LB',
    );

    const contractSummaryValueTotal = contractSummary?.valueTotal;
    const contractSummaryThresholdPercentage = 0.8;
    const contractSummaryThreshold = contractSummaryValueTotal * contractSummaryThresholdPercentage;

    const haveLowerTierParticipationValuesReachedThreshold = lowerTierParticipationClassificationSummaries.filter(
      (summary) => {
        const isLowerTierValueWithinContractSummaryThreshold =
          Math.round(contractSummaryThreshold * 100) / 100 < summary.value;
        return isLowerTierValueWithinContractSummaryThreshold;
      },
    );

    if (contractSummaryIncludesSmallBusiness && haveLowerTierParticipationValuesReachedThreshold) {
      return haveLowerTierParticipationValuesReachedThreshold;
    }
  };

  let smallBusinessClassificationsCopy: SmallBusinessClassification[] = JSON.parse(JSON.stringify(classifications));
  const smallBusinessClassificationsById = smallBusinessClassificationsCopy.reduce<{
    [id: string]: SmallBusinessClassificationSelection[number];
  }>((accumulator, classification) => {
    accumulator[classification.id] = classification;
    return accumulator;
  }, {});

  const contractSummaryListColumns: MUIDataTableColumn[] = [
    {
      name: 'companyId',
      label: 'CompanyId',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'contractId',
      label: 'Id',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      name: 'phaseCode',
      label: 'Contract Number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'companyName',
      label: 'Company',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const contractSummary = contractSummaryData[dataIndex];
          const companyId = contractSummary?.companyId;
          return (
            companyId && (
              <Link href={`/companies/${companyId}`} target="_blank" sx={companyLink}>
                <Typography>{contractSummary?.companyName}</Typography>
              </Link>
            )
          );
        },
      },
    },
    {
      name: 'isSelfWork',
      label: 'HP Self Work',
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
        },
        download: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const contractSummary = contractSummaryData[dataIndex];
          const isSelfWork = contractSummary?.isSelfWork;
          return (
            isSelfWork && (
              <Grid container alignItems="center">
                <Grid item>
                  <CheckIcon sx={activeClassificationIcon} />
                </Grid>
              </Grid>
            )
          );
        },
      },
    },
    {
      name: 'hasLowerTierParticipation',
      label: 'Lower Tier Participation',
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
        },
        download: false,
        sort: true,
        display: project?.smallBusinessRequirement === SmallBusinessRequirementOption.NON_FEDERAL ?? 'excluded',
        customBodyRenderLite: (dataIndex) => {
          const lowerTierParticipationClassificationSummaries =
            contractSummaryData[dataIndex].lowerTierParticipationClassificationSummaries;
          const hasLowerTierParticipation =
            lowerTierParticipationClassificationSummaries && lowerTierParticipationClassificationSummaries.length > 0;
          return (
            hasLowerTierParticipation && (
              <Grid container alignItems="center">
                <Grid item>
                  <CheckIcon sx={activeClassificationIcon} />
                </Grid>
              </Grid>
            )
          );
        },
      },
    },
    {
      name: 'hasLowerTierParticipation',
      label: '',
      options: {
        filter: false,
        display: 'excluded',
        customBodyRenderLite: (dataIndex) => {
          return highlightContractsAndLowerTierParticipationRow(contractSummaryData[dataIndex]);
        },
      },
    },
    {
      name: 'effectiveDate',
      label: 'Contract Date Execution',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const contractSummary = contractSummaryData[dataIndex];
          const date = contractSummary?.effectiveDate;
          const formattedDate = date !== null ? formatDate(date) : null;
          return <Typography>{formattedDate}</Typography>;
        },
      },
    },
    {
      name: 'smallBusinessClassifications',
      label: 'Classifications',
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
          names: classifications.map((classification) => classification.id),
          renderValue: (id) => smallBusinessClassificationsById[id].abbreviation,
          logic: (classification, filters) => {
            const copyOfClassifications = classification as unknown as SmallBusinessClassification[];

            const smallBusinessClassificationIds =
              copyOfClassifications?.map((classification) => classification.id) ?? [];

            if (filters.length) {
              return !smallBusinessClassificationIds.some((classificationId) => filters.includes(classificationId));
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          },
        },
        customFilterListOptions: {
          render: (id) => smallBusinessClassificationsById[id].abbreviation,
        },
        filterType: 'multiselect',
        searchable: true,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          const activeClassifications = contractSummaryData[dataIndex]?.smallBusinessClassifications;
          const activeClassificationIds = activeClassifications.map((classification) => classification.id);
          const classificationsToDisplay = classifications.filter(
            (classification) =>
              classification.name !== SmallBusinessClassificationName.SBT &&
              activeClassificationIds?.includes(classification.id),
          );
          const classificationsToDisplayIds = classifications.map((classification) => classification.id);
          const unsupportedClassifications = activeClassifications.filter(
            (classification) => !classificationsToDisplayIds?.includes(classification.id),
          );
          const isLargeBusiness =
            (contractSummaryData[dataIndex]?.companyName !== '' && !activeClassifications.length) ||
            unsupportedClassifications.length;

          if (!classificationsToDisplay.length) {
            classificationsToDisplay.push({
              id: 'LB',
              abbreviation: 'LB',
              name: 'Large Business',
              sortOrder: -1,
            });
          }

          return (
            <Grid container direction="row" spacing={2}>
              {classificationsToDisplay.map((classification) => (
                <Grid item key={classification.id}>
                  <Grid container alignItems="center" direction="row" spacing={1}>
                    <Grid item>
                      <CheckIcon
                        sx={
                          !activeClassificationIds.includes(classification.id) && !isLargeBusiness
                            ? classificationIconInactive
                            : activeClassificationIcon
                        }
                      />
                    </Grid>
                    <Grid item>
                      <SmallBusinessClassificationDisplayName
                        classification={classification}
                        displayFormat="abbreviation"
                        tooltipFormat="name"
                        typographyClassNames={
                          !activeClassificationIds?.includes(classification.id) && !isLargeBusiness
                            ? classificationColumnInactive
                            : classificationColumn
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          );
        },
      },
    },
    {
      name: 'valueTotal',
      label: 'Total',
      options: {
        filter: false,
        searchable: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <NumberFormat
              value={contractSummaryData[dataIndex]?.valueTotal}
              displayType={'text'}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
              allowLeadingZeros={false}
              isNumericString={true}
              thousandSeparator={true}
              margin="dense"
              variant="outlined"
              color="primary"
            />
          );
        },
      },
    },
    {
      name: 'edit',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        display: editable,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Grid container sx={actionsContainer}>
                <Grid item>
                  <Tooltip title="Edit Contract">
                    <span>
                      <IconButton
                        size="small"
                        onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                          handleEditContract(event, dataIndex)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: 'add',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        display: editable,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          const shouldShowAddButton =
            contractSummaryData[dataIndex].companyName !== '' &&
            contractSummaryData[dataIndex].valueTotal !== 0 &&
            Math.sign(contractSummaryData[dataIndex].valueTotal) === 1 &&
            (isNonFederal || isHpSelfReporting);
          return (
            <>
              {shouldShowAddButton && (
                <Grid container sx={actionsContainer}>
                  <Grid item>
                    <Tooltip title="Add Lower Tier Participation">
                      <span>
                        <IconButton
                          size="small"
                          onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                            handleAddLowerTierParticipation(event, dataIndex)
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </>
          );
        },
      },
    },
  ];

  const contractSummaryListOptions: MUIDataTableOptions = {
    rowsPerPageOptions: [10, 50],
    expandableRows: true,
    expandableRowsOnClick: true,
    expandableRowsHeader: false,
    sortOrder: { name: 'phaseCode', direction: 'asc' },
    setRowProps(row, dataIndex) {
      const [, , , , , , lowerTierLargeBusiness, , , , ,] = row;
      return project?.smallBusinessRequirement !== SmallBusinessRequirementOption.FEDERAL &&
        lowerTierLargeBusiness(dataIndex)?.length
        ? { sx: lowerTierIsLargeBusiness }
        : {};
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const contractSummary = contractSummaryData[rowMeta.dataIndex];
      const changeOrderSummaries = contractSummaryData[rowMeta.dataIndex].changeOrderSummaries;
      const lowerTierSummaries = contractSummaryData[rowMeta.dataIndex].lowerTierParticipationClassificationSummaries;
      return (
        <>
          <TableRow>
            <TableCell colSpan={colSpan} sx={lowerTierParentCell}>
              <TableContainer>
                <Table>
                  {lowerTierSummaries.length > 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>Contract Date Execution</TableCell>
                        <TableCell>Classifications</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {lowerTierSummaries?.map((participation, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={
                            highlightContractsAndLowerTierParticipationRow(contractSummary)?.includes(participation)
                              ? lowerTierIsLargeBusiness
                              : undefined
                          }
                        >
                          <TableCell sx={hiddenCell}></TableCell>
                          <TableCell align="left" sx={secondTierCell}>
                            <Typography>{'Second Tier'}</Typography>
                          </TableCell>
                          <TableCell align="left" sx={lowerTierCompanyNameCell}>
                            <Typography>{participation?.lowerTierCompanyName}</Typography>
                          </TableCell>
                          <TableCell sx={selfWorkHiddenCell}></TableCell>
                          <TableCell sx={lowerTierHiddenCell}></TableCell>
                          <TableCell sx={lowerTierExecutionDateCell}>
                            <Typography>{formatDate(participation?.effectiveDate)}</Typography>
                          </TableCell>
                          <TableCell>
                            <Grid container direction="row" spacing={2}>
                              {participation?.lowerTierParticipationFileSummary?.flatMap(
                                (fileSummary: LowerTierParticipationFileSummary) => {
                                  const activeClassifications = fileSummary?.lowerTierParticipationClassifications;
                                  const activeClassificationIds = activeClassifications.map(
                                    (classification) => classification.smallBusinessClassification.id,
                                  );
                                  const classificationsToDisplay = classifications.filter((classification) =>
                                    activeClassificationIds?.includes(classification.id),
                                  );
                                  if (!classificationsToDisplay.length) {
                                    classificationsToDisplay.push({
                                      id: 'LB',
                                      abbreviation: 'LB',
                                      name: 'Large Business',
                                      sortOrder: -1,
                                    });
                                  }
                                  return classificationsToDisplay?.map((classification) => {
                                    const classificationIndex = Object.keys(SmallBusinessClassificationName).indexOf(
                                      classification.abbreviation,
                                    );

                                    return (
                                      <Grid item key={classification.id}>
                                        <Grid container alignItems="center" direction="row" spacing={1}>
                                          <Grid item>
                                            <CheckIcon sx={activeClassificationIcon} />
                                          </Grid>
                                          <Grid item>
                                            <SmallBusinessClassificationDisplayName
                                              classification={{
                                                name: Object.values(SmallBusinessClassificationName)[
                                                  classificationIndex
                                                ],
                                                abbreviation: classification.abbreviation,
                                              }}
                                              displayFormat="abbreviation"
                                              tooltipFormat="name"
                                              typographyClassNames={classificationColumn}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    );
                                  });
                                },
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={participation?.value}
                              displayType={'text'}
                              prefix={'$'}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowLeadingZeros={false}
                              isNumericString={true}
                              thousandSeparator={true}
                              margin="dense"
                              variant="outlined"
                              color="primary"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Grid container sx={actionsContainer}>
                              <Grid item>
                                <Tooltip title="Lower Tier Participation File">
                                  <span>
                                    <IconButton
                                      size="small"
                                      onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                                        handleViewLowerParticipationDocument(event, participation)
                                      }
                                    >
                                      <PdfIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="right">
                            <Grid container sx={actionsContainer}>
                              <Grid item>
                                <Tooltip title="Edit Lower Tier Participation">
                                  <span>
                                    <IconButton
                                      size="small"
                                      onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                                        handleEditLowerParticipation(event, participation)
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="right">
                            <Grid container sx={actionsContainer}>
                              <Grid item>
                                <Tooltip title="Delete Lower Tier Participation">
                                  <span>
                                    <IconButton
                                      size="small"
                                      onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                                        handleDeleteLowerTierParticipation(event, participation)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={colSpan}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="right">Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {changeOrderSummaries.map((changeOrderSummary, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="left">
                            <Typography>{formatDate(changeOrderSummary.date)}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <NumberFormat
                              value={changeOrderSummary.value}
                              displayType={'text'}
                              prefix={'$'}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowLeadingZeros={false}
                              isNumericString={true}
                              thousandSeparator={true}
                              margin="dense"
                              variant="outlined"
                              color="primary"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableCell>
          </TableRow>
        </>
      );
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const formattedData = data.map((row: any) => {
        const rowData = [...row.data];
        const classificationArrayIndex = isNonFederal ? 5 : 4;

        if (Array.isArray(rowData[classificationArrayIndex])) {
          rowData[classificationArrayIndex] = rowData[classificationArrayIndex]
            .filter((classificationData: SmallBusinessClassification) => classificationData.abbreviation !== 'SBT')
            .map((classification: SmallBusinessClassification) => classification.abbreviation)
            .join(', ');
        }
        return {
          ...row,
          data: rowData,
        };
      });
      return '\uFEFF' + buildHead(columns) + buildBody(formattedData);
    },
    downloadOptions: {
      filename: `Small_Business_${project?.number}_Contracts`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    sortFilterList: false,
  };

  return (
    <>
      <StyledTable
        title={'Contracts'}
        data={contractSummaryData}
        columns={contractSummaryListColumns}
        options={contractSummaryListOptions}
      />
      <EditContractDialog
        isOpen={isEditContractDialogOpen}
        setIsOpen={setIsEditContractDialogOpen}
        project={project}
        contractId={contractToEdit?.contractId}
        companyId={contractToEdit?.companyId}
        phaseCode={contractToEdit?.phaseCode}
        valueTotal={contractToEdit?.valueTotal}
        effectiveDate={contractToEdit?.effectiveDate}
        isSelfWork={contractToEdit?.isSelfWork ?? undefined}
        smallBusinessReportSettings={smallBusinessReportSettings}
        smallBusinessReportClassificationSummaries={smallBusinessReportClassificationSummaries}
        lowerTierParticipation={contractToEdit?.lowerTierParticipationClassificationSummaries}
        allSmallBusinessClassifications={allSmallBusinessClassifications}
        selectedReportOption={selectedReportOption}
      />
      <AddLowerTierParticipationDialog
        isOpen={isAddLowerTierParticipationDialogOpen}
        setIsOpen={setIsAddLowerTierParticipationDialogOpen}
        contractId={lowerTierParticipationToAdd?.contractId}
        allSmallBusinessClassifications={allSmallBusinessClassifications}
        smallBusinessReportSettings={smallBusinessReportSettings}
        smallBusinessReportClassificationSummaries={smallBusinessReportClassificationSummaries}
        smallBusinessClient={smallBusinessClient ?? undefined}
        contractSummaryData={contractSummaryDataByContractId}
      />
      <DeleteLowerTierParticipationDialog
        isOpen={isDeleteLowerTierParticipationDialogOpen}
        setIsOpen={setIsDeleteLowerTierParticipationDialogOpen}
        lowerTierParticipation={lowerTierParticipation}
        smallBusinessReportSettings={smallBusinessReportSettings}
        smallBusinessReportClassificationSummaries={smallBusinessReportClassificationSummaries}
      />
      <ViewLowerTierParticipationDialog
        isOpen={isViewLowerTierParticipationDialogOpen}
        setIsOpen={setIsViewLowerTierParticipationDialogOpen}
        lowerTierParticipation={lowerTierParticipation}
      />
      <EditLowerTierParticipationDialog
        isOpen={isEditLowerTierParticipationDialogOpen}
        setIsOpen={setIsEditLowerTierParticipationDialogOpen}
        lowerTierParticipation={lowerTierParticipation}
        allSmallBusinessClassifications={allSmallBusinessClassifications}
        smallBusinessReportSettings={smallBusinessReportSettings}
        smallBusinessReportClassificationSummaries={smallBusinessReportClassificationSummaries}
        smallBusinessClient={smallBusinessClient ?? undefined}
        contractSummaryData={contractSummaryDataByContractId}
      />
    </>
  );
};

export default SmallBusinessContractTable;
