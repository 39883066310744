import CompanySelect from 'components/company/CompanySelect';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import StyledNotice, { NoticeTypeOption } from 'components/shared/Notice';
import StyledDatePicker from 'components/shared/StyledDatePicker/StyledDatePicker';
import {
  FederalSmallBusinessClassificationName,
  SmallBusinessClassificationName,
} from 'constants/classificationNames';
import { SmallBusinessRequirementOption } from 'constants/smallBusinessRequirementOptions';
import { GET_SINGLE_PROJECT, UPDATE_PROJECT } from 'graphql/projects';
import { GET_SMALL_BUSINESS_REPORT } from 'graphql/smallBusinessReport';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router-dom';
import {
  SmallBusinessFederalReportFormOptionSelection,
} from 'recoil/smallBusinessFederalReportFormOption/atom';
import {
  GetSingleProjectQuery,
  GetSmallBusinessClassificationsQuery,
  LowerTierParticipationClassificationSummary,
  SmallBusinessClassification,
  SmallBusinessReportClassificationSummary,
  SmallBusinessReportSettings,
  useUpsertContractMutation,
} from 'types/generated/graphql';
import { convertUTCDateToLocalDate } from 'utils/general';

import { useApolloClient, useMutation } from '@apollo/client';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

const containerStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const formStyles: SxProps<Theme> = {
  width: '100%',
};

const infoLabel: SxProps<Theme> = {
  fontWeight: 'bolder',
  color: 'black',
};

const checkboxLabel: SxProps<Theme> = {
  marginLeft: 0,
};

const gridLabelStyles: SxProps<Theme> = {
  marginTop: 2,
};

const errorMessageStyle: SxProps<Theme> = {
  color: '#cd0000',
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.75rem',
};

type SmallBusinessClassificationSelection = GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'];

type EditContractDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  contractId?: string;
  companyId?: string;
  phaseCode?: string;
  valueTotal?: number;
  effectiveDate?: DateTime;
  isSelfWork?: boolean | undefined;
  smallBusinessReportSettings?: SmallBusinessReportSettings;
  smallBusinessReportClassificationSummaries?: SmallBusinessReportClassificationSummary[];
  project?: GetSingleProjectQuery['project'];
  lowerTierParticipation?: LowerTierParticipationClassificationSummary[] | undefined;
  allSmallBusinessClassifications: SmallBusinessClassificationSelection;
  selectedReportOption?: SmallBusinessFederalReportFormOptionSelection;
};

type useParamsProps = {
  projectId?: string;
};

type PickedSmallBusinessReportClassificationSummary = Pick<
  SmallBusinessReportClassificationSummary,
  'smallBusinessClassificationId' | 'goalPercent'
>;

const EditContractDialog: React.FC<EditContractDialogProps & Record<string, any>> = ({
  isOpen,
  setIsOpen,
  contractId,
  companyId,
  phaseCode,
  valueTotal,
  effectiveDate,
  isSelfWork,
  smallBusinessReportSettings,
  smallBusinessReportClassificationSummaries,
  project,
  lowerTierParticipation,
  allSmallBusinessClassifications,
  selectedReportOption,
}) => {
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();
  const { projectId } = useParams<useParamsProps>();

  const isNonFederal = project?.smallBusinessRequirement === SmallBusinessRequirementOption.NON_FEDERAL;
  const isHpSelfReporting = project?.smallBusinessRequirement === SmallBusinessRequirementOption.HP_SELF_REPORTING;

  const [upsertContract, { loading: upsertContractIsLoading }] = useUpsertContractMutation({
    refetchQueries: [
      {
        query: GET_SINGLE_PROJECT,
        variables: { id: projectId },
      },
      {
        query: GET_SMALL_BUSINESS_REPORT,
        variables: {
          smallBusinessReportSettingsId: smallBusinessReportSettings?.id,
          form: selectedReportOption === '' ? undefined : selectedReportOption,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [updateProject, { loading: updateProjectIsLoading }] = useMutation(UPDATE_PROJECT, {
    awaitRefetchQueries: true,
  });

  const smallBusinessTotalClassification = allSmallBusinessClassifications.find(
    (classification) => classification.name === SmallBusinessClassificationName.SBT,
  ) as SmallBusinessClassification;

  const [shouldShowHubzoneMessage, setShouldShowHubzoneMessage] = useState(false);

  interface ContractFormValues {
    companyId: string | undefined;
    effectiveDate: DateTime | undefined;
    isSelfWork: boolean | undefined;
  }

  const defaultValues: ContractFormValues = {
    companyId: companyId,
    effectiveDate: effectiveDate,
    isSelfWork: isSelfWork,
  };

  const { handleSubmit, control, reset, setValue, watch } = useForm({
    defaultValues,
  });

  useEffect(() => {
    // using this if statement because even though typescript thinks its a MaterialUiPickersDate its for some reason a string
    if (typeof effectiveDate == 'string') {
      setValue('effectiveDate', DateTime.fromISO(effectiveDate));
    } else {
      setValue('effectiveDate', effectiveDate);
    }
    setValue('companyId', companyId);
    setValue('isSelfWork', isSelfWork);
  }, [setValue, effectiveDate, companyId, isSelfWork]);

  const selectedEffectiveDate = watch('effectiveDate');
  const selectedCompany = watch('companyId');
  const selectedSelfWork = watch('isSelfWork');

  const handleClose = () => {
    // using this if statement because even though typescript thinks its a MaterialUiPickersDate its for some reason a string
    if (typeof effectiveDate == 'string') {
      reset({
        companyId: companyId,
        effectiveDate: DateTime.fromISO(effectiveDate),
        isSelfWork: isSelfWork,
      });
    } else {
      reset({ companyId: companyId, effectiveDate: effectiveDate, isSelfWork: isSelfWork ?? false });
    }
    setIsOpen(false);
    setShouldShowHubzoneMessage(false);
  };

  const isFormValid = () => {
    return !selectedCompany || (selectedEffectiveDate !== null && selectedEffectiveDate?.isValid);
  };

  const onSubmit: SubmitHandler<ContractFormValues> = (data) => {
    if (!contractId) {
      displayToast(
        'Error: Something went wrong while trying to update the contract information. Please contact support.',
        'error',
      );
      return;
    }

    upsertContract({
      variables: {
        input: {
          id: contractId,
          effectiveDate: data.effectiveDate?.toISODate() ?? null,
          companyId: data.companyId ?? null,
          isSelfWork: data.isSelfWork,
        },
      },
    })
      .then((result) => {
        if (isNonFederal || isHpSelfReporting) {
          const companyClassifications = result.data?.upsertContract.company?.smallBusinessClassifications?.filter(
            (smallBusinessClassification) => smallBusinessClassification.isFederal !== true,
          );
          const companyClassificationIds = companyClassifications?.map((classification) => classification.id);

          const clientSupportedClassifications =
            project?.smallBusinessClient?.smallBusinessClientAgencies?.flatMap((agency) =>
              agency?.smallBusinessClientClassifications?.flatMap(
                (classification) => classification.smallBusinessClassification,
              ),
            ) ?? [];

          const clientSupportedClassificationsWithoutDuplicates = clientSupportedClassifications.filter(
            (smallBusinessClientClassification, index) =>
              clientSupportedClassifications.findIndex(
                (classification) => classification.id === smallBusinessClientClassification.id,
              ) === index,
          );

          const classificationsToDisplay = clientSupportedClassificationsWithoutDuplicates.filter((classification) =>
            companyClassificationIds?.includes(classification.id as any),
          );

          classificationsToDisplay.some((classification) => {
            if (classification.name !== SmallBusinessClassificationName.LB) {
              classificationsToDisplay.push(smallBusinessTotalClassification);
            }
          });

          const classificationsToAdd = classificationsToDisplay
            ?.filter((smallBusinessClassification) => smallBusinessClassification.isFederal !== true)
            .map((classification) => {
              return {
                smallBusinessClassificationId: classification.id,
                goalPercent: 0,
              };
            }) as PickedSmallBusinessReportClassificationSummary[];

          const existingClassifications: PickedSmallBusinessReportClassificationSummary[] = [];
          smallBusinessReportClassificationSummaries?.forEach((classification) => {
            existingClassifications.push({
              smallBusinessClassificationId: classification.smallBusinessClassificationId,
              goalPercent: parseFloat(`${classification.goalPercent}`),
            });
          });

          let classifications: PickedSmallBusinessReportClassificationSummary[] = [];
          if (existingClassifications) {
            classifications = existingClassifications;
          }
          if (classificationsToAdd) {
            classifications = classificationsToAdd;
          }
          if (classificationsToAdd && existingClassifications) {
            classifications = classificationsToAdd.concat(existingClassifications);
          }

          const classificationsToRemove: string[] = [];

          const lowerTierParticipationIds = lowerTierParticipation?.map(
            (participation) => participation.lowerTierParticipationId,
          );

          smallBusinessReportClassificationSummaries?.forEach((summary) => {
            if (
              summary.contractIds?.length === 1 &&
              summary.contractIds?.includes(contractId as string) &&
              summary.goalPercent === 0 &&
              summary.name !== SmallBusinessClassificationName.LB
            ) {
              if (
                summary.lowerTierParticipationIds?.length === 1 &&
                summary.lowerTierParticipationIds?.some((id) => lowerTierParticipationIds?.includes(id))
              ) {
                classificationsToRemove.push(summary.smallBusinessClassificationId);
              }
              classificationsToRemove.push(summary.smallBusinessClassificationId);
            }
            return null;
          });

          updateProject({
            variables: {
              input: {
                id: projectId,
                smallBusinessReportSettings: {
                  id: smallBusinessReportSettings?.id,
                  classifications: classifications?.filter((classification) => {
                    return !classificationsToRemove.includes(String(classification.smallBusinessClassificationId));
                  }),
                },
              },
            },
          }).then(() => {
            apolloClient.reFetchObservableQueries();
          });
        }
      })
      .then(() => {
        displayToast('The contract information was updated successfully', 'success');
        handleClose();
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying to update the contract information. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <StyledDialog
      title={'Contract Info'}
      isLoading={upsertContractIsLoading || updateProjectIsLoading}
      content={
        <Grid container sx={containerStyles}>
          <Grid item>
            <Grid container direction="row" spacing={2} justifyContent="space-between">
              <Grid item>
                <Grid container direction="row" spacing={1} justifyContent="space-between">
                  <Grid item>
                    <InputLabel sx={infoLabel}>Phase code:</InputLabel>
                  </Grid>
                  <Grid item>
                    <Typography>{phaseCode}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1} justifyContent="space-between">
                  <Grid item>
                    <InputLabel sx={infoLabel}>Total:</InputLabel>
                  </Grid>
                  <Grid item>
                    <NumberFormat
                      value={valueTotal}
                      displayType={'text'}
                      prefix={'$'}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      allowLeadingZeros={false}
                      isNumericString={true}
                      thousandSeparator={true}
                      margin="dense"
                      variant="outlined"
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={formStyles}>
            <form>
              <Grid item>
                <Controller
                  render={({ field }) => (
                    <Grid item sx={gridLabelStyles}>
                      <InputLabel sx={infoLabel}>Company</InputLabel>
                      <CompanySelect<false>
                        selectedCompanyId={field.value}
                        onChange={(value) => {
                          setShouldShowHubzoneMessage(
                            value?.federalSmallBusinessClassifications?.some(
                              (c) => c.name === FederalSmallBusinessClassificationName.HUBZONE,
                            )
                              ? true
                              : false,
                          );
                          field.onChange(value?.id ?? undefined);
                          setValue('companyId', value?.id ?? undefined);
                        }}
                      />
                      <Grid sx={errorMessageStyle}>
                        {(selectedSelfWork || selectedEffectiveDate) && !selectedCompany
                          ? 'Company is required when other fields are populated'
                          : null}
                      </Grid>
                    </Grid>
                  )}
                  name="companyId"
                  control={control}
                  rules={{
                    required: (selectedSelfWork || selectedEffectiveDate) && !selectedCompany,
                  }}
                />
              </Grid>
              <Grid item sx={gridLabelStyles}>
                <FormControlLabel
                  sx={checkboxLabel}
                  control={
                    <Controller
                      name={'isSelfWork'}
                      control={control}
                      defaultValue={false}
                      render={({ field }) => <Checkbox color="primary" checked={field.value} {...field} />}
                    />
                  }
                  label={'Is this contract Hensel Phelps self performed work?'}
                  labelPlacement="start"
                />
              </Grid>
              <Grid item>
                <Controller
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Grid item sx={gridLabelStyles}>
                      <InputLabel sx={infoLabel}>Contract Date Execution</InputLabel>
                      <StyledDatePicker
                        value={value ? convertUTCDateToLocalDate(value) : null}
                        handleDateChange={(newDate) => {
                          onChange(newDate);
                          setValue('effectiveDate', newDate as DateTime);
                        }}
                        shouldDisplayAsError={!!error}
                      />
                    </Grid>
                  )}
                  name="effectiveDate"
                  control={control}
                  rules={{
                    required: false,
                  }}
                />
              </Grid>
              {shouldShowHubzoneMessage && (
                <Grid item xs={12}>
                  <StyledNotice
                    type={NoticeTypeOption.Notice}
                    message={
                      <Typography>
                        This trade partner is listed as HUBZONE certified. Make sure the certification is current.
                      </Typography>
                    }
                  />
                </Grid>
              )}
            </form>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary
                  disabled={upsertContractIsLoading || updateProjectIsLoading}
                  label={'cancel'}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'submit'}
                  type="submit"
                  disabled={!isFormValid() || upsertContractIsLoading || updateProjectIsLoading}
                  onClick={handleSubmit(onSubmit)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

EditContractDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  contractId: PropTypes.string,
  companyId: PropTypes.string,
  phaseCode: PropTypes.string,
  valueTotal: PropTypes.number,
  effectiveDate: PropTypes.any,
};

export default EditContractDialog;
