import AddClientAgencyAndClassificationDialog from 'components/client/AddClientAgencyAndClassificationDialog';
import DeleteClientAgencyAndClassificationDialog from 'components/client/DeleteClientAgencyAndClassificationDialog';
import EditClientAgencyAndClassificationDialog from 'components/client/EditClientAgencyAndClassificationDialog';
import ActiveOrInactiveStatusContent from 'components/shared/ActiveOrInactiveStatusContent';
import BackToTopButton from 'components/shared/BackToTopButton';
import CSVUploadDialog from 'components/shared/CSVUploadDialog';
import ErrorNotice from 'components/shared/ErrorNotice';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SmallBusinessClassificationDisplayName from 'components/shared/SmallBusinessClassificationDisplayName';
import SmallBusinessRequirementContent from 'components/shared/SmallBusinessRequirementContent';
import StyledTable from 'components/shared/Table';
import SmallBusinessClientDialog from 'components/SmallBusinessClientAdminDialog';
import { CLIENT_DIALOG_VIEWS } from 'constants/adminDialogViews';
import { SmallBusinessAdminRequirementOption } from 'constants/smallBusinessRequirementOptions';
import useRoles from 'hooks/useRoles';
import {
  ExpandButton,
  MUIDataTableColumn,
  MUIDataTableExpandButton,
  MUIDataTableOptions,
} from 'mui-datatables';
import { FC, MouseEvent, useState } from 'react';
import {
  GetSmallBusinessClassificationsQuery,
  SmallBusinessAgencyListQuery,
  SmallBusinessClient,
  SmallBusinessClientAgency,
  useGetSmallBusinessClassificationsQuery,
  useSmallBusinessAgencyListQuery,
  useSmallBusinessClientListQuery,
} from 'types/generated/graphql';
import { closeDialog, getColumnFormattedAdminData, openAdminDialog } from 'utils/adminTables';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Grid,
  IconButton,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

const actionsContainer: SxProps<Theme> = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: { justifyContent: 'flex-start' },
});

const agencyParentCell: SxProps<Theme> = {
  padding: '0 !important',
};

const hiddenCell: SxProps<Theme> = {
  position: 'sticky',
  left: '0px',
  zIndex: 100,
  width: '56px',
};

const headerText: SxProps<Theme> = {
  fontWeight: 'bold',
};

const SmallBusinessClientAdminTable: FC = () => {
  const { isApplicationAdmin } = useRoles();
  const [smallBusinessClientDialogIsOpen, setSmallBusinessClientDialogIsOpen] = useState(false);
  const [smallBusinessClientData, setSmallBusinessClientData] = useState<SmallBusinessClient>();
  const [smallBusinessClientDialogView, setSmallBusinessClientDialogView] = useState<any>();

  const [smallBusinessClientDetails, setSmallBusinessClientDetails] = useState<SmallBusinessClient>();
  const [smallBusinessAgencyAndClassificationDetails, setSmallBusinessAgencyAndClassificationDetails] =
    useState<SmallBusinessClientAgency>();

  const [isAddClientAgencyDialogOpen, setIsAddClientAgencyDialogOpen] = useState(false);
  const [isEditClientAgencyDialogOpen, setIsEditClientAgencyDialogOpen] = useState(false);
  const [isDeleteClientAgencyDialogOpen, setIsDeleteClientAgencyDialogOpen] = useState(false);

  const [csvUploadDialogIsOpen, setCsvUploadDialogIsOpen] = useState(false);
  const [adminTableType, setAdminTableType] = useState('');

  const { EDIT_CLIENT, ADD_CLIENT, DELETE_CLIENT } = CLIENT_DIALOG_VIEWS;

  const {
    data: smallBusinessClientListData,
    loading: smallBusinessClientLoading,
    error: smallBusinessClientError,
  } = useSmallBusinessClientListQuery({
    variables: {
      isActive: [true, false],
    },
  });

  const smallBusinessClientList = smallBusinessClientListData?.smallBusinessClientList ?? [];

  type SmallBusinessClassificationsSelection = GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'];
  type SmallBusinessAgencyListSelection = SmallBusinessAgencyListQuery['smallBusinessAgencyList'];

  const { data: smallBusinessClassificationsData } = useGetSmallBusinessClassificationsQuery();

  const smallBusinessClassificationsList: SmallBusinessClassificationsSelection =
    smallBusinessClassificationsData?.smallBusinessClassificationList ?? [];

  const { data: smallBusinessAgenciesData } = useSmallBusinessAgencyListQuery({
    variables: {
      isActive: [true, false],
    },
  });
  const smallBusinessAgenciesList: SmallBusinessAgencyListSelection =
    smallBusinessAgenciesData?.smallBusinessAgencyList ?? [];

  const handleAddClientAgencyAndClassifications = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    dataIndex: number,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setSmallBusinessClientDetails(smallBusinessClientList[dataIndex] as SmallBusinessClient);
    setIsAddClientAgencyDialogOpen(true);
  };

  const handleEditClientAgencyAndClassifications = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    smallBusinessClient: SmallBusinessClient,
    smallBusinessClientAgencyAndClassification: SmallBusinessClientAgency,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setSmallBusinessClientDetails(smallBusinessClient);
    setSmallBusinessAgencyAndClassificationDetails(smallBusinessClientAgencyAndClassification);
    setIsEditClientAgencyDialogOpen(true);
  };

  const handleDeleteClientAgencyAndClassifications = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    smallBusinessClient: SmallBusinessClient,
    smallBusinessClientAgencyAndClassification: SmallBusinessClientAgency,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setSmallBusinessClientDetails(smallBusinessClient);
    setSmallBusinessAgencyAndClassificationDetails(smallBusinessClientAgencyAndClassification);
    setIsDeleteClientAgencyDialogOpen(true);
  };

  const handleCsvUploadDialog = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setCsvUploadDialogIsOpen(true);
  };

  const getUploadCsvButton = (id?: string) => {
    return (
      <Tooltip title={'Reporting Agency Mass Upload'}>
        <IconButton
          id={id}
          onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
            setAdminTableType(event.currentTarget.id);
            handleCsvUploadDialog(event);
          }}
        >
          <FileUploadIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Reporting Agency Name',
      options: {
        filter: true,
        sort: true,
        searchable: true,
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: 'abbreviation',
      label: 'Abbreviation',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: true,
        searchable: true,
        filterOptions: {
          fullWidth: true,
          renderValue: (abbreviation) => {
            if (abbreviation === '' || abbreviation === null || abbreviation === undefined) {
              return '(none)';
            }
            return abbreviation;
          },
        },
      },
    },
    {
      name: 'isActive',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          <ActiveOrInactiveStatusContent isActive={smallBusinessClientList[dataIndex]?.isActive as boolean} />
        ),
        filterOptions: {
          names: ['Active', 'Inactive'],
          logic: (isActive, filters) => {
            if (filters.length) {
              const status = isActive ? 'Active' : 'Inactive';
              return !filters.includes(status);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          },
        },
      },
    },
    {
      name: 'isFederal',
      label: 'SB Requirement',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          <SmallBusinessRequirementContent
            value={
              smallBusinessClientList[dataIndex]?.isFederal === true
                ? SmallBusinessAdminRequirementOption.FEDERAL
                : smallBusinessClientList[dataIndex]?.isFederal === false
                ? SmallBusinessAdminRequirementOption.NON_FEDERAL
                : SmallBusinessAdminRequirementOption.ANY_ALL
            }
          />
        ),
        filterOptions: {
          names: Object.values(SmallBusinessAdminRequirementOption),
          fullWidth: true,
          logic: (isFederal, filters) => {
            const federal = Object.is(isFederal, true);
            const nonFederal = Object.is(isFederal, false);
            if (filters.length) {
              const sbRequirement = federal
                ? SmallBusinessAdminRequirementOption.FEDERAL
                : nonFederal
                ? SmallBusinessAdminRequirementOption.NON_FEDERAL
                : SmallBusinessAdminRequirementOption.ANY_ALL;
              return !filters.includes(sbRequirement);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          },
        },
      },
    },
    {
      name: 'sortOrder',
      label: '',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'agencyAndClassifications',
      label: '',
      options: {
        filter: false,
        display: false,
        customBodyRenderLite: (dataIndex) => {
          const smallBusinessClientAgencySummary = smallBusinessClientList[dataIndex]?.smallBusinessClientAgencies.map(
            (agency) => agency.smallBusinessAgency?.id + ' ',
          );
          return smallBusinessClientAgencySummary;
        },
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta.rowData;
          return (
            <Grid container sx={actionsContainer}>
              <Grid item>
                <span>
                  <Tooltip title={'Delete Reporting Agency'}>
                    <IconButton
                      onClick={() =>
                        openAdminDialog(
                          rowData,
                          setSmallBusinessClientData,
                          setSmallBusinessClientDialogIsOpen,
                          setSmallBusinessClientDialogView,
                          DELETE_CLIENT,
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: 'edit',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta.rowData;
          return (
            <>
              <Grid container sx={actionsContainer}>
                <Grid item>
                  <span>
                    <Tooltip title={'Edit Reporting Agency'}>
                      <IconButton
                        onClick={() =>
                          openAdminDialog(
                            rowData,
                            setSmallBusinessClientData,
                            setSmallBusinessClientDialogIsOpen,
                            setSmallBusinessClientDialogView,
                            EDIT_CLIENT,
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: 'add',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Grid container sx={actionsContainer}>
                <Grid item>
                  <Tooltip title="Add Agency and Classification">
                    <span>
                      <IconButton
                        size="small"
                        onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                          handleAddClientAgencyAndClassifications(event, dataIndex)
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    count: smallBusinessClientList?.length,
    rowsPerPageOptions: [],
    sortOrder: {
      name: 'name',
      direction: 'asc',
    },
    expandableRows: true,
    expandableRowsOnClick: true,
    expandableRowsHeader: false,
    download: false,
    customToolbar: (data) => {
      const rowDataIndex = data.displayData.length;
      return (
        <>
          <Tooltip title={'Add Reporting Agency'}>
            <IconButton
              onClick={() =>
                openAdminDialog(
                  [rowDataIndex],
                  setSmallBusinessClientData,
                  setSmallBusinessClientDialogIsOpen,
                  setSmallBusinessClientDialogView,
                  ADD_CLIENT,
                )
              }
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          {isApplicationAdmin && (
            <Tooltip title={'Reporting Agency Mass Upload'}>
              <>{getUploadCsvButton('clientAdminTable')}</>
            </Tooltip>
          )}
        </>
      );
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const smallBusinessClients = smallBusinessClientList[rowMeta.dataIndex];
      return (
        <>
          <TableRow>
            <TableCell colSpan={colSpan} sx={agencyParentCell}>
              <TableContainer>
                <Table>
                  {smallBusinessClients?.smallBusinessClientAgencies?.length > 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell sx={headerText}>Agencies</TableCell>
                        <TableCell sx={headerText}>Classifications</TableCell>
                        <TableCell sx={headerText}>SB Requirement</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {smallBusinessClients.smallBusinessClientAgencies?.flatMap((agency: any, index) => {
                      const smallBusinessClientClassifications = [...agency?.smallBusinessClientClassifications];
                      return (
                        <TableRow key={index}>
                          <TableCell sx={hiddenCell}></TableCell>
                          <TableCell align="left">
                            <Typography>{agency?.smallBusinessAgency?.name ?? ''}</Typography>
                          </TableCell>
                          <TableCell>
                            <Grid container direction="row" spacing={2}>
                              {smallBusinessClientClassifications
                                ?.sort((a, b) =>
                                  a.smallBusinessClassification.name.localeCompare(b.smallBusinessClassification.name),
                                )
                                .map((classification: any, index: number, array: any) => (
                                  <Grid item key={classification.smallBusinessClassification.id}>
                                    <Grid container alignItems="center" direction="row" spacing={1}>
                                      <Grid item>
                                        <SmallBusinessClassificationDisplayName
                                          classification={classification.smallBusinessClassification}
                                          displayFormat="abbreviation"
                                          tooltipFormat="name"
                                          displayFormatExtension={(formattedName) =>
                                            `${formattedName}${index === array.length - 1 ? '' : ','}`
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))}
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <SmallBusinessRequirementContent
                              value={
                                agency?.smallBusinessAgency.isFederal === true
                                  ? SmallBusinessAdminRequirementOption.FEDERAL
                                  : agency?.smallBusinessAgency.isFederal === false
                                  ? SmallBusinessAdminRequirementOption.NON_FEDERAL
                                  : SmallBusinessAdminRequirementOption.ANY_ALL
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Grid container sx={actionsContainer}>
                              <Grid item>
                                <Tooltip title="Edit Reporting Agency Agencies">
                                  <span>
                                    <IconButton
                                      size="small"
                                      onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                                        handleEditClientAgencyAndClassifications(
                                          event,
                                          smallBusinessClients as SmallBusinessClient,
                                          smallBusinessClients.smallBusinessClientAgencies[
                                            index
                                          ] as SmallBusinessClientAgency,
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="right">
                            <Grid container sx={actionsContainer}>
                              <Grid item>
                                <Tooltip title="Delete Reporting Agency Agencies">
                                  <span>
                                    <IconButton
                                      size="small"
                                      onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) =>
                                        handleDeleteClientAgencyAndClassifications(
                                          event,
                                          smallBusinessClients as SmallBusinessClient,
                                          smallBusinessClients.smallBusinessClientAgencies[
                                            index
                                          ] as SmallBusinessClientAgency,
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableCell>
          </TableRow>
        </>
      );
    },
  };

  const components = {
    ExpandButton: (props: MUIDataTableExpandButton) => {
      const index = props.dataIndex as number;
      const smallBusinessClientsAgencies = smallBusinessClientList[index]?.smallBusinessClientAgencies ?? [];
      if (!smallBusinessClientsAgencies.length) return <div style={{ width: '24px' }} />;
      return <ExpandButton {...props} />;
    },
  };

  if (smallBusinessClientError) {
    console.error(smallBusinessClientError);
    return <ErrorNotice />;
  }

  if (smallBusinessClientLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <StyledTable
        title="Reporting Agency List"
        data={getColumnFormattedAdminData(smallBusinessClientList) ?? []}
        columns={columns}
        options={options}
        components={components}
      />
      <BackToTopButton />
      {smallBusinessClientDialogIsOpen && (
        <SmallBusinessClientDialog
          isOpen={smallBusinessClientDialogIsOpen}
          setIsOpen={setSmallBusinessClientDialogIsOpen}
          handleClose={() => closeDialog(setSmallBusinessClientDialogIsOpen, setSmallBusinessClientDialogView)}
          smallBusinessClientData={smallBusinessClientData}
          smallBusinessClientDialogView={smallBusinessClientDialogView}
          setSmallBusinessClientDialogView={setSmallBusinessClientDialogView}
        />
      )}
      <AddClientAgencyAndClassificationDialog
        isOpen={isAddClientAgencyDialogOpen}
        setIsOpen={setIsAddClientAgencyDialogOpen}
        smallBusinessClient={smallBusinessClientDetails as SmallBusinessClient}
        smallBusinessClassificationsList={smallBusinessClassificationsList}
        smallBusinessAgenciesList={smallBusinessAgenciesList}
      />
      <EditClientAgencyAndClassificationDialog
        isOpen={isEditClientAgencyDialogOpen}
        setIsOpen={setIsEditClientAgencyDialogOpen}
        smallBusinessClient={smallBusinessClientDetails as SmallBusinessClient}
        smallBusinessAgenciesList={smallBusinessAgenciesList}
        smallBusinessClassificationsList={smallBusinessClassificationsList}
        smallBusinessAgencyAndClassificationDetails={
          smallBusinessAgencyAndClassificationDetails as SmallBusinessClientAgency
        }
      />
      {isDeleteClientAgencyDialogOpen && (
        <DeleteClientAgencyAndClassificationDialog
          isOpen={isDeleteClientAgencyDialogOpen}
          setIsOpen={setIsDeleteClientAgencyDialogOpen}
          smallBusinessClient={smallBusinessClientDetails as SmallBusinessClient}
          smallBusinessAgencyAndClassificationDetails={
            smallBusinessAgencyAndClassificationDetails as SmallBusinessClientAgency
          }
        />
      )}
      <CSVUploadDialog
        isOpen={csvUploadDialogIsOpen}
        setIsOpen={setCsvUploadDialogIsOpen}
        adminTableType={adminTableType}
        smallBusinessClientList={smallBusinessClientList}
        smallBusinessAgenciesList={smallBusinessAgenciesList}
        smallBusinessClassificationsList={smallBusinessClassificationsList}
      />
    </>
  );
};
export default SmallBusinessClientAdminTable;
