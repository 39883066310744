import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import SmallBusinessClassificationDisplayName from 'components/shared/SmallBusinessClassificationDisplayName';
import { FederalSmallBusinessClassificationName, SmallBusinessClassificationName } from 'constants/classificationNames';
import { federalSmallBusinessReportOptionShortDisplayNames } from 'constants/federalSmallBusinessReportOptions';
import { SmallBusinessRequirementOption } from 'constants/smallBusinessRequirementOptions';
import { UPDATE_PROJECT } from 'graphql/projects';
import useToast from 'hooks/useToast';
import _ from 'lodash';
import Papa from 'papaparse';
import { FC, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router-dom';
import { SmallBusinessFederalReportFormOptionSelection } from 'recoil/smallBusinessFederalReportFormOption/atom';
import {
  SmallBusinessClassification,
  SmallBusinessReportClassificationSummary,
  SmallBusinessReportSettings,
} from 'types/generated/graphql';

import { useApolloClient, useMutation } from '@apollo/client';
import BlockIcon from '@mui/icons-material/Block';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { default as DeleteIcon } from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

const classificationCardContainer: SxProps<Theme> = {
  width: '80%',
};

const classificationHeadersContainer: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(1, 2),
});

const classificationsHeader: SxProps<Theme> = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
};

const classificationRowContainer: SxProps<Theme> = (theme: Theme) => ({
  borderTop: '1px solid #b5b5b5',
  padding: theme.spacing(1, 2),
});

const classificationColumnPrimary: SxProps<Theme> = (theme: Theme) => ({
  width: '25%',
  [theme.breakpoints.down('md')]: { width: '30%' },
});

const classificationToBeDeleted: SxProps<Theme> = {
  color: 'gray',
};

const classificationColumnSecondary: SxProps<Theme> = (theme: Theme) => ({
  width: '10%',
  [theme.breakpoints.down('md')]: { width: '20%' },
});

const classificationColumnPrimaryFed: SxProps<Theme> = (theme: Theme) => ({
  width: '58.5%',
  [theme.breakpoints.down('md')]: { width: '30%' },
});

const classificationColumnSecondaryFed: SxProps<Theme> = (theme: Theme) => ({
  width: '10.5%',
  [theme.breakpoints.down('md')]: { width: '23%' },
});

const classificationColumnPercentageFed: SxProps<Theme> = (theme: Theme) => ({
  width: '8%',
  [theme.breakpoints.down('md')]: { width: '10%' },
});

const classificationColumnPercentage: SxProps<Theme> = (theme: Theme) => ({
  width: '9%',
  [theme.breakpoints.down('md')]: { width: '10%' },
});

const classificationColumnPercentageEdit: SxProps<Theme> = (theme: Theme) => ({
  width: '7%',
  [theme.breakpoints.down('md')]: { width: '10%' },
});

const classificationRowText: SxProps<Theme> = (theme: Theme) => ({
  width: 'fit-content',
  padding: theme.spacing(0.1),
});

const classificationRowTextHighlighted: SxProps<Theme> = (theme: Theme) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
});

const classificationPercentage: SxProps<Theme> = {
  fontWeight: 'bold',
};

const classificationPercentageError: SxProps<Theme> = {
  color: 'red',
};

const buttonContainerForClassifications: SxProps<Theme> = (theme: Theme) => ({
  height: 10,
  margin: theme.spacing(1, 2, -0.5, 0),
  [theme.breakpoints.down('md')]: { margin: theme.spacing(1, 2, 1, 0) },
});

const editingModeIndicator: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(1, 1, 0, 0),
});

const actionsContainer: SxProps<Theme> = (theme: Theme) => ({
  margin: theme.spacing(2),
});

const deleteButton: SxProps<Theme> = {
  display: 'inline',
  float: 'left',
  padding: 0,
  marginRight: 12,
};

const classificationName: SxProps<Theme> = {
  display: 'inline',
  float: 'left',
};

const classificationsSelect: SxProps<Theme> = (theme: Theme) => ({
  width: '50%',
  [theme.breakpoints.down('md')]: { width: '40%' },
});

const errorMessageStyle: SxProps<Theme> = {
  color: '#cd0000',
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.75rem',
};

type FormClassification = Omit<SmallBusinessReportClassificationSummary, 'goalPercent'> & {
  goalPercent: string;
};

type FormValues = {
  classification: string;
  classificationsToAdd: string[];
  classifications: FormClassification[];
  classificationsToDelete: SmallBusinessReportClassificationSummary[];
};

type PickedSmallBusinessClassification = Pick<SmallBusinessClassification, 'id' | 'name' | 'abbreviation'>;

type SmallBusinessClassificationsCardProps = {
  classifications?: SmallBusinessReportClassificationSummary[];
  smallBusinessReportSettings?: SmallBusinessReportSettings;
  smallBusinessRequirements?: string | null;
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  selectedReportOption?: SmallBusinessFederalReportFormOptionSelection;
  originalContractValue?: number;
  currentContractValue?: number;
  currentSubContractorContractValue?: number;
  projectNumber?: string;
  smallBusinessClassifications?: PickedSmallBusinessClassification[];
  editable?: boolean;
};

type useParamsProps = {
  projectId?: string;
};

const indirectCostClassification: SmallBusinessReportClassificationSummary = {
  abbreviation: 'IC',
  actualPercentOriginal: 0,
  actualPercentTotal: 2.44,
  actualTotal: 0,
  smallBusinessClassificationId: '',
  smallBusinessReportClassificationId: '',
  name: FederalSmallBusinessClassificationName.IC,
  sortOrder: -1,
  isFederal: true,
};

const SmallBusinessClassificationsCard: FC<SmallBusinessClassificationsCardProps> = ({
  classifications = [],
  smallBusinessReportSettings,
  smallBusinessRequirements,
  selectedStartDate,
  selectedEndDate,
  selectedReportOption,
  originalContractValue = 0,
  currentContractValue = 0,
  currentSubContractorContractValue = 0,
  projectNumber = '',
  smallBusinessClassifications = [],
  editable,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();
  const [classificationsToRemove, setClassificationsToRemove] = useState<string[]>([]);
  const isFederalReport = smallBusinessRequirements === SmallBusinessRequirementOption.FEDERAL;
  const [addedClassifications, setAddedClassifications] = useState<string[]>([]);

  const { projectId } = useParams<useParamsProps>();
  const [updateProject, { loading: isLoading }] = useMutation(UPDATE_PROJECT, {
    awaitRefetchQueries: true,
  });

  const classificationSummariesToFormClassifications = useCallback(
    (classificationSummaries: SmallBusinessReportClassificationSummary[]): FormClassification[] => {
      if (isFederalReport && selectedReportOption?.includes('295')) {
        indirectCostClassification.actualTotal =
          (indirectCostClassification.actualPercentTotal / 100) * currentSubContractorContractValue;
        classificationSummaries = [...classificationSummaries, indirectCostClassification];
      }
      return classificationSummaries.map((classificationSummary: SmallBusinessReportClassificationSummary) => ({
        ...classificationSummary,
        id: classificationSummary.smallBusinessClassificationId,
        goalPercent: classificationSummary.goalPercent?.toString() ?? '',
      }));
    },
    [isFederalReport, selectedReportOption, currentSubContractorContractValue],
  );

  const { handleSubmit, control, watch, reset, setValue, formState } = useForm<FormValues>({
    defaultValues: {
      classifications: classificationSummariesToFormClassifications(classifications),
      classificationsToAdd: [],
      classification: '',
      classificationsToDelete: [],
    },
    mode: 'onBlur',
  });
  useEffect(() => {
    reset({
      classifications: classificationSummariesToFormClassifications(classifications),
      classificationsToAdd: [],
      classification: '',
      classificationsToDelete: [],
    });
  }, [classificationSummariesToFormClassifications, classifications, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'classifications',
  });

  const { isDirty } = formState;

  const watchFieldArray = watch('classifications');
  const classification = watch('classification');
  const classificationsToAdd = watch('classificationsToAdd');

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    if (!isFederalReport && classification) {
      setAddedClassifications([classification]);
    }
  }, [classification, isFederalReport]);

  const smallBusinessTotalClassificationValue = +(
    controlledFields.find((classification) => classification.name === SmallBusinessClassificationName.SBT)
      ?.goalPercent ?? 0
  );

  const [currentSmallBusinessTotalClassificationValue, setCurrentSmallBusinessTotalClassificationValue] = useState(
    smallBusinessTotalClassificationValue,
  );
  useEffect(() => {
    if (isEditing && currentSmallBusinessTotalClassificationValue !== smallBusinessTotalClassificationValue) {
      setCurrentSmallBusinessTotalClassificationValue(smallBusinessTotalClassificationValue);
      const largeBusinessClassificationIndex = classifications.findIndex(
        (classification) => classification.name === SmallBusinessClassificationName.LB,
      );
      setValue(
        `classifications.${largeBusinessClassificationIndex}.goalPercent`,
        (100 - smallBusinessTotalClassificationValue).toString(),
      );
    }
  }, [
    currentSmallBusinessTotalClassificationValue,
    isEditing,
    setValue,
    smallBusinessTotalClassificationValue,
    classifications,
  ]);

  const goalPercentStringsByClassificationId = controlledFields.reduce(
    (accumulator: { [reportClassificationId: string]: string }, classification) => {
      classification.smallBusinessReportClassificationId
        ? (accumulator[classification.smallBusinessReportClassificationId] = classification.goalPercent)
        : (accumulator[classification.smallBusinessClassificationId] = classification.goalPercent);
      return accumulator;
    },
    {},
  );
  const [currentGoalPercentStringsByClassificationId, setCurrentGoalPercentStringsByClassificationId] = useState(
    goalPercentStringsByClassificationId,
  );

  useEffect(() => {
    if (
      isEditing &&
      Object.keys(goalPercentStringsByClassificationId).some(
        (key) => goalPercentStringsByClassificationId[key] !== currentGoalPercentStringsByClassificationId[key],
      )
    ) {
      let isGoalChanged = false;
      Object.keys(goalPercentStringsByClassificationId).forEach((key) => {
        const goalPercentString = goalPercentStringsByClassificationId[key];
        if (goalPercentString !== currentGoalPercentStringsByClassificationId[key]) {
          isGoalChanged = true;
          const classificationsAdded = classifications.length !== watchFieldArray.length;
          const classificationIndex = classificationsAdded
            ? watchFieldArray.findIndex((classification) => classification.smallBusinessClassificationId === key)
            : classifications.findIndex((classification) => classification.smallBusinessReportClassificationId === key);
          const goalPercent = parseFloat(goalPercentString);
          // WARNING: if you edit any logic which impacts goalTotal, be sure to update the
          // duplicate logic in the api.
          setValue(
            `classifications.${classificationIndex}.goalTotal`,
            typeof goalPercent === 'number' ? (goalPercent / 100) * originalContractValue : null,
          );
        }
      });
      if (isGoalChanged) {
        setCurrentGoalPercentStringsByClassificationId(goalPercentStringsByClassificationId);
      }
    }
  }, [
    isEditing,
    setValue,
    classifications,
    goalPercentStringsByClassificationId,
    currentGoalPercentStringsByClassificationId,
    originalContractValue,
    watchFieldArray,
  ]);

  const hasError = (index: number) => {
    const classification = controlledFields[index];
    return (
      classification.goalPercent &&
      !(parseFloat(classification.goalPercent) >= 0 && parseFloat(classification.goalPercent) <= 100)
    );
  };

  const hasClassificationError = (index: number) => {
    const classification = controlledFields[index];
    if (!classification.smallBusinessClassificationId) {
      return true;
    }
    return false;
  };

  const canSubmit = () => {
    return !isDirty || isLoading || (!isFederalReport && !filterDropdownClassifications.length);
  };

  const handleClose = () => {
    setIsEditing(false);
    reset({ classifications: classificationSummariesToFormClassifications(classifications) });
    setClassificationsToRemove([]);
  };

  const handleEditButtonClick = () => {
    setIsEditing(true);
    reset({ classifications: classificationSummariesToFormClassifications(classifications) });
  };

  const onSubmit = (data: FormValues) => {
    const classifications = data.classifications
      ?.filter((classification) => classification.name !== FederalSmallBusinessClassificationName.IC)
      .map((classification) => ({
        smallBusinessClassificationId: classification.smallBusinessClassificationId,
        goalPercent: parseFloat(`${classification.goalPercent}`),
      }));
    return updateProject({
      variables: {
        input: {
          id: projectId,
          smallBusinessReportSettings: {
            id: smallBusinessReportSettings?.id,
            classifications: classifications?.filter((classification) => {
              return !classificationsToRemove.includes(String(classification.smallBusinessClassificationId));
            }),
          },
        },
      },
    })
      .then(() => {
        apolloClient.reFetchObservableQueries();
        displayToast('The small business report classification goals were updated successfully', 'success');
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('Update smallBusiness goals failed: ', error);
        displayToast(
          'Error: Something went wrong while trying submit your changes. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  const handleDownloadButtonClick = () => {
    const csv = isFederalReport
      ? Papa.unparse(
          controlledFields.map((field) => ({
            id: field.id,
            name: field.name,
            abbreviation: field.abbreviation,
            goalPercent: field.goalPercent,
            goalTotal: field.goalTotal || '',
            actualPercentTotal: field.actualPercentTotal,
            actualTotal: field.actualTotal,
            originalContractValue,
            currentContractValue,
            currentSubContractorContractValue,
          })),
        )
      : Papa.unparse(
          controlledFields.map((field) => ({
            id: field.id,
            name: field.name,
            abbreviation: field.abbreviation,
            goalPercent: field.goalPercent,
            goalTotal: field.goalTotal || '',
            actualPercentTotal: field.actualPercentTotal,
            actualPercentOriginal: field.actualPercentOriginal,
            actualTotal: field.actualTotal,
            lowerTierParticipationTotal: field.lowerTierParticipationTotal,
            lowerTierParticipationPercentOriginal: field.lowerTierParticipationPercentOriginal,
            lowerTierPercentTotal: field.lowerTierPercentTotal,
            firsTierTotal: field.firstTierTotal,
            firstTierPercentOriginal: field.firstTierPercentOriginal,
            firstTierPercentTotal: field.firstTierPercentTotal,
            originalContractValue,
            currentContractValue,
            currentSubContractorContractValue,
          })),
        );

    const fileName = isFederalReport
      ? `${
          federalSmallBusinessReportOptionShortDisplayNames[selectedReportOption || ''] || selectedReportOption
        }_${projectNumber}_Classifications`
      : `${selectedStartDate}_${selectedEndDate}_${projectNumber}_Classifications`;

    // Taken from mui-datatables
    const URL = window.URL || window.webkitURL;
    const downloadURI =
      typeof URL.createObjectURL === 'undefined'
        ? `data:text/csv;charset=utf-8,${csv}`
        : URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));

    let link = document.createElement('a');
    link.setAttribute('href', downloadURI);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSelectClassification = (_: any, classificationId: string) => {
    setValue('classification', classificationId);
    setValue('classificationsToAdd', [...addedClassifications, classificationId]);
  };

  const handleDeleteClassification: MouseEventHandler<HTMLButtonElement> = (event) => {
    const classificationId = event.currentTarget.value;
    if (classificationsToRemove.includes(classificationId)) {
      classificationsToRemove.splice(classificationsToRemove.indexOf(classificationId), 1);
      setClassificationsToRemove([...classificationsToRemove]);
    } else {
      classificationsToRemove.push(classificationId);
      setClassificationsToRemove([...classificationsToRemove]);
    }
  };
  const filterDropdownClassifications = smallBusinessClassifications.filter(
    (classification) =>
      classification.name !== SmallBusinessClassificationName.LB.toString() &&
      classification.name !== SmallBusinessClassificationName.SBT.toString() &&
      controlledFields?.every((field) => field.id !== classification.id),
  );

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item sx={buttonContainerForClassifications}>
                <IconButton size="small" onClick={handleDownloadButtonClick}>
                  <DownloadIcon />
                </IconButton>
              </Grid>
              {editable && (
                <Grid item sx={buttonContainerForClassifications}>
                  {!isEditing && (
                    <IconButton size="small" onClick={handleEditButtonClick}>
                      <EditIcon />
                    </IconButton>
                  )}
                  {isEditing && <Typography sx={editingModeIndicator}>Editing...</Typography>}
                </Grid>
              )}
            </Grid>
            {isFederalReport && (
              <Grid container direction="row" sx={classificationHeadersContainer}>
                <Grid item xs={4} sm={4} md={5} lg={7}>
                  <Typography sx={classificationsHeader}>Classifications</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={3} lg={2}>
                  <Typography sx={classificationsHeader}>Current Goals</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={3} lg={3}>
                  <Typography sx={classificationsHeader}>Actual Cumulative</Typography>
                </Grid>
              </Grid>
            )}
            {!isFederalReport && (
              <Grid container direction="row" sx={classificationHeadersContainer}>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <Typography sx={classificationsHeader}>Classifications</Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Typography sx={classificationsHeader}>Current Goals</Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2.3} lg={2.3}>
                  <Typography sx={classificationsHeader}>First Tier Cumulative</Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2.3} lg={2.3}>
                  <Typography sx={classificationsHeader}>Lower Tier Cumulative</Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Typography sx={classificationsHeader}>Actual Cumulative</Typography>
                </Grid>
              </Grid>
            )}

            <Grid container direction="row">
              {controlledFields.map((classification, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={classification.id}
                    sx={
                      classificationsToRemove.includes(String(classification.smallBusinessClassificationId))
                        ? classificationToBeDeleted
                        : classificationRowContainer
                    }
                  >
                    <Grid container>
                      <Grid item sx={isFederalReport ? classificationColumnPrimaryFed : classificationColumnPrimary}>
                        {isEditing &&
                          classification.name !== SmallBusinessClassificationName.SBT &&
                          classification.name !== SmallBusinessClassificationName.LB &&
                          !isFederalReport && (
                            <Controller
                              render={({ field }) => (
                                <IconButton
                                  {...field}
                                  sx={deleteButton}
                                  value={classification.id}
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => {
                                    remove(index);
                                    if (classificationsToAdd?.includes(classification.smallBusinessClassificationId)) {
                                      classificationsToAdd.splice(
                                        classificationsToAdd.indexOf(classification.smallBusinessClassificationId),
                                        1,
                                      );
                                    }
                                    return handleDeleteClassification;
                                  }}
                                  size="large"
                                >
                                  {classificationsToRemove.includes(
                                    String(classification.smallBusinessClassificationId),
                                  ) && <BlockIcon />}
                                  {!classificationsToRemove.includes(
                                    String(classification.smallBusinessClassificationId),
                                  ) && <DeleteIcon />}
                                </IconButton>
                              )}
                              name={'classificationsToDelete'}
                              control={control}
                              rules={{ required: false }}
                            />
                          )}
                        {isEditing && !classification.name && !isFederalReport && (
                          <Grid key={classification.id}>
                            <Controller
                              render={({ field }) => {
                                return (
                                  <FormControl sx={classificationsSelect} required>
                                    <InputLabel>Classifications</InputLabel>
                                    <Select
                                      {...field}
                                      onChange={(e: { target: { value: string } }) => {
                                        field.onChange(e.target.value);
                                        handleSelectClassification(_, e.target.value);
                                      }}
                                      label="Classifications"
                                      required
                                      error={hasClassificationError(index)}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'right',
                                        },
                                      }}
                                      disabled={!filterDropdownClassifications.length}
                                    >
                                      {filterDropdownClassifications.map((value) => {
                                        return (
                                          <MenuItem key={value.id} value={value.id}>
                                            {value.abbreviation} ({value.name})
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                );
                              }}
                              name={`classifications.${index}.smallBusinessClassificationId` as const}
                              control={control}
                              rules={{ required: true }}
                            />
                            <Grid item sx={errorMessageStyle}>
                              {!filterDropdownClassifications.length &&
                                `Unable to add a new classification. Please contact your Supplier Diversity representative to have the classification added.`}
                            </Grid>
                          </Grid>
                        )}
                        {classification.name && (
                          <Grid sx={classificationName}>
                            <SmallBusinessClassificationDisplayName
                              classification={classification}
                              displayFormat="full"
                              tooltipFormat="full"
                              indent={true}
                              noWrap={true}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid item sx={classificationColumnPercentageEdit}>
                        {(!isEditing ||
                          [
                            SmallBusinessClassificationName.LB.toString(),
                            FederalSmallBusinessClassificationName.IC.toString(),
                            SmallBusinessClassificationName.SBT.toString(),
                          ].includes(classification.name)) && (
                          <Typography
                            sx={
                              Number(classification.goalPercent) < 0
                                ? classificationPercentageError
                                : classificationPercentage
                            }
                          >
                            <NumberFormat
                              value={classification.goalPercent}
                              displayType={'text'}
                              suffix={'%'}
                              decimalScale={2}
                              allowLeadingZeros={false}
                              isNumericString={true}
                              margin="dense"
                              variant="outlined"
                              color="primary"
                            />
                          </Typography>
                        )}
                        {isEditing &&
                          ![
                            SmallBusinessClassificationName.LB.toString(),
                            FederalSmallBusinessClassificationName.IC.toString(),
                          ].includes(classification.name) && (
                            <Grid sx={classificationCardContainer}>
                              <Controller
                                render={({ field }) => (
                                  <NumberFormat
                                    {...field}
                                    decimalScale={2}
                                    allowLeadingZeros={false}
                                    isNumericString={true}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth={true}
                                    color="primary"
                                    customInput={TextField}
                                    error={hasError(index)}
                                    helperText={
                                      hasError(index) ? 'Value must be a number in the range of 0 to 100' : ''
                                    }
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                  />
                                )}
                                name={`classifications.${index}.goalPercent` as const}
                                control={control}
                                rules={{ min: 0, max: 100 }}
                              />
                            </Grid>
                          )}
                      </Grid>
                      <Grid
                        item
                        sx={isFederalReport ? classificationColumnSecondaryFed : classificationColumnSecondary}
                      >
                        <Typography sx={classificationRowText}>
                          <NumberFormat
                            value={classification.goalTotal}
                            prefix={'$'}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            allowLeadingZeros={false}
                            isNumericString={true}
                            thousandSeparator={true}
                            margin="dense"
                            variant="outlined"
                            color="primary"
                          />
                        </Typography>
                      </Grid>
                      {/* First Tier */}
                      {!isFederalReport && (
                        <>
                          <Grid item sx={classificationColumnPercentage}>
                            <Typography sx={classificationPercentage}>
                              <NumberFormat
                                value={classification.firstTierPercentTotal}
                                displayType={'text'}
                                suffix={'%'}
                                decimalScale={2}
                                allowLeadingZeros={false}
                                isNumericString={true}
                                margin="dense"
                                variant="outlined"
                                color="primary"
                              />
                            </Typography>
                          </Grid>
                          <Grid item sx={classificationColumnSecondary}>
                            <Typography sx={classificationRowText}>
                              <NumberFormat
                                value={classification.firstTierTotal}
                                prefix={'$'}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                allowLeadingZeros={false}
                                isNumericString={true}
                                thousandSeparator={true}
                                margin="dense"
                                variant="outlined"
                                color="primary"
                              />
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {/* Lower Tier */}
                      {!isFederalReport && (
                        <>
                          <Grid item sx={classificationColumnPercentage}>
                            <Typography sx={classificationPercentage}>
                              <NumberFormat
                                value={classification.lowerTierPercentTotal}
                                displayType={'text'}
                                suffix={'%'}
                                decimalScale={2}
                                allowLeadingZeros={false}
                                isNumericString={true}
                                margin="dense"
                                variant="outlined"
                                color="primary"
                              />
                            </Typography>
                          </Grid>
                          <Grid item sx={classificationColumnSecondary}>
                            <Typography sx={classificationRowText}>
                              <NumberFormat
                                value={classification.lowerTierParticipationTotal}
                                prefix={'$'}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                allowLeadingZeros={false}
                                isNumericString={true}
                                thousandSeparator={true}
                                margin="dense"
                                variant="outlined"
                                color="primary"
                              />
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {/*Actual Cumulative*/}
                      <Grid
                        item
                        sx={isFederalReport ? classificationColumnPercentageFed : classificationColumnPercentage}
                      >
                        <Typography sx={classificationPercentage}>
                          <NumberFormat
                            value={classification.actualPercentTotal}
                            displayType={'text'}
                            suffix={'%'}
                            decimalScale={2}
                            allowLeadingZeros={false}
                            isNumericString={true}
                            margin="dense"
                            variant="outlined"
                            color="primary"
                          />
                        </Typography>
                      </Grid>
                      <Grid item sx={classificationColumnSecondary}>
                        <Typography
                          sx={
                            isFederalReport &&
                            classification.name === SmallBusinessClassificationName.LB &&
                            classification.actualTotal > 1500000
                              ? classificationRowTextHighlighted
                              : classificationRowText
                          }
                        >
                          <NumberFormat
                            value={classification.actualTotal}
                            prefix={'$'}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            allowLeadingZeros={false}
                            isNumericString={true}
                            thousandSeparator={true}
                            margin="dense"
                            variant="outlined"
                            color="primary"
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {isEditing && (
            <Grid item xs={12} sx={actionsContainer}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid container alignItems="center" direction="row" spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <StyledButtonSecondary label="Cancel" onClick={handleClose} disabled={isLoading} />
                    </Grid>
                    <Grid item>
                      <StyledButtonPrimary
                        type="submit"
                        label="Submit"
                        disabled={canSubmit()}
                        onClick={handleSubmit(onSubmit)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!isFederalReport && (
                  <Grid item>
                    <StyledButtonSecondary
                      label="Add New Classification"
                      onClick={() => {
                        append({
                          abbreviation: '',
                          actualPercentOriginal: 0,
                          actualPercentTotal: 0,
                          actualTotal: 0,
                          firstTierPercentOriginal: 0,
                          firstTierPercentTotal: 0,
                          firstTierTotal: 0,
                          goalPercent: '0',
                          goalTotal: 0,
                          lowerTierParticipationPercentOriginal: 0,
                          lowerTierParticipationTotal: 0,
                          lowerTierPercentTotal: 0,
                          name: '',
                          smallBusinessClassificationId: '',
                          smallBusinessReportClassificationId: '',
                          sortOrder: 1,
                          isFederal: false,
                          __typename: 'SmallBusinessReportClassificationSummary',
                        });
                      }}
                      disabled={isLoading}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </Card>
  );
};

export default SmallBusinessClassificationsCard;
