import RelatedContractsSummary from 'components/RelatedContractsSummary';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import StyledNotice, { NoticeTypeOption } from 'components/shared/Notice';
import StyledDatePicker from 'components/shared/StyledDatePicker/StyledDatePicker';
import {
  FederalSmallBusinessClassificationName,
  SmallBusinessClassificationName,
} from 'constants/classificationNames';
import { GET_SINGLE_COMPANY } from 'graphql/companies';
import { UPLOAD_COMPANY_FILE } from 'graphql/companyFile';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { Fragment, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  CompanyFileCreateInput,
  GetSmallBusinessClassificationsQuery,
  SmallBusinessAgencyListQuery,
  SmallBusinessClassification,
  useSmallBusinessAgencyListQuery,
} from 'types/generated/graphql';
import { convertUTCDateToLocalDate, generateTransactionKey } from 'utils/general';

import { useApolloClient, useMutation } from '@apollo/client';
import {
  Button,
  Chip,
  Divider,
  Grid,
  InputLabel,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const containerStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(0),
});

const itemStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(1),
});

const inputLabelStyles: SxProps<Theme> = {
  marginBottom: -0.5,
  marginTop: 1,
};

const chooseFileButton: SxProps<Theme> = (theme: Theme) => ({
  borderRadius: theme.spacing(2),
});

const chooseFileButtonLabel: SxProps<Theme> = {
  fontSize: '0.625rem',
  fontWeight: 'bold',
};

const textFieldStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(0),
});

type PickedSmallBusinessClassification = Pick<
  SmallBusinessClassification,
  'id' | 'name' | 'abbreviation' | 'sortOrder'
>;

type AddCompanyDocumentDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  classificationType?: string;
  smallBusinessClassifications: PickedSmallBusinessClassification[];
};

type useParamsProps = {
  companyId?: string;
};

const AddCompanyDocumentDialog: React.FC<AddCompanyDocumentDialogProps & Record<string, any>> = ({
  isOpen,
  setIsOpen,
  classificationType,
  smallBusinessClassifications,
}) => {
  const { companyId } = useParams<useParamsProps>();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();

  type SmallBusinessClassificationsSelection = GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'];
  type SmallBusinessAgencyListSelection = SmallBusinessAgencyListQuery['smallBusinessAgencyList'];

  const { data: smallBusinessAgenciesData, loading: smallBusinessAgenciesLoading } = useSmallBusinessAgencyListQuery({
    variables: {
      isActive: [true],
    },
  });
  const smallBusinessAgencyList: SmallBusinessAgencyListSelection =
    smallBusinessAgenciesData?.smallBusinessAgencyList?.filter((agency) => agency.isFederal !== true) ?? [];

  const smallBusinessAgenciesById = smallBusinessAgencyList.reduce<{
    [id: string]: SmallBusinessAgencyListSelection[number];
  }>((accumulator: { [x: string]: any }, agency: { id: string }) => {
    accumulator[agency.id] = agency;
    return accumulator;
  }, {});

  const smallBusinessClassificationsById = smallBusinessClassifications?.reduce<{
    [id: string]: SmallBusinessClassificationsSelection[number];
  }>((accumulator, classification) => {
    accumulator[classification.id] = classification;
    return accumulator;
  }, {});

  const smallBusinessClassificationIdByName = smallBusinessClassifications.reduce(
    (accumulator: { [x: string]: any }, classification: { name: string; id: any }) => {
      accumulator[classification.name] = classification.id;
      return accumulator;
    },
    {},
  );

  const [uploadCompanyDocument, { loading: isLoading }] = useMutation(UPLOAD_COMPANY_FILE, {
    refetchQueries: [
      {
        query: GET_SINGLE_COMPANY,
        variables: { id: companyId },
      },
    ],
    awaitRefetchQueries: true,
  });

  type FormValues = {
    federalSmallBusinessClassificationIds: string[];
    smallBusinessClassificationIds: string[];
    smallBusinessAgency: string;
    federalAgency: string;
    startLocalizedDate: string;
    endLocalizedDate: string;
    // file inputs are represented as arrays even when the "multiple" attribute is not used.
    file: { name: string }[];
  } & Omit<CompanyFileCreateInput, 'smallBusinessClassificationCoverages' | 'file'>;

  const defaultValues = {
    title: '',
    notes: '',
    file: undefined,
    federalSmallBusinessClassificationIds: [],
    smallBusinessClassifications: [],
    smallBusinessAgency: '',
    federalAgency: 'SAM.gov',
    startLocalizedDate: undefined,
    endLocalizedDate: undefined,
  };

  const { handleSubmit, control, watch, setValue, register, reset, formState } = useForm<FormValues>({ defaultValues });

  const { isValid } = formState;
  // file inputs are represented as arrays even when the "multiple" attribute is not used. Grab the first element only.
  const [selectedFile] = watch('file') ?? [undefined];
  const selectedFederalSmallBusinessClassificationIds = watch('federalSmallBusinessClassificationIds');
  const selectedSmallBusinessClassificationIds = watch('smallBusinessClassificationIds');
  const selectedStartLocalizedDate = watch('startLocalizedDate') ?? undefined;
  const selectedSmallBusinessAgency = watch('smallBusinessAgency');

  const findClassificationsBySelectedAgency = smallBusinessAgencyList?.find(
    (agency: { id: string }) => agency.id === selectedSmallBusinessAgency,
  )?.smallBusinessAgencyClassifications;

  const agencyAcceptedClassifications = findClassificationsBySelectedAgency
    ? [...findClassificationsBySelectedAgency].sort((a, b) =>
        a.smallBusinessClassification.name.localeCompare(b.smallBusinessClassification.name),
      )
    : [];

  const selectedSmallBusinessAgencyUrl = smallBusinessAgencyList?.find(
    (agency) => agency.id === selectedSmallBusinessAgency,
  )?.url as string;

  const truncatedSmallBusinessAgencyUrl =
    selectedSmallBusinessAgencyUrl && selectedSmallBusinessAgencyUrl.length > 30
      ? `${selectedSmallBusinessAgencyUrl.substring(0, 30)}...`
      : selectedSmallBusinessAgencyUrl;

  const agencyAcceptedClassificationListById =
    agencyAcceptedClassifications?.map(
      (classification: { smallBusinessClassification: { id: string } }) =>
        classification.smallBusinessClassification.id,
    ) ?? [];

  const smallBusinessClassificationListById = smallBusinessClassifications
    ?.filter((classification) => classification.name !== SmallBusinessClassificationName.LB)
    .map((classification) => classification.id);

  const areAnyClassificationsSelected =
    !!selectedFederalSmallBusinessClassificationIds?.length || !!selectedSmallBusinessClassificationIds?.length;

  const isSmallBusinessAgencySelected = !!selectedSmallBusinessAgency;

  const handleClose = () => {
    setIsOpen(false);
    reset(defaultValues);
    setTransactionKey(generateTransactionKey());
  };

  const federalSmallBusinessAgency = smallBusinessAgenciesData?.smallBusinessAgencyList?.find(
    (agency) => agency.name === 'SAM.gov',
  );

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const startDate = DateTime.fromISO(data.startLocalizedDate).toISODate();
    const endDate = DateTime.fromISO(data.endLocalizedDate).toISODate();
    const isFederal = classificationType === 'federalSmallBusinessClassifications' ? true : false;
    const smallBusinessAgencyId = isFederal ? federalSmallBusinessAgency?.id : selectedSmallBusinessAgency;

    return uploadCompanyDocument({
      variables: {
        input: {
          transactionKey,
          title: data.title,
          notes: data.notes ?? undefined,
          file: data.file[0],
          companyId,
          smallBusinessClassificationCoverages:
            [
              ...(selectedSmallBusinessClassificationIds ?? []),
              ...(selectedFederalSmallBusinessClassificationIds ?? []),
            ]?.map((classificationId) => ({
              smallBusinessClassificationId: classificationId,
              startDate,
              endDate,
              isFederal,
              smallBusinessAgencyId: smallBusinessAgencyId,
            })) ?? [],
        },
      },
    })
      .then(() => {
        displayToast('The file was uploaded successfully', 'success');
        handleClose();
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying to upload your file. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <StyledDialog
      title={'Add New Document'}
      isLoading={isLoading}
      content={
        <Grid container direction="column" sx={containerStyles}>
          <form>
            <Grid item xs={12} sx={itemStyles}>
              <InputLabel sx={inputLabelStyles}>Title</InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField {...field} variant="outlined" margin="dense" fullWidth size="small" />
                )}
                name="title"
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} sx={itemStyles}>
              <InputLabel sx={inputLabelStyles}>Notes</InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField {...field} variant="outlined" margin="dense" fullWidth multiline size="small" />
                )}
                name="notes"
                control={control}
              />
            </Grid>
            {classificationType === 'federalSmallBusinessClassifications' && (
              <>
                <Grid item xs={12} sx={itemStyles}>
                  <InputLabel sx={inputLabelStyles}>Agency</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        size="small"
                        disabled
                        defaultValue={'SAM.gov'}
                      />
                    )}
                    name="federalAgency"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sx={itemStyles}>
                  <Divider variant="middle" light={true} />
                </Grid>
                <InputLabel sx={inputLabelStyles}>
                  Federal Small Business Classifications certified by this document
                </InputLabel>
                <Controller
                  render={({ field }) => (
                    <Grid item xs={12} sx={itemStyles}>
                      <Autocomplete
                        {...field}
                        onChange={(_: any, classificationIds: string[]) => {
                          // SDVOSB is implied by VOSB being selected.
                          const sdvosbId =
                            smallBusinessClassificationIdByName[FederalSmallBusinessClassificationName.SDVOSB];
                          const vosbId =
                            smallBusinessClassificationIdByName[FederalSmallBusinessClassificationName.VOSB];
                          if (
                            classificationIds.some((id) => id === sdvosbId) &&
                            !classificationIds.some((id) => id === vosbId)
                          ) {
                            classificationIds.push(vosbId);
                          }
                          // SB is implied by any other small business classification being selected.
                          const sbId = smallBusinessClassificationIdByName[FederalSmallBusinessClassificationName.SB];
                          if (classificationIds.length && !classificationIds.some((id) => id === sbId)) {
                            classificationIds.push(sbId);
                          }

                          setValue('federalSmallBusinessClassificationIds', classificationIds);
                        }}
                        id="combo-box-classifications"
                        multiple
                        options={
                          smallBusinessClassifications
                            ?.filter(
                              (classification) =>
                                classification.name !== FederalSmallBusinessClassificationName.LB &&
                                classification.name !== FederalSmallBusinessClassificationName.SBT,
                            )
                            .map((classification: { id: string }) => classification.id) ?? []
                        }
                        filterSelectedOptions
                        getOptionLabel={(option: string) => {
                          const classification = smallBusinessClassificationsById[option];
                          return `${classification.abbreviation} (${classification.name})`;
                        }}
                        renderTags={(value: string[], getTagProps: any) =>
                          value
                            .map((option, index) => ({
                              classification: smallBusinessClassificationsById[option],
                              index,
                            }))
                            .sort((a, b) => a.classification.sortOrder - b.classification.sortOrder)
                            .map(({ classification, index }) => {
                              const areMultipleClassificationsSelected = value.length > 1;
                              const isImplicitSbSelection =
                                classification.name === FederalSmallBusinessClassificationName.SB &&
                                areMultipleClassificationsSelected;
                              const isImplicitVosbSelection =
                                classification.name === FederalSmallBusinessClassificationName.VOSB &&
                                value.some(
                                  (option) =>
                                    option ===
                                    smallBusinessClassificationIdByName[FederalSmallBusinessClassificationName.SDVOSB],
                                );
                              const tooltipSuffix = isImplicitSbSelection
                                ? ' (required when any other Federal Small Business Classification has been selected)'
                                : isImplicitVosbSelection
                                ? ' (required when Service Disabled Veteran-Owned Small Business has been selected)'
                                : '';
                              return (
                                <Tooltip arrow title={`${classification.name}${tooltipSuffix}`}>
                                  {/* This div allows the tooltip to work with disabled chips. */}
                                  <div key={index}>
                                    <Chip
                                      variant="outlined"
                                      label={classification.abbreviation}
                                      {...getTagProps({ index })}
                                      disabled={isImplicitSbSelection || isImplicitVosbSelection}
                                    />
                                  </div>
                                </Tooltip>
                              );
                            })
                        }
                        renderInput={(params: any) => <TextField {...params} sx={textFieldStyles} key={params.id} />}
                        size="small"
                      />
                    </Grid>
                  )}
                  name={'federalSmallBusinessClassificationIds'}
                  control={control}
                />
              </>
            )}
            <Grid>
              {classificationType === 'nonFederalSmallBusinessClassifications' && (
                <>
                  <Grid item>
                    <InputLabel sx={inputLabelStyles}>Agency</InputLabel>
                    <Controller
                      name="smallBusinessAgency"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          onChange={(_: any, agencyId: string | null) => {
                            setValue('smallBusinessAgency', agencyId as string);
                            field.onChange(agencyId);
                            setValue('smallBusinessClassificationIds', []);
                          }}
                          id="combo-box-agencies"
                          options={smallBusinessAgencyList?.map((agency: { id: string }) => agency.id) ?? []}
                          getOptionLabel={(option: string) => {
                            const agency = smallBusinessAgenciesById[option];
                            return `${agency?.name ?? ''}`;
                          }}
                          renderInput={(params: any) => <TextField {...params} />}
                          size="small"
                          loading={smallBusinessAgenciesLoading}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={itemStyles}>
                    {isSmallBusinessAgencySelected && selectedSmallBusinessAgencyUrl !== null && (
                      <Fragment>
                        <Grid item xs={12} sx={itemStyles}>
                          <Divider variant="middle" light={true} />
                        </Grid>
                        <StyledNotice
                          type={NoticeTypeOption.Notice}
                          message={
                            <Typography>
                              For agency certification documentation, please go to:{' '}
                              {
                                <a
                                  href={selectedSmallBusinessAgencyUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                  title={selectedSmallBusinessAgencyUrl}
                                >
                                  {truncatedSmallBusinessAgencyUrl}
                                </a>
                              }
                            </Typography>
                          }
                        />
                      </Fragment>
                    )}
                  </Grid>
                  <Grid item xs={12} sx={itemStyles}>
                    <Divider variant="middle" light={true} />
                  </Grid>
                  <InputLabel sx={inputLabelStyles}>Business Classifications certified by this document</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Grid item xs={12} sx={itemStyles}>
                        <Autocomplete
                          {...field}
                          value={selectedSmallBusinessClassificationIds ?? []}
                          onChange={(_: any, classificationIds: string[]) => {
                            setValue('smallBusinessClassificationIds', classificationIds);
                          }}
                          id="combo-box-classifications"
                          multiple
                          options={
                            agencyAcceptedClassificationListById?.length > 0
                              ? agencyAcceptedClassificationListById
                              : smallBusinessClassificationListById ?? []
                          }
                          filterSelectedOptions
                          getOptionLabel={(option: string) => {
                            const classification = smallBusinessClassificationsById[option];
                            return `${classification.abbreviation} (${classification.name})`;
                          }}
                          renderTags={(value: string[], getTagProps: any) => {
                            return value.map((option, index) => {
                              const classification = smallBusinessClassificationsById[option];
                              return (
                                <Tooltip arrow title={`${classification.name}`}>
                                  <div key={index}>
                                    <Chip
                                      variant="outlined"
                                      label={classification.abbreviation}
                                      {...getTagProps({ index })}
                                    />
                                  </div>
                                </Tooltip>
                              );
                            });
                          }}
                          renderInput={(params: any) => <TextField {...params} sx={textFieldStyles} />}
                          size="small"
                          disabled={!selectedSmallBusinessAgency}
                        />
                      </Grid>
                    )}
                    name={'smallBusinessClassificationIds'}
                    control={control}
                  />
                </>
              )}
            </Grid>
            {areAnyClassificationsSelected && (
              <Fragment>
                {classificationType === 'federalSmallBusinessClassifications' && (
                  <Fragment>
                    <Grid item xs={12} sx={itemStyles}>
                      <Divider variant="middle" light={true} />
                    </Grid>
                    <StyledNotice
                      type={NoticeTypeOption.Notice}
                      message={
                        <Typography>
                          If uploading a SAM.gov file: Note the certification dates are not the dates of the project,
                          but the Entity Start Date and the Expiration Date according to SAM.gov
                        </Typography>
                      }
                    />
                  </Fragment>
                )}
                <Grid item xs={12} sx={itemStyles}>
                  <Grid container>
                    <Controller
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <Grid item xs={12} sm={6}>
                          <InputLabel required sx={inputLabelStyles}>
                            Certification start date
                          </InputLabel>
                          <StyledDatePicker
                            value={value ? convertUTCDateToLocalDate(value) : null}
                            handleDateChange={(newDate) => {
                              onChange(newDate);
                            }}
                            shouldDisplayAsError={!!error}
                            isRequired={!value}
                            helperText="Invalid Date Format"
                          />
                        </Grid>
                      )}
                      name="startLocalizedDate"
                      control={control}
                      rules={{
                        required: areAnyClassificationsSelected,
                      }}
                    />
                    <Controller
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <Grid item xs={12} sm={6}>
                          <InputLabel required sx={inputLabelStyles}>
                            Certification end date
                          </InputLabel>
                          <StyledDatePicker
                            value={value ? convertUTCDateToLocalDate(value) : null}
                            handleDateChange={(newDate) => {
                              onChange(newDate);
                            }}
                            shouldDisplayAsError={!!error}
                            minDate={selectedStartLocalizedDate}
                            isRequired={!value}
                            helperText="Invalid Date Format"
                          />
                        </Grid>
                      )}
                      name="endLocalizedDate"
                      control={control}
                      rules={{
                        required: areAnyClassificationsSelected,
                      }}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            )}
            <Grid item xs={12} sx={itemStyles}>
              <Divider variant="middle" light={true} />
            </Grid>
            <Grid item xs={12} sx={itemStyles}>
              <section>
                <InputLabel sx={inputLabelStyles}>Uploaded File (PDFs and Image types only)</InputLabel>
                <Grid container direction="row" spacing={2} alignItems="center">
                  <Grid item>
                    <Button sx={chooseFileButton} variant="contained" component="label">
                      <Typography sx={chooseFileButtonLabel}>Choose File</Typography>
                      <input
                        {...register('file', {
                          required: true,
                        })}
                        type="file"
                        hidden
                        name="file"
                        accept=".pdf,image/*"
                      />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">{selectedFile?.name ?? 'No file chosen'}</Typography>
                  </Grid>
                </Grid>
              </section>
            </Grid>
          </form>
          {companyId && selectedStartLocalizedDate && (
            <Grid item>
              <RelatedContractsSummary
                apolloClient={apolloClient}
                companyId={companyId}
                afterIsoDate={selectedStartLocalizedDate}
              />
            </Grid>
          )}
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary disabled={isLoading} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={'submit'}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  // the form is reporting valid in some situations where no file is selected, even though it is required.
                  disabled={!isValid || !selectedFile || isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

export default AddCompanyDocumentDialog;
