import heroImage from 'assets/images/reportAnalysis.png';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import Dialog from 'components/shared/Dialog';
import SmallBusRequirementField from 'components/shared/SmallBusRequirementField';
import { HP_CLIENT_NAME } from 'constants/clientOptions';
import { SmallBusinessRequirementOption } from 'constants/smallBusinessRequirementOptions';
import { GET_SINGLE_PROJECT, UPDATE_PROJECT } from 'graphql/projects';
import useProject from 'hooks/useProject';
import useToast from 'hooks/useToast';
import { FC, Fragment, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useParams } from 'react-router-dom';
import { SmallBusinessClient, useSmallBusinessClientListQuery } from 'types/generated/graphql';
import { generateTransactionKey } from 'utils/general';

import { useMutation } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Card,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

const container: SxProps<Theme> = {
  height: 'calc(100vh - 170px)',
};

const heroImageStyles: SxProps<Theme> = (theme: Theme) => ({
  maxWidth: 200,
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: { maxWidth: 150 },
});

const mainTitle: SxProps<Theme> = (theme: Theme) => ({
  fontSize: '.875rem',
  textTransform: 'uppercase',
  textAlign: 'center',
  marginBottom: theme.spacing(3),
});

const projectNameStyles: SxProps<Theme> = {
  fontWeight: 'bold',
  fontSize: '1.5rem',
};

const card: SxProps<Theme> = (theme: Theme) => ({
  borderTop: '8px solid' + theme.palette.primary.main,
  padding: theme.spacing(2, 6),
  maxWidth: 850,
  [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) },
});

const section: SxProps<Theme> = (theme: Theme) => ({
  marginTop: theme.spacing(2),
});

const sectionTitle: SxProps<Theme> = {
  fontWeight: 'bold',
};

const radioGroup: SxProps<Theme> = (theme: Theme) => ({
  marginLeft: theme.spacing(3),
});

const radioLabel: SxProps<Theme> = (theme: Theme) => ({
  fontSize: '0.875rem',
  marginTop: theme.spacing(0.5),
});

const inputContainer: SxProps<Theme> = (theme: Theme) => ({
  margin: theme.spacing(0, 0, 3, 3),
});

const actionsContainer: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: theme.spacing(2),
});

const notConfiguredMessage: SxProps<Theme> = (theme: Theme) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(2),
});

type PickedClients = Pick<SmallBusinessClient, 'id' | 'name' | 'abbreviation'>;

type CreateFormInput = {
  isActualCalculationsBasedOnTotalContractValue?: string;
  originalContractAmount?: string;
  smallBusinessRequirement?: string;
  smallBusinessClient?: PickedClients | null;
};

type useParamsProps = {
  projectId?: string;
};

type SmallBusReportSettingsCreateFormProps = {
  projectName?: string;
  editable?: boolean;
};

const SmallBusReportSettingsCreateForm: FC<SmallBusReportSettingsCreateFormProps> = ({ projectName, editable }) => {
  const { projectId } = useParams<useParamsProps>();
  const { project } = useProject(projectId);
  const [isOpen, setIsOpen] = useState(false);

  const defaultValues = {
    isActualCalculationsBasedOnTotalContractValue:
      project?.smallBusinessReportSettings?.isActualCalculationsBasedOnTotalContractValue !== undefined
        ? project.smallBusinessReportSettings.isActualCalculationsBasedOnTotalContractValue.toString()
        : '',
    originalContractAmount: project?.originalContractValue ? project.originalContractValue.toString() : '',
    smallBusinessRequirement: project?.smallBusinessRequirement ? project.smallBusinessRequirement : '',
    smallBusinessClient: project?.smallBusinessClient,
  };
  const { handleSubmit, control, watch, formState, setValue } = useForm<CreateFormInput>({ defaultValues });
  const [transactionKey] = useState(generateTransactionKey());
  const { displayToast } = useToast();
  const history = useHistory();

  const { data: smallBusinessClientData } = useSmallBusinessClientListQuery({
    variables: {
      isActive: [true],
    },
  });

  const [updateProject, { loading: isLoading }] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [
      {
        query: GET_SINGLE_PROJECT,
        variables: { id: projectId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const { isValid, isDirty } = formState;

  const originalContractAmount = watch('originalContractAmount');
  const smallBusinessRequirement = watch('smallBusinessRequirement');
  const smallBusinessClient = watch('smallBusinessClient');
  const isActualCalculationsBasedOnTotalContractValue = watch('isActualCalculationsBasedOnTotalContractValue');

  const getClientList = () => {
    const clientData = smallBusinessClientData?.smallBusinessClientList ?? [];
    return !smallBusinessRequirement
      ? []
      : smallBusinessRequirement === SmallBusinessRequirementOption.HP_SELF_REPORTING
      ? clientData.filter((client) => client.name === HP_CLIENT_NAME)
      : clientData.filter(
          (client) =>
            client.isFederal !== (smallBusinessRequirement !== SmallBusinessRequirementOption.FEDERAL) &&
            client.name !== HP_CLIENT_NAME,
        );
  };

  let clientList = getClientList();
  const setClient = (_: any, client: PickedClients | null) => {
    setValue('smallBusinessClient', client ?? null);
  };

  const onCancel = () => {
    history.push('/projects');
  };

  const handleRequirementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('smallBusinessRequirement', event.target.value);
    setValue('smallBusinessClient', null);
    clientList = getClientList();
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const onSubmit: SubmitHandler<CreateFormInput> = (data) => {
    return updateProject({
      variables: {
        input: {
          id: projectId,
          originalContractValue: data.originalContractAmount
            ? parseFloat(data.originalContractAmount.replaceAll(',', ''))
            : undefined,
          smallBusinessRequirement: data.smallBusinessRequirement,
          smallBusinessReportSettings: {
            transactionKey,
            isActualCalculationsBasedOnTotalContractValue: data.isActualCalculationsBasedOnTotalContractValue
              ? JSON.parse(data.isActualCalculationsBasedOnTotalContractValue)
              : undefined,
          },
          smallBusinessClientId: data.smallBusinessClient?.id ?? undefined,
        },
      },
    })
      .then(() => {
        handleCloseDialog();
        displayToast('The small business report settings were updated successfully', 'success');
        history.push(`/projects/${projectId}/reports/small-business-report`);
      })
      .catch((error) => {
        console.error('Create smallBusReportSettings failed: ', error);
        displayToast(
          'Error: Something went wrong while trying submit your changes. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <Grid container justifyContent="center" alignContent="center" sx={container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item>
          <Card sx={card}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignContent="center" alignItems="center">
                  <Box component="img" src={heroImage} alt={'Man Analyzing a Report'} sx={heroImageStyles}></Box>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={section}>
                <Typography sx={mainTitle}>
                  Small Business Report
                  <br />
                  {projectName && (
                    <Box component="span" sx={projectNameStyles}>
                      {projectName}
                    </Box>
                  )}
                </Typography>
                {editable && (
                  <Fragment>
                    <Typography sx={sectionTitle}>Select the small business requirement</Typography>
                    <section>
                      <Controller
                        render={({ field }) => (
                          <SmallBusRequirementField {...field} onChange={handleRequirementChange} />
                        )}
                        name="smallBusinessRequirement"
                        control={control}
                        rules={{ required: true }}
                      />
                    </section>
                    <Typography sx={sectionTitle}>Select the contract reporting requirement</Typography>
                    <section>
                      <Controller
                        render={({ field }) => (
                          <RadioGroup aria-label="" {...field} sx={radioGroup}>
                            <Grid container direction="row" spacing={2}>
                              <Grid item>
                                <FormControlLabel
                                  value="true"
                                  control={<Radio size="small" color="primary" />}
                                  label={<Typography sx={radioLabel}>Total Contract Amount</Typography>}
                                />
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  value="false"
                                  control={<Radio size="small" color="primary" />}
                                  label={<Typography sx={radioLabel}>Subcontracted Dollars Only</Typography>}
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        )}
                        name="isActualCalculationsBasedOnTotalContractValue"
                        control={control}
                        rules={{ required: true }}
                      />
                    </section>
                    <Typography sx={sectionTitle}>Reporting Agency</Typography>
                    <Grid item xs={8}>
                      <section>
                        <Controller
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              sx={inputContainer}
                              color="primary"
                              options={clientList}
                              getOptionLabel={(option: { name: string }) => option.name}
                              renderInput={(params: any) => <TextField {...params} />}
                              onChange={setClient}
                            />
                          )}
                          name="smallBusinessClient"
                          control={control}
                          rules={{ required: true }}
                        />
                      </section>
                    </Grid>
                    <Grid container direction="row">
                      <Typography sx={sectionTitle}>
                        Enter Original Contract Amount (From Small Business Contracting Plan) *
                      </Typography>
                      <Grid item xs={12} sx={inputContainer}>
                        <Controller
                          render={({ field }) => (
                            <NumberFormat
                              {...field}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowLeadingZeros={false}
                              isNumericString={true}
                              thousandSeparator={true}
                              customInput={TextField}
                              margin="dense"
                              variant="outlined"
                              fullWidth={true}
                              color="primary"
                              error={!originalContractAmount}
                              required={true}
                              helperText={'Required'}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                            />
                          )}
                          name="originalContractAmount"
                          control={control}
                          rules={{ required: true, min: 0 }}
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
                {!editable && (
                  <Grid container justifyContent="center" alignContent="center" alignItems="center">
                    <Typography sx={notConfiguredMessage}>
                      This report must be configured by an admin before it can be viewed.
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sx={actionsContainer}>
                {editable && (
                  <Grid container justifyContent="center" alignContent="center" alignItems="center">
                    {' '}
                    <StyledButtonPrimary
                      disabled={!isValid || !isDirty || isLoading}
                      label={'Submit'}
                      onClick={handleOpenDialog}
                    />
                  </Grid>
                )}
                {!editable && (
                  <Grid container justifyContent="center" alignContent="center" alignItems="center">
                    <StyledButtonSecondary onClick={onCancel} label={'Back to project list'} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Dialog
          data-testid="confirm-dialog"
          isOpen={isOpen}
          handleClose={handleCloseDialog}
          maxWidth="sm"
          title={'Small Business Report Change Confirmation'}
          content={
            <Grid container>
              <Grid item>
                <Typography>
                  {`Project ${project?.name} has ${smallBusinessRequirement} reporting on ${
                    isActualCalculationsBasedOnTotalContractValue ? 'the total contract value' : 'subcontracted totals'
                  } to ${smallBusinessClient?.name}.`}
                </Typography>
              </Grid>
              <Grid item sx={section}>
                <Typography>
                  Small Business Requirement, Contract Report Requirement, and Reporting Agency cannot be modified once
                  submitted. Please verify that the changes are correct before submitting.
                </Typography>
              </Grid>
            </Grid>
          }
          actions={
            <Fragment>
              <StyledButtonSecondary onClick={handleCloseDialog} label={'Cancel'} />
              <StyledButtonPrimary type={'submit'} onClick={handleSubmit(onSubmit)} label={'Submit'} />
            </Fragment>
          }
        />
      </form>
    </Grid>
  );
};
export default SmallBusReportSettingsCreateForm;
