import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import { GET_SINGLE_PROJECT, UPDATE_PROJECT } from 'graphql/projects';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Project, ProjectSmallBusinessReport, SmallBusinessClient } from 'types/generated/graphql';

import { useMutation } from '@apollo/client';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Box, Card, Grid, IconButton, InputAdornment, SxProps, TextField, Theme, Typography } from '@mui/material';

const sectionStyles: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: theme.spacing(2),
});

const cardStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2, 2, 2.5, 2),
});

const cardColumnContainer: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(2) },
});

const cardColumnTitle: SxProps<Theme> = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
};

const cardColumnTitleActive: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.main,
});

const cardColumnIconContainer: SxProps<Theme> = (theme: Theme) => ({
  marginRight: theme.spacing(0.5),
});

const cardColumnContainerActive: SxProps<Theme> = (theme: Theme) => ({
  border: '1px solid' + theme.palette.primary.main,
  paddingRight: 4,
});

const cardColumnIcon: SxProps<Theme> = {
  color: '#636363',
  fontSize: '3rem',
};

const editButtonContainer: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: -8,
  [theme.breakpoints.down('md')]: { marginBottom: 0 },
});

const labelEditing: SxProps<Theme> = {
  fontWeight: 'bold',
};

const editingModeIndicator: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(4, 4, 0, 0),
});

const editingContainer: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(4),
});

const notesCard: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: '#f2f2f2',
  boxShadow: 'none',
  padding: theme.spacing(2),
});

const notesSection: SxProps<Theme> = {
  whiteSpace: 'pre-wrap',
};

const notesLabel: SxProps<Theme> = (theme: Theme) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
});

const projectField: SxProps<Theme> = {
  paddingTop: 2,
};

type CardColumnProps = {
  icon?: React.ReactNode;
  title: string;
  amount?: number;
  contractNumber?: string;
  isActive?: Boolean;
};

const CardColumn: FC<CardColumnProps> = ({ icon, title, amount, contractNumber, isActive = false }) => {
  return (
    <Grid container direction="row" alignItems="center" sx={cardColumnContainer}>
      <Box component="span" sx={isActive ? cardColumnContainerActive : undefined}>
        <Grid container direction="row">
          <Grid item sx={cardColumnIconContainer}>
            {icon}
          </Grid>
          <Grid item>
            <Typography sx={isActive ? cardColumnTitleActive : cardColumnTitle} color="textSecondary">
              {title}
            </Typography>
            {!!amount && (
              <NumberFormat
                value={amount}
                displayType={'text'}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
                allowLeadingZeros={false}
                isNumericString={true}
                thousandSeparator={true}
                margin="dense"
                variant="outlined"
                color="primary"
              />
            )}
            {!!contractNumber && <Typography>{contractNumber}</Typography>}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

type PickedClients = Pick<SmallBusinessClient, 'id' | 'name' | 'abbreviation'>;

type UpdateFormInput = {
  isActualCalculationsBasedOnTotalContractValue?: string;
  originalContractAmount?: string;
  primeContractNumber?: string;
  smallBusinessRequirement?: string;
  notes?: string;
  client?: PickedClients | null;
};

type SmallBusinessContractAmountsCardProps = {
  project?: Project;
  report?: ProjectSmallBusinessReport;
  editable?: boolean;
};

const SmallBusinessContractAmountsCard: FC<SmallBusinessContractAmountsCardProps> = ({ project, report, editable }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { displayToast } = useToast();

  const [updateProject, { loading: isLoading }] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [
      {
        query: GET_SINGLE_PROJECT,
        variables: { id: project?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const defaultValues = {
    isActualCalculationsBasedOnTotalContractValue:
      project?.smallBusinessReportSettings?.isActualCalculationsBasedOnTotalContractValue?.toString() ?? '',
    originalContractAmount: project?.originalContractValue?.toString() ?? '',
    primeContractNumber: project?.primeContractNumber ?? '',
    notes: project?.smallBusinessReportSettings?.notes ?? '',
  };

  const { handleSubmit, control, watch, formState, reset } = useForm<UpdateFormInput>({ defaultValues });

  const { isDirty, isValid } = formState;

  const originalContractAmount = watch('originalContractAmount');

  const handleCancel = () => {
    setIsEditing(false);
    reset(defaultValues);
  };

  const handleEditButtonClick = () => {
    setIsEditing(true);
    reset(defaultValues); // Re-initialize based on latest project info
  };

  const onSubmit: SubmitHandler<UpdateFormInput> = (data) => {
    return updateProject({
      variables: {
        input: {
          id: project?.id,
          primeContractNumber: data.primeContractNumber ?? undefined,
          originalContractValue: data.originalContractAmount
            ? parseFloat(data.originalContractAmount.replaceAll(',', ''))
            : undefined,
          smallBusinessReportSettings: {
            id: project?.smallBusinessReportSettings?.id,
            notes: data.notes ?? undefined,
          },
        },
      },
    })
      .then(() => {
        displayToast('The small business report settings were updated successfully', 'success');
        setIsEditing(false);
      })
      .catch((error) => {
        console.error('Create smallBusReportSettings failed: ', error);
        displayToast(
          'Error: Something went wrong while trying submit your changes. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <Card sx={cardStyles}>
      <Grid container direction="row" justifyContent="center">
        {editable && (
          <Grid item xs={12} sx={editButtonContainer}>
            <Grid container justifyContent="flex-end" sx={editButtonContainer}>
              {!isEditing && (
                <IconButton size="small" onClick={handleEditButtonClick}>
                  <EditIcon />
                </IconButton>
              )}
              {isEditing && <Typography sx={editingModeIndicator}>Editing...</Typography>}
            </Grid>
          </Grid>
        )}
        {!isEditing && (
          <>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CardColumn
                icon={<AttachMoneyIcon sx={cardColumnIcon} />}
                title="Original Contract"
                amount={project?.originalContractValue ?? undefined}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CardColumn
                icon={<MonetizationOnIcon sx={cardColumnIcon} />}
                title="Total Current Contract"
                amount={report?.currentContractValue}
                isActive={project?.smallBusinessReportSettings?.isActualCalculationsBasedOnTotalContractValue === true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CardColumn
                icon={<MonetizationOnIcon sx={cardColumnIcon} />}
                title="Subcontracted Total"
                amount={report?.currentSubContractorContractValue}
                isActive={project?.smallBusinessReportSettings?.isActualCalculationsBasedOnTotalContractValue === false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CardColumn title="Prime Contract Number" contractNumber={project?.primeContractNumber ?? undefined} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={12} sx={projectField}>
              <CardColumn title="Reporting Agency" contractNumber={project?.smallBusinessClient?.name ?? undefined} />
            </Grid>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography sx={notesLabel}>Notes</Typography>
                <Card sx={notesCard}>
                  <Typography sx={notesSection}>
                    {project?.smallBusinessReportSettings?.notes
                      ? project?.smallBusinessReportSettings?.notes
                      : 'There are no notes.'}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
        {isEditing && (
          <Grid item xs={12} sx={editingContainer}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={12} sx={sectionStyles}>
                  <Typography color="textSecondary" sx={labelEditing}>
                    Original Contract *
                  </Typography>
                  <section>
                    <Controller
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          allowLeadingZeros={false}
                          isNumericString={true}
                          thousandSeparator={true}
                          customInput={TextField}
                          margin="dense"
                          variant="outlined"
                          fullWidth={true}
                          color="primary"
                          error={!originalContractAmount}
                          required={true}
                          helperText={!originalContractAmount ? 'Required' : ''}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      )}
                      name="originalContractAmount"
                      control={control}
                      rules={{ required: true, min: 0 }}
                    />
                  </section>
                </Grid>
                <Grid item xs={12} sx={sectionStyles}>
                  <Typography color="textSecondary" sx={labelEditing}>
                    Prime Contract Number
                  </Typography>
                  <section>
                    <Controller
                      render={({ field }) => <TextField {...field} fullWidth variant="outlined" margin="dense" />}
                      name="primeContractNumber"
                      control={control}
                    />
                  </section>
                </Grid>
                <Grid item xs={12} sx={sectionStyles}>
                  <Typography color="textSecondary" sx={labelEditing}>
                    Notes
                  </Typography>
                  <section>
                    <Controller
                      render={({ field }) => <TextField {...field} variant="outlined" fullWidth multiline />}
                      name="notes"
                      control={control}
                    />
                  </section>
                </Grid>
                <Grid item xs={12} sx={sectionStyles}>
                  <Grid container alignItems="center" direction="row" spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <StyledButtonSecondary disabled={isLoading} label="Cancel" onClick={handleCancel} />
                    </Grid>
                    <Grid item>
                      <StyledButtonPrimary disabled={!isDirty || !isValid || isLoading} type="submit" label="Submit" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default SmallBusinessContractAmountsCard;
