import { SmallBusinessClassificationName } from 'constants/classificationNames';

import { Theme, Tooltip, Typography } from '@mui/material';
import {
  CSSSelectorObjectOrCssVariables,
  SxProps,
  SystemCssProperties,
  SystemStyleObject,
  Theme as MuiTheme,
  useTheme,
} from '@mui/system';

type Classification = { name: string; abbreviation: string };
type SmallBusinessClassificationNameFormatFunction = { (classification: Classification): string };
const smallBusinessClassificationNameDisplayFormatOptions = ['abbreviation', 'name', 'full'] as const;
type SmallBusinessClassificationNameFormatFunctionMap = {
  [Property in typeof smallBusinessClassificationNameDisplayFormatOptions[number]]: SmallBusinessClassificationNameFormatFunction;
};
const smallBusinessClassificationNameFormatFunctions: SmallBusinessClassificationNameFormatFunctionMap = {
  abbreviation: (classification) => classification.abbreviation,
  name: (classification) => classification.name,
  full: (classification) => `${classification.abbreviation} (${classification.name})`,
};
type SmallBusinessClassificationNameFormatOption = keyof typeof smallBusinessClassificationNameFormatFunctions;

type CustomFormatExtension = { (formattedName: string): string | React.ReactElement };

export type SmallBusinessClassificationNameProps = {
  classification: Classification;
  displayFormat: SmallBusinessClassificationNameFormatOption;
  displayFormatExtension?: CustomFormatExtension;
  tooltipFormat?: SmallBusinessClassificationNameFormatOption;
  tooltipFormatExtension?: CustomFormatExtension;
  noWrap?: boolean;
  typographyClassNames?: SystemStyleObject<Theme> | SystemCssProperties<Theme> | CSSSelectorObjectOrCssVariables<Theme>;
  indent?: boolean;
};

const noFormatExtension: CustomFormatExtension = (formattedName) => formattedName;

const classificationNameLevel1: SxProps<MuiTheme> = (theme: MuiTheme) => ({
  paddingLeft: theme.spacing(0),
  fontWeight: 'bold',
});

const classificationNameLevel2: SxProps<MuiTheme> = (theme: MuiTheme) => ({
  paddingLeft: theme.spacing(2),
  fontWeight: 'normal',
});

const classificationNameLevel3: SxProps<MuiTheme> = (theme: MuiTheme) => ({
  paddingLeft: theme.spacing(4),
  fontStyle: 'italic',
});

const getIndentationStyles = (classificationName: string, theme: MuiTheme) => {
  switch (classificationName) {
    case SmallBusinessClassificationName.LB:
    case SmallBusinessClassificationName.SBT:
      return classificationNameLevel1(theme);
    case SmallBusinessClassificationName.SDVOSB:
      return classificationNameLevel3(theme);
    default:
      return classificationNameLevel2(theme);
  }
};

export const SmallBusinessClassificationDisplayName: React.FC<SmallBusinessClassificationNameProps> = ({
  classification,
  displayFormat,
  displayFormatExtension = noFormatExtension,
  tooltipFormat,
  tooltipFormatExtension = noFormatExtension,
  noWrap = false,
  typographyClassNames,
  indent = false,
}) => {
  let typographyStyles = {};
  const theme = useTheme();
  if (indent) {
    typographyStyles = { ...getIndentationStyles(classification.name, theme) };
  }
  if (typographyClassNames) {
    typographyStyles = { ...typographyClassNames };
  }
  const typography = (
    <Typography sx={{ ...typographyStyles }} width={noWrap ? 'auto' : 'fit-content'}>
      {displayFormatExtension(smallBusinessClassificationNameFormatFunctions[displayFormat](classification))}
    </Typography>
  );

  if (!!tooltipFormat) {
    return (
      <Tooltip
        arrow
        title={tooltipFormatExtension(smallBusinessClassificationNameFormatFunctions[tooltipFormat](classification))}
      >
        {typography}
      </Tooltip>
    );
  } else {
    return typography;
  }
};

export default SmallBusinessClassificationDisplayName;
