export enum FederalSmallBusinessClassificationName {
  LB = 'Large Business',
  SB = 'Small Business',
  SDB = 'Small Disadvantaged Business',
  WOSB = 'Women-Owned Small Business',
  HBCU_MI = 'Historically Black Colleges and Universities and Minority Institutions',
  HUBZONE = 'HUBZONE Small Businesses',
  VOSB = 'Veteran-Owned Small Business',
  SDVOSB = 'Service Disabled Veteran-Owned Small Business',
  ANCS_NOT_SB = 'Alaska Native Corporation and Indian Tribes that has not been certified as Small Business',
  ANCS_NOT_SDB = 'Alaska Native Corporation and Indian Tribes that has not been certified as Small Disadvantaged Business',
  IC = 'Indirect Costs', // Front end display classification only
  SBT = 'Small Business Total',
}

export enum SmallBusinessClassificationName {
  LB = 'Large Business',
  SB = 'Small Business',
  SBE = 'Small Business Enterprise',
  SDB = 'Small Disadvantaged Business ',
  SDBE = 'Small Disadvantaged Business Enterprise',
  SWOBE = 'Small Women-Owned Business Enterprise',
  SHZBE = 'Small HUBZone Business Enterprise',
  SDVBE = 'Small Service Disabled Veteran Business Enterprise',
  SVOBE = 'Small Veteran-Owned Business Enterprise',
  DBE = 'Disadvantaged Business Enterprise',
  MBE = 'Minority-Owned Business Enterprise',
  CBE = 'Certified Business Enterprise',
  LBE = 'Local Business Enterprise',
  LSBE = 'Local Small Business Enterprise',
  LDB = 'Local Developing Business',
  WBE = 'Women Business Enterprise',
  WOSB = 'Women-Owned Business Enterprise',
  EDWOSB = 'Economically Disadvantaged Woman-Owned Small Business',
  SWaM = 'Small, Woman-Owned and Minority-Owned Business',
  HUBZone = 'Historically Underutilized Business Zone',
  'N.C.' = 'North Carolina Historically Underutilized Business',
  Texas = 'Texas Historically Underutilized Business',
  VBE = 'Veteran Business Enterprise',
  VOBE = 'Veteran-Owned Business Enterprise',
  VOSB = 'Veteran-Owned Small Business',
  SDVOSB = 'Service Disabled Veteran-Owned Small Business',
  DVBE = 'Disabled Veteran Business Enterprise',
  ACDBE = 'Airport Concessions Disadvantaged Business Enterprise',
  LGBT = 'Lesbian, Gay, Bisexual or Transgender-Owned Business',
  LGBTQ = 'Lesbian, Gay, Bisexual, Transgender or Queer-Owned Business',
  CBB = 'County-based Business',
  CLB = 'County-located Business',
  SCS = 'Small Contractors and Suppliers',
  PDBE = 'Persons with Disabilities Business Enterprise',
  AABE = 'African American Business Enterprise',
  ABE = 'Asian American Business Enterprise',
  DIBE = 'Disabled Individual Business Enterprise',
  ESBE = 'Emerging Small Business Enterprise',
  HABE = 'Hispanic American Business Enterprise',
  NABE = 'Native American Business Enterprise',
  LGBTBE = 'LGBT Business Enterprise',
  SE = 'Social Enterprise',
  SLB = 'Small Local Business',
  MWBE = 'Minority Women Business Enterprise',
  SMBE = 'Small Minority Business Enterprise',
  'SBA 8a' = 'Small Business Administration 8a',
  'SB (Micro)' = 'Small Business (Micro)',
  'SB - PW' = 'Small Business - PW',
  'SBE (Proprietary)' = 'Small Business Enterprise (Proprietary)',
  SBT = 'Small Business Total',
}
