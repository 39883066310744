import AddFile from 'components/shared/AddFile';
import StyledNotice, { NoticeTypeOption } from 'components/shared/Notice';
import { SmallBusinessClassificationName } from 'constants/classificationNames';
import { LOWER_TIER_FILE_SUMMARY_DICTIONARY } from 'constants/lowerTierFileSummary';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { LowerTierFileSummary, LowerTierFileSummaryDictionary } from 'types';
import {
  GetSmallBusinessClassificationsQuery,
  SmallBusinessAgency,
  SmallBusinessClassification,
} from 'types/generated/graphql';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {
  Autocomplete,
  Chip,
  Divider,
  Grid,
  IconButton,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

const containerStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const formStyles: SxProps<Theme> = {
  width: '100%',
};

const infoLabel: SxProps<Theme> = {
  lineHeight: '1.5',
  fontSize: '0.75rem',
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.6)',
  marginTop: '10px',
};

const errorMessageStyle: SxProps<Theme> = {
  color: '#cd0000',
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.75rem',
};

const itemStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingTop: theme.spacing(1),
});

type PickedSmallBusinessClassification = Pick<
  SmallBusinessClassification,
  'id' | 'name' | 'abbreviation' | 'sortOrder'
>;

type SmallBusinessClassificationSelection = GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'];

type LowerTierFileSummaryDialogProps = {
  control: Control<any>;
  lowerTierFileSummary: LowerTierFileSummary;
  handleLowerTierFileSummaryChange: (a: any, b: number, c: keyof LowerTierFileSummary) => void;
  lowerTierSummaryIndex: number;
  smallBusinessAgencyList?: SmallBusinessAgency[] | undefined;
  allSmallBusinessClassifications: SmallBusinessClassificationSelection;
  handleRemoveLowerTierFileSummary: (x: number) => void;
  handleLowerTierFileSummaryInputFields: (x: LowerTierFileSummary[], y: File[]) => void;
  isLowerTierDialogOpen: boolean;
  isAddingLowerTierParticipation: boolean;
};

type LowerTierFileSummaryInputFieldProps = {
  control: Control<any>;
  lowerTierFileSummaryDictionary: LowerTierFileSummaryDictionary;
  lowerTierFileSummary: LowerTierFileSummary;
  handleLowerTierFileSummaryChange: (a: any, b: number, c: keyof LowerTierFileSummary) => void;
  lowerTierSummaryIndex: number;
  smallBusinessAgencyList: SmallBusinessAgency[] | undefined;
  allSmallBusinessClassifications: SmallBusinessClassificationSelection;
  selectedAgency: string | undefined | null;
  setSelectedAgency: (x: string | undefined | null) => void;
  selectedClassifications: PickedSmallBusinessClassification[] | null | undefined;
  setSelectedClassifications: (x: PickedSmallBusinessClassification[] | undefined | null) => void;
  addedFiles: File[];
  setAddedFiles: (x: File[]) => void;
  isAddingLowerTierParticipation: boolean;
  handleLowerTierFileSummaryInputFields: (x: LowerTierFileSummary[], y: File[]) => void;
};

const LowerTierFileSummaryInputFields: FC<LowerTierFileSummaryInputFieldProps> = ({
  control,
  lowerTierFileSummaryDictionary,
  lowerTierFileSummary,
  handleLowerTierFileSummaryChange,
  lowerTierSummaryIndex,
  smallBusinessAgencyList,
  allSmallBusinessClassifications,
  selectedAgency,
  setSelectedAgency,
  selectedClassifications,
  setSelectedClassifications,
  addedFiles,
  setAddedFiles,
  isAddingLowerTierParticipation,
  handleLowerTierFileSummaryInputFields,
}) => {
  const existingClassifications = useMemo(() => {
    return (
      (lowerTierFileSummary.Classifications?.map((classificationId) => {
        return allSmallBusinessClassifications?.find((classification) => classification?.id === classificationId);
      }) as PickedSmallBusinessClassification[]) ?? []
    );
  }, [lowerTierFileSummary.Classifications, allSmallBusinessClassifications]);

  const existingAgencyId = smallBusinessAgencyList?.find((agency) => agency?.id === lowerTierFileSummary.Agency)?.id;

  // Set initial values for agency, classification, and handles file field change
  useEffect(() => {
    if (lowerTierFileSummaryDictionary.key === 'Agency' && !isAddingLowerTierParticipation) {
      handleLowerTierFileSummaryChange(existingAgencyId, lowerTierSummaryIndex, lowerTierFileSummaryDictionary.key);
      setSelectedAgency(existingAgencyId);
    } else if (lowerTierFileSummaryDictionary.key === 'Classifications' && !isAddingLowerTierParticipation) {
      setSelectedClassifications(existingClassifications);
    } else if (lowerTierFileSummaryDictionary.key === 'Documentation') {
      handleLowerTierFileSummaryChange(
        isAddingLowerTierParticipation ? addedFiles : lowerTierFileSummary.Documentation,
        lowerTierSummaryIndex,
        lowerTierFileSummaryDictionary.key,
      );
    } else {
      return;
    }
  }, [
    addedFiles,
    existingAgencyId,
    existingClassifications,
    handleLowerTierFileSummaryChange,
    isAddingLowerTierParticipation,
    lowerTierFileSummaryDictionary.key,
    lowerTierFileSummary.Documentation,
    lowerTierSummaryIndex,
    setSelectedAgency,
    setSelectedClassifications,
  ]);

  // Pass input values changes to EditLowerTierDialog when editing
  useEffect(() => {
    return handleLowerTierFileSummaryInputFields(
      [
        {
          id: lowerTierFileSummary.id,
          Agency: lowerTierFileSummary.Agency,
          Classifications: lowerTierFileSummary.Classifications,
          Documentation: lowerTierFileSummary.Documentation,
        },
      ],
      addedFiles ?? null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgency, selectedClassifications, addedFiles]);

  const findClassificationsBySelectedAgency = smallBusinessAgencyList?.find(
    (agency) => agency?.id === selectedAgency,
  )?.smallBusinessAgencyClassifications;

  const agencyAcceptedClassifications = findClassificationsBySelectedAgency
    ? [...findClassificationsBySelectedAgency].sort((a, b) =>
        a.smallBusinessClassification.name.localeCompare(b.smallBusinessClassification.name),
      )
    : [];

  const agencyAcceptedClassificationListById =
    agencyAcceptedClassifications?.map((classification) => classification.smallBusinessClassification) ?? [];

  const smallBusinessClassificationIdByName = allSmallBusinessClassifications?.reduce<{
    [name: string]: string;
  }>((accumulator, classification) => {
    accumulator[classification?.name] = classification?.id;
    return accumulator;
  }, {});

  const lbId =
    smallBusinessClassificationIdByName && smallBusinessClassificationIdByName[SmallBusinessClassificationName.LB];

  const largeBusinessClassification = allSmallBusinessClassifications?.find(
    (classification) => classification.id === lbId,
  ) as PickedSmallBusinessClassification;

  const smallBusinessAgenciesById = smallBusinessAgencyList?.reduce<{
    [id: string]: SmallBusinessAgency;
  }>((accumulator, agency) => {
    accumulator[agency?.id] = agency;
    return accumulator;
  }, {});

  const handleSelectClassification = (_: any, classifications: PickedSmallBusinessClassification[] | null) => {
    handleLowerTierFileSummaryChange(classifications, lowerTierSummaryIndex, lowerTierFileSummaryDictionary.key);
    setSelectedClassifications(classifications);
  };

  const handleDisabledClassifications = useCallback(
    (option) => {
      const selectedClassificationId = selectedClassifications?.map((selectedClassification) => {
        return selectedClassification?.id;
      });

      if (selectedClassifications?.length) {
        if (selectedClassificationId?.includes(lbId as string)) {
          return option?.id !== lbId;
        } else {
          return option?.id === lbId;
        }
      }
      return option;
    },
    [lbId, selectedClassifications],
  );

  const handleDeleteClassifications = (classificationToDelete: string) => {
    const classificationsToKeep = selectedClassifications?.filter(
      (classification) => classification.id !== classificationToDelete,
    );
    handleLowerTierFileSummaryChange(classificationsToKeep, lowerTierSummaryIndex, lowerTierFileSummaryDictionary.key);
    setSelectedClassifications(classificationsToKeep);
  };

  return (
    <Grid item>
      <Controller
        name={lowerTierFileSummaryDictionary.name}
        control={control}
        render={() => (
          <>
            <Typography sx={infoLabel}>{lowerTierFileSummaryDictionary.label}</Typography>
            {lowerTierFileSummaryDictionary.key === 'Agency' ? (
              <>
                <Autocomplete
                  value={selectedAgency ?? ''}
                  onChange={(_: any, agencyId: string | null) => {
                    handleLowerTierFileSummaryChange(
                      agencyId,
                      lowerTierSummaryIndex,
                      lowerTierFileSummaryDictionary.key,
                    );
                    setSelectedAgency(agencyId);
                    handleLowerTierFileSummaryChange([], lowerTierSummaryIndex, 'Classifications');
                    setSelectedClassifications([]);
                  }}
                  id={`combo-box-agencies-${lowerTierFileSummary.id ?? lowerTierFileSummary.transactionKey}`}
                  options={smallBusinessAgencyList?.map((agency) => agency?.id) ?? []}
                  getOptionLabel={(option: string) => {
                    const agency = smallBusinessAgenciesById && smallBusinessAgenciesById[option];
                    return `${agency?.name ?? ''}`;
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
                  size="small"
                  filterSelectedOptions
                />
              </>
            ) : lowerTierFileSummaryDictionary.key === 'Classifications' ? (
              <>
                <Autocomplete
                  value={selectedClassifications ?? []}
                  id={`combo-box-classifications-${lowerTierFileSummary.id ?? lowerTierFileSummary.transactionKey}`}
                  multiple
                  options={
                    agencyAcceptedClassificationListById.length > 0
                      ? agencyAcceptedClassificationListById
                      : [largeBusinessClassification] ?? []
                  }
                  isOptionEqualToValue={(
                    option: PickedSmallBusinessClassification,
                    value: PickedSmallBusinessClassification,
                  ) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option: { abbreviation: string; name: string }) =>
                    `${option?.abbreviation} (${option?.name})`
                  }
                  renderInput={(params: any) => <TextField {...params} />}
                  onChange={(_: any, classifications: PickedSmallBusinessClassification[]) => {
                    handleSelectClassification(_, classifications);
                  }}
                  size="small"
                  getOptionDisabled={handleDisabledClassifications}
                  filterSelectedOptions
                  renderTags={(value: any[], getTagProps: any) => {
                    const classifications = value
                      .map((classification: SmallBusinessClassification) => {
                        return classification;
                      })
                      .sort((a, b) => a.name.localeCompare(b.name));

                    return classifications.map((classification, index) => {
                      return (
                        <Tooltip arrow title={`${classification?.name}`}>
                          <div key={index}>
                            <Chip
                              variant="outlined"
                              label={classification?.abbreviation}
                              {...getTagProps({ index })}
                              onDelete={() => handleDeleteClassifications(classification.id)}
                            />
                          </div>
                        </Tooltip>
                      );
                    });
                  }}
                />
                <Grid sx={errorMessageStyle}>
                  {(selectedClassifications && selectedClassifications.length === 0) ||
                  existingClassifications.length === 0
                    ? 'Classification(s) are required'
                    : null}
                </Grid>
                <>
                  <Grid item xs={12} sx={itemStyles}>
                    <Divider variant="middle" light={true} />
                  </Grid>
                  <StyledNotice
                    type={NoticeTypeOption.Notice}
                    message={
                      <Typography>
                        If an agency or classification isn’t found, please contact your Supplier Diversity
                        representative to have the data added.
                      </Typography>
                    }
                  />
                </>
              </>
            ) : (
              <>
                <Grid item>
                  <AddFile
                    id={`add-file-${lowerTierFileSummary.id ?? lowerTierFileSummary.transactionKey}`}
                    setAddedFiles={setAddedFiles}
                    addedFiles={addedFiles}
                    existingFiles={lowerTierFileSummary.Documentation ?? []}
                    maxNumFiles={1}
                    isEditable={lowerTierFileSummary.Documentation.length === 0}
                  />
                  <Grid sx={errorMessageStyle}>
                    {(isAddingLowerTierParticipation && addedFiles.length === 0) ||
                    (!isAddingLowerTierParticipation &&
                      lowerTierFileSummary.Documentation.length === 0 &&
                      addedFiles.length === 0)
                      ? 'Compliance Documentation is required'
                      : null}
                  </Grid>
                </Grid>
                {(isAddingLowerTierParticipation ||
                  (!isAddingLowerTierParticipation && lowerTierFileSummary.Documentation.length === 0)) && (
                  <>
                    <Grid item xs={12} sx={itemStyles}>
                      <Divider variant="middle" light={true} />
                    </Grid>
                    <StyledNotice
                      type={NoticeTypeOption.Notice}
                      message={
                        <Typography>
                          Please ensure compliance documentation is effective as of the contract effective date.
                        </Typography>
                      }
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      />
    </Grid>
  );
};

const LowerTierFileSummaryDialog: React.FC<LowerTierFileSummaryDialogProps> = ({
  control,
  lowerTierFileSummary,
  handleLowerTierFileSummaryChange,
  lowerTierSummaryIndex,
  smallBusinessAgencyList,
  handleRemoveLowerTierFileSummary,
  handleLowerTierFileSummaryInputFields,
  isLowerTierDialogOpen,
  isAddingLowerTierParticipation,
  allSmallBusinessClassifications,
}) => {
  const [addedFiles, setAddedFiles] = useState<File[]>([]);
  const [selectedAgency, setSelectedAgency] = useState<string | null | undefined>('');
  const [selectedClassifications, setSelectedClassifications] = useState<
    PickedSmallBusinessClassification[] | null | undefined
  >([]);

  // Set addedFile to empty array if cancelling changes to Lower Tier Participation
  useEffect(() => {
    return !isLowerTierDialogOpen ? setAddedFiles([]) : undefined;
  }, [isLowerTierDialogOpen]);

  return (
    <Grid container sx={containerStyles} justifyContent={'space-between'}>
      <Grid sx={formStyles}>
        {LOWER_TIER_FILE_SUMMARY_DICTIONARY.map(
          (lowerTierFileSummaryDictionary: LowerTierFileSummaryDictionary, index: number) => {
            return (
              <>
                <LowerTierFileSummaryInputFields
                  key={index}
                  lowerTierSummaryIndex={lowerTierSummaryIndex}
                  control={control}
                  lowerTierFileSummaryDictionary={lowerTierFileSummaryDictionary}
                  lowerTierFileSummary={lowerTierFileSummary}
                  handleLowerTierFileSummaryChange={handleLowerTierFileSummaryChange}
                  smallBusinessAgencyList={smallBusinessAgencyList}
                  allSmallBusinessClassifications={allSmallBusinessClassifications}
                  selectedAgency={selectedAgency}
                  setSelectedAgency={setSelectedAgency}
                  selectedClassifications={selectedClassifications}
                  setSelectedClassifications={setSelectedClassifications}
                  addedFiles={addedFiles}
                  setAddedFiles={setAddedFiles}
                  isAddingLowerTierParticipation={isAddingLowerTierParticipation}
                  handleLowerTierFileSummaryInputFields={handleLowerTierFileSummaryInputFields}
                />
              </>
            );
          },
        )}
        {((isAddingLowerTierParticipation && lowerTierSummaryIndex > 0) || !isAddingLowerTierParticipation) && (
          <Grid item>
            <Tooltip title={'Remove Lower Tier File Summary'}>
              <IconButton
                onClick={() => {
                  handleRemoveLowerTierFileSummary(lowerTierSummaryIndex);
                }}
              >
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default LowerTierFileSummaryDialog;
