import { gql } from '@apollo/client';

export const GET_PAGINATED_PROJECTS = gql`
  query GetPaginatedProjects(
    $skip: Int!
    $first: Int!
    $order: ProjectListOrderInput
    $filter: ProjectListFilterInput
    $search: String
  ) {
    projectListResult(skip: $skip, first: $first, order: $order, filter: $filter, search: $search) {
      count
      items {
        id
        number
        name
        district
        city
        state
        smallBusinessRequirement
        isActive
        smallBusinessClientId
        smallBusinessClient {
          id
          name
          abbreviation
        }
      }
    }
  }
`;

export const GET_SINGLE_PROJECT = gql`
  query GetSingleProject($id: ID!) {
    project(id: $id) {
      id
      number
      name
      notes
      address
      district
      city
      state
      zip
      isActive
      originalContractValue
      smallBusinessRequirement
      primeContractNumber
      smallBusinessClient {
        id
        name
        abbreviation
        isFederal
        smallBusinessClientAgencies {
          id
          smallBusinessAgency {
            id
            name
            abbreviation
            isActive
            isFederal
            smallBusinessAgencyClassifications {
              smallBusinessClassification {
                id
                name
                abbreviation
                isFederal
              }
            }
          }
          smallBusinessClientClassifications {
            smallBusinessClassification {
              id
              name
              abbreviation
              isFederal
            }
          }
        }
      }
      smallBusinessReportSettings {
        id
        isActualCalculationsBasedOnTotalContractValue
        notes
      }
      admins {
        id
        microsoftAdId
        upn
        name
        firstName
        lastName
        email
        jobTitle
      }
    }
  }
`;

export const GET_PROJECTS_CONTRACTED_AFTER = gql`
  query GetProjectsContractedAfter($companyId: String!, $date: String!) {
    getProjectsContractedAfter(companyId: $companyId, date: $date) {
      number
      name
    }
  }
`;

export const GET_PROJECTS_IN_USE = gql`
  query GetProjectsInUse($smallBusinessClientId: String, $smallBusinessClassificationId: String) {
    getProjectsInUse(
      smallBusinessClientId: $smallBusinessClientId
      smallBusinessClassificationId: $smallBusinessClassificationId
    ) {
      id
      number
      name
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($input: ProjectUpdateInput!) {
    updateProject(input: $input) {
      id
      number
      name
      notes
      address
      district
      city
      state
      zip
      isActive
      originalContractValue
      smallBusinessRequirement
      primeContractNumber
      smallBusinessClient {
        id
      }
      smallBusinessReportSettings {
        id
        isActualCalculationsBasedOnTotalContractValue
        classifications {
          id
          goalPercent
        }
        notes
      }
    }
  }
`;

export const SET_ADMIN_STATUS_ON_PROJECT = gql`
  mutation setAdminStatusOnProject($projectId: ID!, $microsoftAdId: String!, $isAdmin: Boolean!) {
    setAdminStatusOnProject(projectId: $projectId, microsoftAdId: $microsoftAdId, isAdmin: $isAdmin) {
      id
      number
      name
      notes
      address
      district
      city
      state
      zip
      isActive
      originalContractValue
      smallBusinessRequirement
      primeContractNumber
      smallBusinessClient {
        id
        name
        abbreviation
      }
      smallBusinessReportSettings {
        id
        isActualCalculationsBasedOnTotalContractValue
        notes
      }
    }
  }
`;
