import MercuryFileDisplayImage from 'components/MercuryFileDisplayImage';
import { MAX_FILE_SIZE_IN_BYTES } from 'constants/maxFileSize';
import { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadedFile } from 'types/generated/graphql';
import { isImageFile, isPdfFile } from 'utils/general';

import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Grid, IconButton, SxProps, Theme, Tooltip, Typography } from '@mui/material';

const desktopInstructionsStyle: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: { display: 'none' },
});

const dropzoneStyle: SxProps<Theme> = (theme: Theme) => ({
  width: '100%',
  height: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  outline: 'none',
  border: '2px dashed' + theme.palette.secondary.dark,
  borderRadius: 4,
  '&:hover': { cursor: 'pointer' },
});

const containerStyle: SxProps<Theme> = (theme: Theme) => ({
  width: 'inherit',
  height: 100,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.dark,
  outline: 'none',
  transition: 'border .24s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const fileUploadErrorContainerStyle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error.main,
});

const fileUploadErrorDropzoneStyle: SxProps<Theme> = (theme: Theme) => ({
  color: theme.palette.error.main,
  border: '2px dashed' + theme.palette.error.main,
});

const uploadedFileContainerStyle: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  borderRadius: 4,
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('xs')]: {
    justifyContent: 'space-between',
  },
});

const pdfIconStyle: SxProps<Theme> = (theme: Theme) => ({
  marginRight: theme.spacing(2),
});

type AddFileProps = {
  setAddedFiles: (x: any) => void;
  addedFiles: File[];
  existingFiles: UploadedFile[];
  maxNumFiles: number;
  isEditable: boolean;
  id: string;
};

const generateFilePreviews = (files: File[]) => {
  const filesWithPreviews = files.map((file) => {
    if (file.type && file.type !== 'application/pdf' && file.type !== 'image/png') {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      return file;
    }
    return files;
  });
  return filesWithPreviews;
};

const AddFile: FC<AddFileProps> = ({
  setAddedFiles,
  addedFiles,
  existingFiles,
  maxNumFiles = 1,
  isEditable = true,
  id,
}) => {
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);
  const addedFilesWithPreviews = addedFiles.length ? generateFilePreviews(addedFiles) : [];
  const allFiles: any[] = existingFiles.length > 0 ? existingFiles.flat() : addedFilesWithPreviews.flat();

  const isDropzoneDisabled = maxNumFiles !== 0 && addedFiles.length === maxNumFiles;

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png'],
      'application/pdf': ['.pdf'],
    },
    multiple: false,
    maxFiles: maxNumFiles,
    disabled: isDropzoneDisabled,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    onDropAccepted: (acceptedFile) => {
      setFileUploadError(null);
      validateFiles(allFiles, acceptedFile[0]);
    },
    onDropRejected: (rejectedFile) => {
      const errorMessage = rejectedFile[0].errors[0].code;
      if (errorMessage === 'file-too-large') {
        setFileUploadError(
          `The file you've selected is too large. Please select a file that is under ${
            MAX_FILE_SIZE_IN_BYTES / 1000000
          }.`,
        );
        return;
      }
      setFileUploadError('The file you selected cannot be uploaded. Please select a different file.');
    },
  });

  // delete photo from UI that has not been sent to API yet
  const deleteLocalFile = (fileName: string) => {
    const addedFilesExceptThisOne = addedFiles.filter((file: File) => file.name !== fileName);
    setAddedFiles(addedFilesExceptThisOne);
    return;
  };

  const formatFile = (file: any) => {
    const fileWithPreview = generateFilePreviews([file]);
    if (file.path) {
      Object.assign(fileWithPreview[0], {
        path: file.name,
      });
    }
    return fileWithPreview[0];
  };

  const validateFiles = (filesArray: File[], fileToCheck: File, counter = 1) => {
    const getNewFileName = (oldFileName: string) => {
      return (
        oldFileName.substring(0, oldFileName.lastIndexOf('.')) +
        ' (' +
        counter +
        ')' +
        oldFileName.substring(oldFileName.lastIndexOf('.'))
      );
    };

    const updateFileNameNumber = (oldFileName: string) => {
      return (
        oldFileName.substring(0, oldFileName.lastIndexOf('(')) +
        ' (' +
        counter +
        ')' +
        oldFileName.substring(oldFileName.lastIndexOf('.'))
      );
    };

    // if name matches, increment number and call fn again
    // if no matches exist, then create the next name iteration and return file object
    if (filesArray.some((file) => file.name === fileToCheck.name)) {
      let newFileName;
      if (counter > 1) {
        newFileName = updateFileNameNumber(fileToCheck.name);
      } else {
        newFileName = getNewFileName(fileToCheck.name);
      }

      let blob = fileToCheck.slice(0, fileToCheck.size, fileToCheck.type);
      const newFile = new File([blob], newFileName, {
        type: fileToCheck.type,
      });
      validateFiles(filesArray, newFile, counter + 1);
    } else {
      const formattedFile = formatFile(fileToCheck);
      const allFormattedFiles = [...addedFiles, formattedFile];
      setAddedFiles(allFormattedFiles.flat());
      return;
    }
  };

  const RotateImage = (direction: string, file: any) => {
    if (direction !== 'left' && direction !== 'right') {
      return;
    }

    const blobFromFile = file.slice(0, file.size, file.__typename);
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    //Convert Blob to base 64 image
    reader.readAsDataURL(blobFromFile);
    reader.onloadend = () => {
      const base64data = reader.result;
      image.src = base64data as string;
      image.onload = () => {
        rotateImageNinetyDegrees(direction, image, file, canvas);
        canvas.toBlob((blob) => {
          createAndAddNewFileFromBlob(blob, file);
        });
      };
    };
  };

  const rotateImageNinetyDegrees = (direction: string, image: HTMLImageElement, file: File, canvas: any) => {
    if (direction !== 'left' && direction !== 'right') {
      return;
    }

    const maxDim = Math.max(image.height, image.width);
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    ctx.setTransform(1, 0, 0, 1, maxDim / 2, maxDim / 2);
    ctx.rotate((direction === 'left' ? -90 : 90) * (Math.PI / 180));
    ctx.drawImage(image, -maxDim / 2, -maxDim / 2);
    canvas.toDataURL(`image/${file.type}`);
  };

  const createAndAddNewFileFromBlob = (blob: any, file: File) => {
    const newFile = new File([blob], file.name, {
      type: file.type,
    });
    Object.assign(newFile, {
      preview: URL.createObjectURL(newFile),
    });
    updateAddFilesAndAllFilesWithNewFile(newFile);
  };

  const updateAddFilesAndAllFilesWithNewFile = (newFile: File) => {
    const formattedFile = formatFile(newFile);
    const addedFilesExceptThisOne = addedFiles.filter((file: File) => file.name !== newFile.name);
    setAddedFiles([...addedFilesExceptThisOne, formattedFile]);
  };

  const thumbs = allFiles.map((file: any, index: number) => {
    const canRotateImage = file.type && file.type.indexOf('image/') === 0 && file.type !== 'image/gif';

    return (
      <Grid item key={`${id}-${index}`} xs={12}>
        <Grid container direction="row" alignItems="center" sx={uploadedFileContainerStyle}>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              {isImageFile(file) && (
                <Grid item xs={12} sx={pdfIconStyle}>
                  <MercuryFileDisplayImage file={file} isFullSizePreview={false} />
                </Grid>
              )}
              <Grid item>
                {isPdfFile(file) && !file.isMalware && <PictureAsPdf sx={pdfIconStyle} />}
                {isPdfFile(file) && file.isMalware && <ErrorIcon sx={pdfIconStyle} />}
              </Grid>
              <Grid item>
                <Tooltip title={file.name}>
                  <Typography>{file.name}</Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {canRotateImage && (
              <>
                <IconButton size="small" onClick={() => RotateImage('left', file)}>
                  <RotateLeftIcon />
                </IconButton>
                <IconButton size="small" onClick={() => RotateImage('right', file)}>
                  <RotateRightIcon />
                </IconButton>
              </>
            )}
            {!file.id && (
              <IconButton size="small" onClick={() => deleteLocalFile(file.name)}>
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      {isEditable && (
        <Grid sx={fileUploadError ? fileUploadErrorContainerStyle : containerStyle}>
          <Grid sx={fileUploadError ? fileUploadErrorDropzoneStyle : dropzoneStyle} {...getRootProps()}>
            <input {...getInputProps()} />
            {fileUploadError && <p>{fileUploadError}</p>}
            {!fileUploadError && (
              <>
                <Typography color="textSecondary" sx={desktopInstructionsStyle}>
                  {!isDropzoneDisabled
                    ? 'Drag and drop an image here or click to select a file'
                    : 'Maximum files uploaded'}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      )}
      {thumbs.length > 0 && <Grid container>{thumbs}</Grid>}
    </>
  );
};

export default AddFile;
