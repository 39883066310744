import MercuryFileDisplayImage from 'components/MercuryFileDisplayImage';
import PdfViewer from 'components/PdfViewer';
import RelatedContractsSummary from 'components/RelatedContractsSummary';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import { GET_SINGLE_COMPANY } from 'graphql/companies';
import { DELETE_COMPANY_FILE, UPDATE_COMPANY_FILE } from 'graphql/companyFile';
import useRoles from 'hooks/useRoles';
import useToast from 'hooks/useToast';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { GetSingleCompanyQuery, useCurrentAdUserQuery } from 'types/generated/graphql';
import { formatDate, isImageFile, isPdfFile } from 'utils/general';

import { useApolloClient, useMutation } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Card,
  Grid,
  IconButton,
  InputLabel,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

const container: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const form: SxProps<Theme> = {
  width: '100%',
};

const inputLabel: SxProps<Theme> = (theme: Theme) => ({
  marginTop: theme.spacing(-1),
  marginBottom: theme.spacing(-0.5),
});

const notesCard: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: '#f2f2f2',
  boxShadow: 'none',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
});

const notesSection: SxProps<Theme> = {
  whiteSpace: 'pre-wrap',
};

const fieldLabel: SxProps<Theme> = {
  fontSize: '0.825rem',
};

const editingLabel: SxProps<Theme> = (theme: Theme) => ({
  fontSize: '0.825rem',
  color: theme.palette.error.main,
});

const section: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: theme.spacing(2),
});

const uploadedFileContainer: SxProps<Theme> = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
});

const pdfIcon: SxProps<Theme> = (theme: Theme) => ({
  fontSize: '3rem',
  marginRight: theme.spacing(2),
});

type CompanyFileSelection = GetSingleCompanyQuery['company']['files'][number];

type EditCompanyDocumentDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  selectedFile?: CompanyFileSelection;
  setSelectedFile: Dispatch<SetStateAction<CompanyFileSelection | undefined>>;
  companyId: string;
};

type CompanyDocumentInput = {
  title: string;
  notes: string;
};

const ViewCompanyDocumentDialog: React.FC<EditCompanyDocumentDialogProps & Record<string, any>> = ({
  isOpen,
  setIsOpen,
  selectedFile,
  setSelectedFile,
  companyId,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { displayToast } = useToast();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isViewingPdf, setIsViewingPdf] = useState(false);
  const { isEnterpriseAdmin } = useRoles();
  const apolloClient = useApolloClient();

  const { data: currentAdUserData } = useCurrentAdUserQuery({
    fetchPolicy: 'no-cache',
  });
  const canManageCompanyDocuments = isEnterpriseAdmin || !!currentAdUserData?.currentAdUser?.projects?.length;

  const [updateCompanyFile, { loading: isLoading }] = useMutation(UPDATE_COMPANY_FILE, {
    refetchQueries: [
      {
        query: GET_SINGLE_COMPANY,
        variables: { id: companyId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [deleteCompanyFile, { loading: deleteIsLoading }] = useMutation(DELETE_COMPANY_FILE, {
    refetchQueries: [
      {
        query: GET_SINGLE_COMPANY,
        variables: { id: companyId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const defaultValues = {
    title: '',
    notes: '',
  };

  // Currently, we assume that a file only has one start date associated with it.
  const startIsoDate = selectedFile?.smallBusinessClassificationCoverages?.[0]?.startDate;

  const { handleSubmit, control, reset, formState } = useForm<CompanyDocumentInput>({ defaultValues });

  useEffect(() => {
    reset({ title: selectedFile?.title ?? '', notes: selectedFile?.notes ?? '' });
  }, [selectedFile, reset]);

  const { isValid } = formState;

  const handleClose = () => {
    setIsOpen(false);
    reset(defaultValues);
    setIsEditing(false);
    setSelectedFile(undefined);
    setIsDeleting(false);
    setIsViewingPdf(false);
  };

  const onSubmit: SubmitHandler<CompanyDocumentInput> = (data) => {
    return updateCompanyFile({
      variables: {
        input: {
          id: selectedFile?.id,
          title: data.title,
          notes: data.notes,
        },
      },
    })
      .then(() => {
        displayToast('The file was updated successfully', 'success');
        handleClose();
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying to update your file. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  const handleDelete = () => {
    deleteCompanyFile({
      variables: {
        id: selectedFile?.id,
      },
    })
      .then(() => {
        displayToast('The file was deleted successfully', 'success');
        handleClose();
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying to delete your file. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  const handleCancel = () => {
    reset({ title: selectedFile?.title ?? '', notes: selectedFile?.notes ?? '' });
    setIsEditing(false);
  };

  return (
    <StyledDialog
      title={selectedFile?.title ?? ''}
      isLoading={isLoading || deleteIsLoading}
      content={
        <Grid container sx={container}>
          {!isEditing && !isDeleting && !isViewingPdf && (
            <Fragment>
              <Grid item xs={12}>
                <Typography>{`Uploaded by ${selectedFile?.uploadedBy?.name} on ${formatDate(
                  selectedFile?.uploaded,
                )}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="flex-end">
                  <Grid item>
                    <Typography color="textSecondary" sx={fieldLabel}>
                      Notes
                    </Typography>
                  </Grid>
                  {canManageCompanyDocuments && (
                    <Grid item>
                      <IconButton size="small" onClick={() => setIsEditing(true)}>
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Card sx={notesCard}>
                      <Typography sx={notesSection}>{selectedFile?.notes ? selectedFile?.notes : ''}</Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    {selectedFile?.file && isImageFile(selectedFile.file) && (
                      <MercuryFileDisplayImage file={selectedFile.file} />
                    )}
                    {selectedFile?.file && isPdfFile(selectedFile.file) && (
                      <>
                        <Typography color="textSecondary" sx={fieldLabel}>
                          Compliance Documentation
                        </Typography>
                        <Grid container justifyContent="space-between" alignItems="center" sx={uploadedFileContainer}>
                          <Grid item>
                            <Grid container alignItems="center">
                              <Grid item>
                                <PdfIcon color="disabled" sx={pdfIcon} />
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle2">{selectedFile.file?.name}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <StyledButtonPrimary label="View" onClick={() => setIsViewingPdf(true)} />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          )}
          {isEditing && (
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Typography sx={editingLabel}>Editing...</Typography>
              </Grid>
              <Grid sx={form}>
                <form>
                  <Grid item xs={12} sx={section}>
                    <Controller
                      render={({ field }) => (
                        <>
                          <InputLabel sx={inputLabel} shrink>
                            Title
                          </InputLabel>
                          <TextField {...field} variant="outlined" margin="dense" fullWidth multiline />
                        </>
                      )}
                      name="title"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sx={section}>
                    <Controller
                      render={({ field }) => (
                        <>
                          <InputLabel sx={inputLabel} shrink>
                            Notes
                          </InputLabel>
                          <TextField {...field} variant="outlined" margin="dense" fullWidth multiline />
                        </>
                      )}
                      name="notes"
                      control={control}
                    />
                  </Grid>
                </form>
              </Grid>
            </Grid>
          )}
          {isDeleting && (
            <Grid item xs={12}>
              <Typography>Are you sure you want to delete this?</Typography>
            </Grid>
          )}
          {isDeleting && startIsoDate && (
            <Grid item xs={12}>
              <RelatedContractsSummary apolloClient={apolloClient} companyId={companyId} afterIsoDate={startIsoDate} />
            </Grid>
          )}
          {isViewingPdf && selectedFile?.file && isPdfFile(selectedFile?.file) && (
            <Grid container justifyContent={'center'}>
              <PdfViewer file={selectedFile.file} />
            </Grid>
          )}
        </Grid>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              {!isEditing && !isDeleting && (
                <Grid item>
                  <StyledButtonSecondary disabled={isLoading} label={'close'} onClick={handleClose} />
                </Grid>
              )}
              {canManageCompanyDocuments &&
                !isEditing &&
                !isDeleting &&
                !isViewingPdf &&
                selectedFile?.file?.cachedUrl && (
                  <Grid item>
                    <a href={selectedFile?.file?.cachedUrl} download={selectedFile?.file?.name}>
                      <StyledButtonSecondary label={'download'} disabled={isLoading || isDeleting} />
                    </a>
                  </Grid>
                )}
              {canManageCompanyDocuments && !isEditing && !isDeleting && !isViewingPdf && (
                <Grid item>
                  <StyledButtonPrimary label={'delete'} onClick={() => setIsDeleting(true)} disabled={isLoading} />
                </Grid>
              )}
              {isEditing && (
                <Grid item>
                  <StyledButtonSecondary disabled={isLoading} label={'cancel'} onClick={handleCancel} />
                </Grid>
              )}
              {isEditing && !isDeleting && (
                <Grid item>
                  <StyledButtonPrimary
                    label={'submit'}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isValid || isLoading}
                  />
                </Grid>
              )}
              {isDeleting && (
                <>
                  <Grid item>
                    <StyledButtonSecondary
                      disabled={deleteIsLoading}
                      label={'back'}
                      onClick={() => setIsDeleting(false)}
                    />
                  </Grid>
                  <Grid item>
                    <StyledButtonPrimary label={'delete'} onClick={handleDelete} disabled={deleteIsLoading} />
                  </Grid>
                </>
              )}
              {isViewingPdf && (
                <Grid item>
                  <StyledButtonPrimary label={'back'} onClick={() => setIsViewingPdf(false)} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

export default ViewCompanyDocumentDialog;
