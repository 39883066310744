import ActiveOrInactiveStatusContent from 'components/shared/ActiveOrInactiveStatusContent';
import BackToTopButton from 'components/shared/BackToTopButton';
import CSVUploadDialog from 'components/shared/CSVUploadDialog';
import ErrorNotice from 'components/shared/ErrorNotice';
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner';
import SmallBusinessClassificationDisplayName from 'components/shared/SmallBusinessClassificationDisplayName';
import SmallBusinessRequirementContent from 'components/shared/SmallBusinessRequirementContent';
import StyledTable from 'components/shared/Table';
import SmallBusinessAgencyDialog from 'components/SmallBusinessAgencyAdminDialog/SmallBusinessAgencyAdminDialog';
import { AGENCY_DIALOG_VIEWS } from 'constants/adminDialogViews';
import { SmallBusinessClassificationName } from 'constants/classificationNames';
import { SmallBusinessAdminRequirementOption } from 'constants/smallBusinessRequirementOptions';
import useRoles from 'hooks/useRoles';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { FC, MouseEvent, useState } from 'react';
import {
  GetSmallBusinessClassificationsQuery,
  SmallBusinessAgency,
  SmallBusinessAgencyClassification,
  useGetSmallBusinessClassificationsQuery,
  useSmallBusinessAgencyListQuery,
} from 'types/generated/graphql';
import { closeDialog, getColumnFormattedAdminData, openAdminDialog } from 'utils/adminTables';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Grid, IconButton, SxProps, Theme, Tooltip } from '@mui/material';

const actionsContainer: SxProps<Theme> = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: { justifyContent: 'flex-start' },
});

type SmallBusinessClassificationSelection =
  GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'][number];

const SmallBusinessAgencyAdminTable: FC = () => {
  const { isApplicationAdmin } = useRoles();
  const [smallBusinessAgencyDialogIsOpen, setSmallBusinessAgencyDialogIsOpen] = useState(false);
  const [smallBusinessAgencyData, setSmallBusinessAgencyData] = useState([]);
  const [smallBusinessAgencyDialogView, setSmallBusinessAgencyDialogView] = useState<any>();

  const [csvUploadDialogIsOpen, setCsvUploadDialogIsOpen] = useState(false);
  const [adminTableType, setAdminTableType] = useState('');

  const { EDIT_AGENCY, ADD_AGENCY, DELETE_AGENCY } = AGENCY_DIALOG_VIEWS;

  const {
    data: smallBusinessAgenciesData,
    loading: smallBusinessAgenciesLoading,
    error: smallBusinessAgenciesError,
  } = useSmallBusinessAgencyListQuery({
    variables: {
      isActive: [true, false],
    },
  });

  const smallBusinessAgencyList = smallBusinessAgenciesData?.smallBusinessAgencyList ?? [];

  const { data: smallBusinessClassificationsData, loading: smallBusinessClassificationsLoading } =
    useGetSmallBusinessClassificationsQuery();

  const smallBusinessClassifications =
    smallBusinessClassificationsData?.smallBusinessClassificationList
      .filter((classification) => classification.name !== SmallBusinessClassificationName.SBT)
      .sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  const handleCsvUploadDialog = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setCsvUploadDialogIsOpen(true);
  };

  const getUploadCsvButton = (id?: string) => {
    return (
      <Tooltip title={'Agency Mass Upload'}>
        <IconButton
          id={id}
          onClick={(event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
            setAdminTableType(event.currentTarget.id);
            handleCsvUploadDialog(event);
          }}
        >
          <FileUploadIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const smallBusinessClassificationsById = smallBusinessClassifications.reduce<{
    [id: string]: SmallBusinessClassificationSelection;
  }>((accumulator, classification) => {
    accumulator[classification.id] = classification;
    return accumulator;
  }, {});

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Agency Name',
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: 'abbreviation',
      label: 'Abbreviation',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: false,
        filterOptions: {
          fullWidth: true,
          renderValue: (abbreviation) => {
            if (abbreviation === '' || abbreviation === null || abbreviation === undefined) {
              return '(none)';
            }
            return abbreviation;
          },
        },
      },
    },
    {
      name: 'isActive',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          <ActiveOrInactiveStatusContent isActive={smallBusinessAgencyList[dataIndex]?.isActive as boolean} />
        ),
        filterOptions: {
          names: ['Active', 'Inactive'],
          logic: (isActive, filters) => {
            if (filters.length) {
              const status = isActive ? 'Active' : 'Inactive';
              return !filters.includes(status);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          },
        },
      },
    },
    {
      name: 'isFederal',
      label: 'SB Requirement',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          <SmallBusinessRequirementContent
            value={
              smallBusinessAgencyList[dataIndex]?.isFederal === true
                ? SmallBusinessAdminRequirementOption.FEDERAL
                : smallBusinessAgencyList[dataIndex]?.isFederal === false
                ? SmallBusinessAdminRequirementOption.NON_FEDERAL
                : SmallBusinessAdminRequirementOption.ANY_ALL
            }
          />
        ),
        filterOptions: {
          names: Object.values(SmallBusinessAdminRequirementOption),
          fullWidth: true,
          logic: (isFederal, filters) => {
            const federal = Object.is(isFederal, true);
            const nonFederal = Object.is(isFederal, false);
            if (filters.length) {
              const sbRequirement = federal
                ? SmallBusinessAdminRequirementOption.FEDERAL
                : nonFederal
                ? SmallBusinessAdminRequirementOption.NON_FEDERAL
                : SmallBusinessAdminRequirementOption.ANY_ALL;
              return !filters.includes(sbRequirement);
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          },
        },
      },
    },
    {
      name: 'sortOrder',
      label: '',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'agencyAndClassifications',
      label: 'Classifications',
      options: {
        filter: true,
        filterType: 'multiselect',
        filterOptions: {
          fullWidth: true,
          names: smallBusinessClassifications.map((classification) => classification.id),
          renderValue: (id) => {
            return smallBusinessClassificationsById[id].abbreviation;
          },
          logic: (classifications, filters) => {
            const copyOfClassifications = classifications as unknown as SmallBusinessAgencyClassification[];

            const smallBusinessClassificationIds =
              copyOfClassifications.map((classification) => classification.smallBusinessClassification.id) ?? [];

            if (filters.length) {
              return !smallBusinessClassificationIds.some((classificationId) => filters.includes(classificationId));
            }
            /* istanbul ignore next */
            return false; // this line can never be tested as filters will always exist
          },
        },
        customFilterListOptions: {
          render: (id) => smallBusinessClassificationsById[id].abbreviation,
        },
        searchable: true,
        sort: false,
        customBodyRenderLite: (dataIndex: number) => {
          const smallBusinessAgencyClassifications = smallBusinessAgencyList[
            dataIndex
          ].smallBusinessAgencyClassifications?.map((classification) => classification.smallBusinessClassification);

          return (
            <Grid container direction="row" spacing={2}>
              {smallBusinessAgencyClassifications
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((classification, index, array) => (
                  <Grid item key={classification.id}>
                    <Grid container alignItems="center" direction="row" spacing={1}>
                      <Grid item>
                        <SmallBusinessClassificationDisplayName
                          classification={classification}
                          displayFormat="abbreviation"
                          tooltipFormat="name"
                          displayFormatExtension={(formattedName) =>
                            `${formattedName}${index === array.length - 1 ? '' : ','}`
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          );
        },
      },
    },
    {
      name: 'url',
      label: 'Documentation',
      options: {
        filter: false,
        customBodyRender: (_, tableMeta) => {
          const urlIndex = 7;
          const url: string = tableMeta.rowData[urlIndex];
          const truncatedUrl = url && url.length > 30 ? `${url.substring(0, 30)}...` : url;
          return (
            url && (
              <a href={url} target="_blank" rel="noreferrer" title={url}>
                {truncatedUrl}
              </a>
            )
          );
        },
      },
    },
    {
      name: 'edit',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta.rowData;
          return (
            <>
              <Grid container sx={actionsContainer}>
                <Grid item>
                  <span>
                    <Tooltip title={'Edit Agency'}>
                      <IconButton
                        onClick={() =>
                          openAdminDialog(
                            rowData,
                            setSmallBusinessAgencyData,
                            setSmallBusinessAgencyDialogIsOpen,
                            setSmallBusinessAgencyDialogView,
                            EDIT_AGENCY,
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        filter: false,
        searchable: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (_, tableMeta) => {
          const rowData = tableMeta.rowData;
          return (
            <>
              <Grid container sx={actionsContainer}>
                <Grid item>
                  <span>
                    <Tooltip title={'Delete Agency'}>
                      <IconButton
                        onClick={() =>
                          openAdminDialog(
                            rowData,
                            setSmallBusinessAgencyData,
                            setSmallBusinessAgencyDialogIsOpen,
                            setSmallBusinessAgencyDialogView,
                            DELETE_AGENCY,
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    count: smallBusinessAgencyList?.length,
    rowsPerPageOptions: [],
    download: false,
    sortOrder: { name: 'name', direction: 'asc' },
    customToolbar: () => {
      return (
        <>
          <Tooltip title={'Add Agency'}>
            <IconButton
              onClick={() =>
                openAdminDialog(
                  [],
                  setSmallBusinessAgencyData,
                  setSmallBusinessAgencyDialogIsOpen,
                  setSmallBusinessAgencyDialogView,
                  ADD_AGENCY,
                )
              }
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          {isApplicationAdmin && (
            <Tooltip title={'Agency Mass Upload'}>
              <>{getUploadCsvButton('agencyAdminTable')}</>
            </Tooltip>
          )}
        </>
      );
    },
  };

  if (smallBusinessAgenciesError) {
    console.error(smallBusinessAgenciesError);
    return <ErrorNotice />;
  }

  if (smallBusinessAgenciesLoading || smallBusinessClassificationsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <StyledTable
        title="Agency List"
        data={getColumnFormattedAdminData(smallBusinessAgencyList as SmallBusinessAgency[]) ?? []}
        columns={columns}
        options={options}
      />
      <BackToTopButton />
      {smallBusinessAgencyDialogIsOpen && (
        <SmallBusinessAgencyDialog
          isOpen={smallBusinessAgencyDialogIsOpen}
          setIsOpen={setSmallBusinessAgencyDialogIsOpen}
          handleClose={() => closeDialog(setSmallBusinessAgencyDialogIsOpen, setSmallBusinessAgencyDialogView)}
          smallBusinessAgencyData={smallBusinessAgencyData}
          smallBusinessAgencyDialogView={smallBusinessAgencyDialogView}
          setSmallBusinessAgencyDialogView={setSmallBusinessAgencyDialogView}
          smallBusinessClassificationList={smallBusinessClassifications}
        />
      )}
      <CSVUploadDialog
        isOpen={csvUploadDialogIsOpen}
        setIsOpen={setCsvUploadDialogIsOpen}
        adminTableType={adminTableType}
        smallBusinessAgenciesList={smallBusinessAgencyList}
        smallBusinessClassificationsList={smallBusinessClassifications}
        smallBusinessClientList={[]}
      />
    </>
  );
};
export default SmallBusinessAgencyAdminTable;
