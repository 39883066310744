import reportOptionNotSet from 'assets/images/reportOptionNotSet.svg';
import ErrorNotice from 'components/shared/ErrorNotice';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import Notice, { NoticeTypeOption } from 'components/shared/Notice';
import StyledDatePicker from 'components/shared/StyledDatePicker/StyledDatePicker';
import SmallBusinessClassificationsCard from 'components/SmallBusinessClassificationsCard';
import SmallBusinessContractAmountsCard from 'components/SmallBusinessContractAmountsCard';
import SmallBusinessContractTable from 'components/SmallBusinessContractTable';
import SmallBusReportSaveDialog from 'components/SmallBusReportSaveDialog';
import SmallBusReportSettingsCreateForm from 'components/SmallBusReportSettingsCreateForm';
import {
  FederalSmallBusinessClassificationName,
  SmallBusinessClassificationName,
} from 'constants/classificationNames';
import { federalSmallBusinessReportOptions } from 'constants/federalSmallBusinessReportOptions';
import { SmallBusinessRequirementOption } from 'constants/smallBusinessRequirementOptions';
import { GET_SINGLE_PROJECT } from 'graphql/projects';
import { GET_SMALL_BUSINESS_REPORT } from 'graphql/smallBusinessReport';
import useProject from 'hooks/useProject';
import useRoles from 'hooks/useRoles';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  useSmallBusinessFederalReportFormOptionState,
} from 'recoil/smallBusinessFederalReportFormOption';
import {
  SmallBusinessFederalReportFormOptionSelection,
} from 'recoil/smallBusinessFederalReportFormOption/atom';
import {
  useSmallBusinessReportEndDateState,
  useSmallBusinessReportStartDateState,
} from 'recoil/smallBusinessReportDateRangeState';
import {
  GetSmallBusinessClassificationsQuery,
  Project,
  ProjectSmallBusinessReport,
  SmallBusinessClassification,
  SmallBusinessReportClassificationSummary,
  useCurrentAdUserQuery,
  useGetSmallBusinessClassificationsQuery,
} from 'types/generated/graphql';
import { convertUTCDateToLocalDate } from 'utils/general';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Button, Grid, MenuItem, Select, SxProps, Theme, Typography } from '@mui/material';

const sectionStyles: SxProps<Theme> = (theme: Theme) => ({
  marginBottom: theme.spacing(2),
});

const refreshButtonContainer: SxProps<Theme> = {
  position: 'relative',
};

const projectHeader: SxProps<Theme> = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  color: '#636363',
  fontSize: '1.5rem',
};

const reportOptionsLabel: SxProps<Theme> = (theme: Theme) => ({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1.5),
});

const refreshReportButton: SxProps<Theme> = (theme: Theme) => ({
  boxShadow: 'none',
  border: '1px solid #b5b5b5',
  backgroundColor: 'white',
  borderRadius: theme.spacing(3),
  marginBottom: theme.spacing(2),
});

const saveReportButton: SxProps<Theme> = (theme: Theme) => ({
  boxShadow: 'none',
  border: '1px solid #b5b5b5',
  backgroundColor: 'white',
  borderRadius: theme.spacing(3),
});

const saveAndRefreshReportButtonLabel: SxProps<Theme> = (theme: Theme) => ({
  color: '#636363',
  fontWeight: 'bold',
  marginTop: '5px',
  '&:hover': { color: theme.palette.secondary.main },
});

const largeBusinessContractsMessage: SxProps<Theme> = (theme: Theme) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.primary.main,
});

const reportOptionsContainer: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(2) },
});

const imgStyles: SxProps<Theme> = {
  width: 300,
};

const reportOptionNotSetTitleSx: SxProps<Theme> = (theme: Theme) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  marginTop: theme.spacing(2),
});

const reportOptionNotSetSubTitleSx: SxProps<Theme> = {
  textAlign: 'center',
  fontSize: '0.875rem',
};

type useParamsProps = { projectId?: string };

type PickedSmallBusinessReportClassificationSummary = Pick<
  SmallBusinessReportClassificationSummary,
  'smallBusinessClassificationId' | 'goalPercent'
>;

type PickedSmallBusinessClassification = Pick<
  SmallBusinessClassification,
  'id' | 'abbreviation' | 'name' | 'sortOrder'
>;

type SelectedReportOptionInput = {
  reportOptionSelect: SmallBusinessFederalReportFormOptionSelection;
};

type SmallBusinessClassificationSelection = GetSmallBusinessClassificationsQuery['smallBusinessClassificationList'];

const SmallBusinessReportPage: FC = () => {
  const { projectId } = useParams<useParamsProps>();

  const [selectedStartDate, { handleSmallBusinessReportStartDateChange }] = useSmallBusinessReportStartDateState();
  const [selectedEndDate, { handleSmallBusinessReportEndDateChange }] = useSmallBusinessReportEndDateState();

  const [saveReportDialogIsOpen, setSaveReportDialogIsOpen] = useState(false);
  const [validSelectedEndDate, setValidSelectedEndDate] = useState<string | null>(selectedEndDate);

  const { project: updatedProject, loading: projectLoading, error: projectError } = useProject(projectId);

  const { data: smallBusinessClassificationsData } = useGetSmallBusinessClassificationsQuery();
  const allSmallBusinessClassifications: SmallBusinessClassificationSelection =
    smallBusinessClassificationsData?.smallBusinessClassificationList ?? [];
  const largeBusinessClassification = allSmallBusinessClassifications?.find(
    (classification) => classification.name === SmallBusinessClassificationName.LB,
  );
  const smallBusinessTotalClassification = allSmallBusinessClassifications?.find(
    (classification) => classification.name === SmallBusinessClassificationName.SBT,
  );

  const { isEnterpriseAdmin } = useRoles();
  const { data: currentAdUserData } = useCurrentAdUserQuery({
    fetchPolicy: 'no-cache',
  });
  const isAdmin =
    isEnterpriseAdmin || currentAdUserData?.currentAdUser?.projects?.some((project) => project.id === projectId);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      if (selectedStartDate > selectedEndDate) {
        setValidSelectedEndDate(selectedStartDate);
      } else {
        setValidSelectedEndDate(selectedEndDate);
      }
    }
  }, [selectedEndDate, selectedStartDate, validSelectedEndDate]);

  const [smallBusinessFederalReportFormOption, { handleSmallBusinessFederalReportFormOptionChange }] =
    useSmallBusinessFederalReportFormOptionState();
  const { handleSubmit, control, watch } = useForm<SelectedReportOptionInput>({
    defaultValues: { reportOptionSelect: smallBusinessFederalReportFormOption },
  });
  const selectedReportOption = watch('reportOptionSelect');
  useEffect(() => {
    if (smallBusinessFederalReportFormOption !== selectedReportOption) {
      handleSmallBusinessFederalReportFormOptionChange(selectedReportOption);
    }
  }, [smallBusinessFederalReportFormOption, handleSmallBusinessFederalReportFormOptionChange, selectedReportOption]);

  const [
    handleRefreshProject,
    { loading: refreshProjectLoading, error: refreshProjectError, data: refreshProjectData },
  ] = useLazyQuery(GET_SINGLE_PROJECT, {
    variables: {
      id: updatedProject?.id,
    },
    fetchPolicy: 'network-only',
  });
  const project: Project = refreshProjectData?.project || updatedProject;
  const isFederalReport = project?.smallBusinessRequirement === SmallBusinessRequirementOption.FEDERAL;

  const smallBusinessClientClassifications: PickedSmallBusinessClassification[] =
    project?.smallBusinessClient?.smallBusinessClientAgencies?.flatMap((agency) =>
      agency.smallBusinessClientClassifications?.flatMap(
        (classification) => classification.smallBusinessClassification,
      ),
    ) ?? [];

  largeBusinessClassification &&
    smallBusinessTotalClassification &&
    smallBusinessClientClassifications.push(largeBusinessClassification, smallBusinessTotalClassification);

  const smallBusinessClientClassificationsWithoutDuplicates = smallBusinessClientClassifications.filter(
    (smallBusinessClientClassification, index) =>
      smallBusinessClientClassifications.findIndex(
        (classification) => classification.id === smallBusinessClientClassification.id,
      ) === index,
  );

  const clientAcceptedClassifications = [...smallBusinessClientClassificationsWithoutDuplicates]?.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const {
    loading: reportLoading,
    error: reportError,
    data: reportData,
  } = useQuery(GET_SMALL_BUSINESS_REPORT, {
    skip: !project?.smallBusinessReportSettings?.id,
    variables: {
      smallBusinessReportSettingsId: project?.smallBusinessReportSettings?.id,
      startDate: selectedStartDate ?? undefined,
      endDate: selectedEndDate ?? undefined,
      form: selectedReportOption === '' ? undefined : selectedReportOption,
    },
    fetchPolicy: 'network-only',
  });

  const [handleRefreshReport, { loading: refreshReportLoading, error: refreshReportError, data: refreshReportData }] =
    useLazyQuery(GET_SMALL_BUSINESS_REPORT, {
      variables: {
        smallBusinessReportSettingsId: project?.smallBusinessReportSettings?.id,
        startDate: selectedStartDate ?? undefined,
        endDate: selectedEndDate ?? undefined,
        form: selectedReportOption === '' ? undefined : selectedReportOption,
      },
      fetchPolicy: 'network-only',
    });

  const shouldShowForm = !project?.smallBusinessReportSettings;
  const onSubmit: SubmitHandler<SelectedReportOptionInput> = (data) => {
    console.log('HANDLE SUBMIT TO API');
    console.log(data);
  };

  const refreshProjectSmallBusinessReport: ProjectSmallBusinessReport =
    refreshReportData?.projectFederalSmallBusinessReport;
  const projectSmallBusinessReport: ProjectSmallBusinessReport = reportData?.projectSmallBusinessReport;
  const contractSummaryData =
    (projectSmallBusinessReport?.contractSummaries || refreshProjectSmallBusinessReport?.contractSummaries) ?? [];
  const clientSupportedClassifications: PickedSmallBusinessClassification[] = clientAcceptedClassifications ?? [];
  const classificationSummaries: Array<SmallBusinessReportClassificationSummary> =
    (projectSmallBusinessReport?.classificationSummaries ||
      refreshProjectSmallBusinessReport?.classificationSummaries) ??
    [];
  const largeBusinessReportSummaryClassification = projectSmallBusinessReport?.classificationSummaries?.find(
    (classification) => classification.name === FederalSmallBusinessClassificationName.LB,
  );
  const shouldShowLargeContractMessage =
    isFederalReport &&
    (largeBusinessReportSummaryClassification ? largeBusinessReportSummaryClassification.actualTotal > 1500000 : false);

  const datesSet =
    (!isFederalReport && !!selectedStartDate && !!selectedEndDate) || (isFederalReport && !!selectedReportOption);
  const reportOptionNotSetSubTitle = isFederalReport
    ? 'Select a REPORT OPTION above to get started.'
    : 'Select a START and END date above to get started.';
  const reportOptionNotSetTitle = isFederalReport ? 'Federal Small Business Report' : 'Small Business Report';

  const isSaveReportDisabled = (isFederalReport && !selectedReportOption) || !projectSmallBusinessReport || !project;
  const shouldShowInconsistentTotalsMessage =
    isFederalReport &&
    !project?.smallBusinessReportSettings?.isActualCalculationsBasedOnTotalContractValue &&
    classificationSummaries?.reduce((total, classification) => {
      if (classification.name === 'Large Business' || classification.name === 'Small Business Total') {
        return total + classification.actualTotal;
      }
      return total;
    }, 0) !==
      contractSummaryData?.reduce((total, contract) => {
        if (contract.companyId !== '' && !contract.isSelfWork) {
          return total + contract.valueTotal;
        }
        return total;
      }, 0);
  const shouldShowTechnicalServicesMessage =
    isFederalReport &&
    (projectSmallBusinessReport?.contractSummaries?.some((contractSummary) => contractSummary.valueTotal > 750000) ??
      false);

  let filterExistingClassifications: PickedSmallBusinessReportClassificationSummary[] = [];
  classificationSummaries
    .filter((classification) => classification.name !== SmallBusinessClassificationName.SBT)
    .map((summary) => {
      return filterExistingClassifications.push({
        smallBusinessClassificationId: summary.smallBusinessClassificationId,
        goalPercent: parseFloat(`${summary.goalPercent}`),
      });
    });

  const commentsShouldBeRequired = classificationSummaries?.some(
    (classification: SmallBusinessReportClassificationSummary) => {
      if (classification.goalPercent && classification.name !== 'Large Business') {
        return classification.actualPercentTotal < classification.goalPercent;
      }
      return false;
    },
  );

  if (projectLoading || reportLoading || refreshReportLoading || refreshProjectLoading) {
    return <LoadingSpinner />;
  }
  const errorMessage = projectError ?? reportError ?? refreshReportError ?? refreshProjectError ?? '';
  if (errorMessage) {
    console.error('reportError', errorMessage);
    return <ErrorNotice />;
  }

  const handleStartDateChange = (date: DateTime | null) => {
    if (date) {
      handleSmallBusinessReportStartDateChange(date.toISO());
    }
    if (validSelectedEndDate) {
      handleSmallBusinessReportEndDateChange(validSelectedEndDate);
    }
  };
  const handleEndDateChange = (date: DateTime | null) => {
    if (date) {
      handleSmallBusinessReportEndDateChange(date.toISO());
    }
  };
  return (
    <>
      {shouldShowForm && <SmallBusReportSettingsCreateForm projectName={project?.name ?? ''} editable={isAdmin} />}
      {!shouldShowForm && (
        <>
          <Grid container>
            {!isAdmin && (
              <Grid item xs={12} sx={sectionStyles}>
                <Notice
                  type={NoticeTypeOption.Notice}
                  message="You are viewing this report in read-only mode. Edit permissions are managed by the Director of Supplier Diversity. "
                />
              </Grid>
            )}
            <Grid item xs={12} sx={sectionStyles}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography sx={projectHeader}>
                    {project?.name} - {project?.number}
                  </Typography>
                  <Typography>{project?.address}</Typography>
                  <Typography>
                    {project?.city}, {project?.state} {project?.zip}
                  </Typography>
                </Grid>
                <Grid item sx={reportOptionsContainer}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      {isFederalReport && (
                        <>
                          <Typography sx={reportOptionsLabel}>Report Options</Typography>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  displayEmpty
                                  margin="dense"
                                  variant="outlined"
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                  }}
                                >
                                  <MenuItem value="">Select Report Option</MenuItem>
                                  {federalSmallBusinessReportOptions.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                              name="reportOptionSelect"
                              control={control}
                            />
                          </form>
                        </>
                      )}
                      {!isFederalReport && (
                        <>
                          <Typography sx={reportOptionsLabel}>Date Range</Typography>
                          <StyledDatePicker
                            label="Start Date"
                            value={convertUTCDateToLocalDate(selectedStartDate) ?? null}
                            handleDateChange={(newDate) => {
                              // make sure year is 4 digits long before page refreshes when using keyboard nav
                              newDate && /^\d{4}$/.test(newDate?.year.toString()) && handleStartDateChange(newDate);
                            }}
                          />
                          <StyledDatePicker
                            label="End Date"
                            value={convertUTCDateToLocalDate(validSelectedEndDate) ?? null}
                            handleDateChange={(newDate) => {
                              // make sure year is 4 digits long before page refreshes when using keyboard nav
                              newDate && /^\d{4}$/.test(newDate?.year.toString()) && handleEndDateChange(newDate);
                            }}
                            minDate={convertUTCDateToLocalDate(selectedStartDate)}
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!datesSet && (
              <Grid item xs={12} sx={sectionStyles}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <Box
                        component="img"
                        sx={imgStyles}
                        src={reportOptionNotSet}
                        alt={'Man viewing data graphs'}
                      ></Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={reportOptionNotSetTitleSx}>{reportOptionNotSetTitle}</Typography>
                    <Typography sx={reportOptionNotSetSubTitleSx}> {reportOptionNotSetSubTitle}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {datesSet && (
              <>
                <Grid item xs={12} sx={refreshButtonContainer}>
                  <Grid container>
                    <Button
                      sx={refreshReportButton}
                      onClick={() => {
                        handleRefreshReport();
                        handleRefreshProject();
                      }}
                      size="small"
                      variant="contained"
                    >
                      <Typography sx={saveAndRefreshReportButtonLabel}>Refresh for Updates</Typography>
                    </Button>
                  </Grid>
                </Grid>
                {shouldShowInconsistentTotalsMessage && (
                  <Grid item xs={12}>
                    <Notice
                      type={NoticeTypeOption.Notice}
                      message={
                        <Typography>
                          Subcontracted subtotal does not match classification summaries. There is likely one or more
                          contracted companies without assigned classifications.
                        </Typography>
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} sx={sectionStyles}>
                  {project && projectSmallBusinessReport && (
                    <SmallBusinessContractAmountsCard
                      project={project}
                      report={projectSmallBusinessReport}
                      editable={isAdmin}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sx={sectionStyles}>
                  {projectSmallBusinessReport && (
                    <SmallBusinessClassificationsCard
                      classifications={classificationSummaries}
                      smallBusinessReportSettings={project?.smallBusinessReportSettings || undefined}
                      smallBusinessRequirements={project?.smallBusinessRequirement}
                      selectedStartDate={selectedStartDate}
                      selectedEndDate={selectedEndDate}
                      selectedReportOption={selectedReportOption}
                      originalContractValue={project?.originalContractValue || undefined}
                      currentContractValue={projectSmallBusinessReport?.currentContractValue}
                      currentSubContractorContractValue={projectSmallBusinessReport?.currentSubContractorContractValue}
                      projectNumber={project?.number}
                      smallBusinessClassifications={clientSupportedClassifications}
                      editable={isAdmin}
                    />
                  )}
                </Grid>
                {shouldShowLargeContractMessage && (
                  <Grid item xs={12}>
                    <Typography sx={largeBusinessContractsMessage}>
                      Your project includes large business contracts greater than $1,500,000 - highlighted above. A
                      small business subcontracting plan from these companies must be uploaded to the Small Business
                      site on the Employee Portal.
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} sx={sectionStyles}>
                  <SmallBusinessContractTable
                    classifications={clientSupportedClassifications}
                    contractSummaryData={contractSummaryData}
                    project={project ?? undefined}
                    editable={isAdmin}
                    smallBusinessReportSettings={project?.smallBusinessReportSettings || undefined}
                    smallBusinessReportClassificationSummaries={projectSmallBusinessReport?.classificationSummaries}
                    allSmallBusinessClassifications={allSmallBusinessClassifications}
                    selectedReportOption={selectedReportOption}
                  />
                </Grid>
                {isAdmin && (
                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                      <Button
                        sx={saveReportButton}
                        onClick={() => setSaveReportDialogIsOpen(true)}
                        size="small"
                        variant="contained"
                        disabled={isSaveReportDisabled}
                      >
                        <Typography sx={saveAndRefreshReportButtonLabel}>Save Report</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          {projectSmallBusinessReport && project && (
            <SmallBusReportSaveDialog
              report={projectSmallBusinessReport}
              smallBusReportSaveDialogIsOpen={saveReportDialogIsOpen}
              setSmallBusReportSaveDialogIsOpen={setSaveReportDialogIsOpen}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              selectedReportOption={selectedReportOption}
              shouldShowTechnicalServicesMessage={shouldShowTechnicalServicesMessage}
              smallBusinessReportSettings={project?.smallBusinessReportSettings}
              commentsShouldBeRequired={commentsShouldBeRequired}
              smallBusinessRequirements={project?.smallBusinessRequirement}
            />
          )}
        </>
      )}
    </>
  );
};

export default SmallBusinessReportPage;
