import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import StyledDatePicker from 'components/shared/StyledDatePicker/StyledDatePicker';
import { SmallBusinessClassificationName } from 'constants/classificationNames';
import { UPDATE_PROJECT } from 'graphql/projects';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router-dom';
import { LowerTierFileSummary } from 'types';
import {
  NestedLowerTierParticipationFileSummary,
  SmallBusinessAgency,
  SmallBusinessClassification,
  SmallBusinessClient,
  SmallBusinessReportClassificationSummary,
  SmallBusinessReportContractSummary,
  SmallBusinessReportSettings,
  useUpsertLowerTierParticipationMutation,
} from 'types/generated/graphql';
import { convertUTCDateToLocalDate, generateTransactionKey } from 'utils/general';

import { useApolloClient, useMutation } from '@apollo/client';
import { Grid, InputAdornment, SxProps, TextField, Theme, Typography } from '@mui/material';

import LowerTierFileSummaryDialog from '../LowerTierFileSummaryDialog/LowerTierFileSummaryDialog';

const containerStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: theme.spacing(2),
});

const formStyles: SxProps<Theme> = {
  width: '100%',
};

const infoLabel: SxProps<Theme> = {
  lineHeight: '1.5',
  fontSize: '0.75rem',
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.6)',
  marginTop: '10px',
};

const errorMessageStyle: SxProps<Theme> = {
  color: '#cd0000',
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.75rem',
};

const valueTotalInput: SxProps<Theme> = {
  margin: 0,
};

const dateContainer: SxProps<Theme> = {
  marginRight: '50px',
};

const addAgencyAndDocumentationButton: SxProps<Theme> = {
  width: 0,
};

type PickedSmallBusinessClassification = Pick<
  SmallBusinessClassification,
  'id' | 'name' | 'abbreviation' | 'sortOrder'
>;

type PickedSmallBusinessReportClassificationSummary = Pick<
  SmallBusinessReportClassificationSummary,
  'smallBusinessClassificationId' | 'goalPercent'
>;

type AddLowerTierParticipationDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  contractId?: string;
  companyId?: string;
  smallBusinessReportSettings?: SmallBusinessReportSettings;
  smallBusinessReportClassificationSummaries?: SmallBusinessReportClassificationSummary[];
  smallBusinessClient?: SmallBusinessClient | undefined;
  contractSummaryData?: SmallBusinessReportContractSummary | undefined;
};

type useParamsProps = {
  projectId?: string;
};

const AddLowerTierParticipationDialog: React.FC<AddLowerTierParticipationDialogProps & Record<string, any>> = ({
  isOpen,
  setIsOpen,
  contractId,
  companyId,
  effectiveDate,
  allSmallBusinessClassifications,
  smallBusinessReportSettings,
  smallBusinessReportClassificationSummaries,
  smallBusinessClient,
  contractSummaryData,
}) => {
  const { displayToast } = useToast();
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const apolloClient = useApolloClient();
  const { projectId } = useParams<useParamsProps>();
  const contractValueTotal = contractSummaryData?.valueTotal as number;

  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [lowerTierParticipationFileSummary, setLowerTierParticipationFileSummary] = useState<LowerTierFileSummary[]>([
    {
      transactionKey: generateTransactionKey(),
      Agency: '',
      Classifications: [],
      Documentation: [],
    },
  ]);
  const [lowerTierParticipationFileSummaryValues, setLowerTierParticipationFileSummaryValues] = useState<
    LowerTierFileSummary[]
  >([
    {
      transactionKey: generateTransactionKey(),
      Agency: '',
      Classifications: [],
      Documentation: [],
    },
  ]);

  const [addLowerTierParticipation, { loading: isLowerTierParticipationLoading }] =
    useUpsertLowerTierParticipationMutation();

  const [updateProject] = useMutation(UPDATE_PROJECT, {
    awaitRefetchQueries: true,
  });

  const { handleSubmit, control, setValue, watch, reset } = useForm();

  const smallBusinessAgencyList: SmallBusinessAgency[] | undefined = smallBusinessClient?.smallBusinessClientAgencies
    .map((agency) => agency.smallBusinessAgency)
    .sort((a, b) => a.name.localeCompare(b.name));
  interface LowerTierParticipationFormValues {
    contractId: string;
    companyId: string;
    lowerTierCompanyName: string;
    valueTotal: number;
    smallBusinessAgency: string;
    smallBusinessClassifications: PickedSmallBusinessClassification[];
    effectiveDate: DateTime | undefined;
  }

  useEffect(() => {
    //  using this if statement because even though typescript thinks its a MaterialUiPickersDate its for some reason a string
    if (typeof effectiveDate == 'string') {
      setValue('effectiveDate', DateTime.fromISO(effectiveDate));
    } else {
      setValue('effectiveDate', effectiveDate);
    }
    setValue('companyId', companyId);
    setValue('contractId', contractId);
  }, [setValue, companyId, effectiveDate, contractId]);

  const selectedLowerTierCompanyName = watch('lowerTierCompanyName');
  const selectedValue = watch('valueTotal');
  const selectedEffectiveDate = watch('effectiveDate');

  const smallBusinessClassificationIdByName = allSmallBusinessClassifications?.reduce(
    (accumulator: any, classification: PickedSmallBusinessClassification) => {
      accumulator[classification.name] = classification.id;
      return accumulator;
    },
    {},
  );

  const lbId =
    smallBusinessClassificationIdByName && smallBusinessClassificationIdByName[SmallBusinessClassificationName.LB];

  const handleClose = () => {
    if (typeof effectiveDate == 'string') {
      reset({
        companyId: companyId,
        contractId: contractId,
        effectiveDate: DateTime.fromISO(effectiveDate),
      });
    } else {
      reset({ companyId: companyId, effectiveDate: effectiveDate, contractId: contractId });
    }
    setIsOpen(false);
    setTransactionKey(generateTransactionKey());
    setLowerTierParticipationFileSummary([
      {
        Agency: '',
        Classifications: [],
        Documentation: [],
      },
    ]);
  };

  const handleAddLowerTierFileSummary = () => {
    let newLowerTierParticipationFileSummary = {
      transactionKey: generateTransactionKey(),
      Agency: '',
      Classifications: [],
      Documentation: [],
    };
    setLowerTierParticipationFileSummary([...lowerTierParticipationFileSummary, newLowerTierParticipationFileSummary]);
  };

  const handleLowerTierFileSummaryChange = (value: any, index: number, key: keyof LowerTierFileSummary) => {
    let lowerTierParticipationFileSummaryCopy: LowerTierFileSummary[] = lowerTierParticipationFileSummary;

    lowerTierParticipationFileSummaryCopy[index][key] =
      key === 'Agency'
        ? smallBusinessAgencyList?.find((agency) => {
            return agency.id === value;
          })?.id
        : key === 'Classifications'
        ? Array.isArray(value)
          ? value.map((classification) => classification.id)
          : []
        : key === 'Documentation'
        ? Array.isArray(value)
          ? value.map((file) => file)
          : []
        : value;
    setLowerTierParticipationFileSummary(lowerTierParticipationFileSummaryCopy);
  };

  const handleRemoveLowerTierFileSummary = (index: number) => {
    let lowerTierParticipationFileSummaryData = [...lowerTierParticipationFileSummary];
    lowerTierParticipationFileSummaryData.splice(index, 1);
    setLowerTierParticipationFileSummary(lowerTierParticipationFileSummaryData);
    setFormIsValid(true);
  };

  const hasClassifications = lowerTierParticipationFileSummaryValues.map((summary) => {
    if (summary.Classifications.length > 0) {
      return true;
    }
    return false;
  });

  const filesExist = lowerTierParticipationFileSummaryValues.map((summary) => summary.Documentation.length > 0);

  const isSelectedValueValid = (value: string) => {
    return +value.replace(/,/g, '') <= contractValueTotal;
  };

  const isFormValid = () => {
    if (
      (selectedValue &&
        isSelectedValueValid(selectedValue) &&
        selectedLowerTierCompanyName &&
        !hasClassifications.includes(false) &&
        !filesExist.includes(false) &&
        selectedEffectiveDate !== null &&
        selectedEffectiveDate?.isValid) ||
      formIsValid
    ) {
      return true;
    }
    return false;
  };

  const onSubmit: SubmitHandler<LowerTierParticipationFormValues> = (data) => {
    const fileIndex = 0;

    if (!contractId) {
      displayToast(
        'Error: Something went wrong while trying to update the contract information. Please contact support.',
        'error',
      );
      return;
    }
    const value = data.valueTotal.toString();
    const smallBusinessClassificationIds: string[] = [];

    const lowerTierParticipationFileSummaryArr: NestedLowerTierParticipationFileSummary[] = [];
    lowerTierParticipationFileSummary.forEach((summary) => {
      !summary.Agency
        ? smallBusinessClassificationIds.push(lbId)
        : smallBusinessClassificationIds.push(...summary.Classifications);
      lowerTierParticipationFileSummaryArr.push({
        transactionKey: generateTransactionKey(),
        file: summary.Documentation[fileIndex],
        smallBusinessAgencyId: summary.Agency || null,
        lowerTierParticipationClassifications: !summary.Agency
          ? [{ smallBusinessClassificationId: lbId }]
          : summary.Classifications.map((classification) => {
              return {
                smallBusinessClassificationId: classification,
              };
            }) ?? [],
      });
    });

    addLowerTierParticipation({
      variables: {
        input: {
          transactionKey,
          contractId: data.contractId,
          value: parseFloat(value.replaceAll(',', '')),
          lowerTierCompanyName: data.lowerTierCompanyName,
          effectiveDate: data.effectiveDate?.toISODate() ?? null,
          filesToAdd: lowerTierParticipationFileSummaryArr ?? [],
        },
      },
    })
      .then(() => {
        const classificationsToAdd = smallBusinessClassificationIds.map((id) => {
          return {
            smallBusinessClassificationId: id,
            goalPercent: 0,
          };
        }) as PickedSmallBusinessReportClassificationSummary[];

        const existingClassifications: PickedSmallBusinessReportClassificationSummary[] = [];
        smallBusinessReportClassificationSummaries
          ?.filter((classification) => classification.name !== SmallBusinessClassificationName.SBT)
          .forEach((classification) => {
            existingClassifications.push({
              smallBusinessClassificationId: classification.smallBusinessClassificationId,
              goalPercent: parseFloat(`${classification.goalPercent}`),
            });
          });
        let classifications: PickedSmallBusinessReportClassificationSummary[] = [];
        if (existingClassifications) {
          classifications = existingClassifications;
        }
        if (classificationsToAdd) {
          classifications = classificationsToAdd;
        }
        if (classificationsToAdd && existingClassifications) {
          classifications = classificationsToAdd.concat(existingClassifications);
        }

        updateProject({
          variables: {
            input: {
              id: projectId,
              smallBusinessReportSettings: {
                id: smallBusinessReportSettings?.id,
                classifications: classifications,
              },
            },
          },
        }).then(() => {
          apolloClient.reFetchObservableQueries();
        });
      })
      .then(() => {
        displayToast('The lower tier participation information was added successfully', 'success');
        handleClose();
        setLowerTierParticipationFileSummary([
          {
            Agency: '',
            Classifications: [],
            Documentation: [],
          },
        ]);
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying to add lower tier participation information. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <StyledDialog
      title={'Lower Tier Participation Contract Info'}
      isLoading={isLowerTierParticipationLoading}
      content={
        <Grid container sx={containerStyles}>
          <Grid sx={formStyles}>
            <form>
              <Grid item>
                <Grid item>
                  <Typography sx={infoLabel}>Lower Tier Participation Name</Typography>
                </Grid>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size="small"
                      error={!selectedLowerTierCompanyName}
                    />
                  )}
                  name="lowerTierCompanyName"
                  control={control}
                />
                <Grid sx={errorMessageStyle}>{!selectedLowerTierCompanyName ? 'Company is required' : null}</Grid>
              </Grid>
              <Grid container>
                <Grid item sx={dateContainer}>
                  <Grid item>
                    <Typography sx={infoLabel}>Effective Date</Typography>
                  </Grid>
                  <Controller
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <StyledDatePicker
                        value={value ? convertUTCDateToLocalDate(value) : null}
                        handleDateChange={(newDate) => {
                          onChange(newDate);
                        }}
                        shouldDisplayAsError={!!error || !selectedEffectiveDate}
                      />
                    )}
                    name="effectiveDate"
                    control={control}
                    rules={{
                      required: false,
                    }}
                  />
                  <Grid sx={errorMessageStyle}>{!selectedEffectiveDate ? 'Date is required' : null}</Grid>
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography sx={infoLabel}>Total</Typography>
                  </Grid>
                  <Controller
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        sx={valueTotalInput}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowLeadingZeros={false}
                        isNumericString={true}
                        thousandSeparator={true}
                        customInput={TextField}
                        margin="dense"
                        variant="outlined"
                        color="primary"
                        error={!selectedValue}
                        required={true}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    )}
                    name="valueTotal"
                    control={control}
                    rules={{ required: true, min: 0, max: contractValueTotal }}
                  />
                  <Grid sx={errorMessageStyle}>
                    {!selectedValue
                      ? 'Total is required'
                      : isSelectedValueValid(selectedValue) === false
                      ? `Total cannot be greater than the contract total: $${contractValueTotal}`
                      : null}
                  </Grid>
                </Grid>
              </Grid>
              {lowerTierParticipationFileSummary?.map((fileSummary, index) => {
                return (
                  <LowerTierFileSummaryDialog
                    key={index}
                    control={control}
                    lowerTierFileSummary={fileSummary}
                    handleLowerTierFileSummaryChange={handleLowerTierFileSummaryChange}
                    lowerTierSummaryIndex={index}
                    smallBusinessAgencyList={smallBusinessAgencyList}
                    allSmallBusinessClassifications={allSmallBusinessClassifications}
                    handleRemoveLowerTierFileSummary={handleRemoveLowerTierFileSummary}
                    handleLowerTierFileSummaryInputFields={(lowerTierFileSummary: LowerTierFileSummary[]) =>
                      setLowerTierParticipationFileSummaryValues(lowerTierFileSummary)
                    }
                    isLowerTierDialogOpen={isOpen}
                    isAddingLowerTierParticipation={true}
                  />
                );
              })}
            </form>
          </Grid>
        </Grid>
      }
      actions={
        <>
          <Grid container justifyContent="center">
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <StyledButtonSecondary
                    disabled={isLowerTierParticipationLoading}
                    label={'cancel'}
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item>
                  <StyledButtonPrimary
                    label={'submit'}
                    type="submit"
                    disabled={!isFormValid() || isLowerTierParticipationLoading}
                    onClick={handleSubmit(onSubmit)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={addAgencyAndDocumentationButton} justifyContent={'flex-end'}>
            <Grid item>
              <StyledButtonSecondary
                disabled={isLowerTierParticipationLoading}
                label={'Add Agency/ Documentation'}
                onClick={handleAddLowerTierFileSummary}
              />
            </Grid>
          </Grid>
        </>
      }
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

AddLowerTierParticipationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  contractId: PropTypes.string,
  companyId: PropTypes.string,
  phaseCode: PropTypes.string,
  valueTotal: PropTypes.number,
  effectiveDate: PropTypes.any,
  lowerTierCompanyName: PropTypes.string,
  lowerTierParticipationId: PropTypes.string,
  smallBusinessClassificationAbbreviations: PropTypes.array,
};

export default AddLowerTierParticipationDialog;
