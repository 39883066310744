import { atom, useRecoilState } from 'recoil';

export type SmallBusinessReportDateRangeSelection = {
  startDate: string;
  endDate: string;
};

const defaultStartDate = null;
const defaultEndDate = null;

const smallBusinessReportStartDateAtom = atom<string | null>({
  key: 'smallBusinessReportStartDate',
  default: defaultStartDate,
});

const smallBusinessReportEndDateAtom = atom<string | null>({
  key: 'smallBusinessReportEndDate',
  default: defaultEndDate,
});

export const useSmallBusinessReportStartDateState = () => {
  const [smallBusinessReportDateOption, setSmallBusinessReportStartDateOption] = useRecoilState(
    smallBusinessReportStartDateAtom,
  );

  function handleSmallBusinessReportStartDateChange(newStartDate: string) {
    setSmallBusinessReportStartDateOption(newStartDate);
  }

  return [smallBusinessReportDateOption, { handleSmallBusinessReportStartDateChange }] as const;
};

export const useSmallBusinessReportEndDateState = () => {
  const [smallBusinessReportDateOption, setSmallBusinessReportEndDateOption] =
    useRecoilState(smallBusinessReportEndDateAtom);

  function handleSmallBusinessReportEndDateChange(newEndDate: string) {
    setSmallBusinessReportEndDateOption(newEndDate);
  }

  return [smallBusinessReportDateOption, { handleSmallBusinessReportEndDateChange }] as const;
};

const smallBusinessReportDateRangeState = { smallBusinessReportStartDateAtom, smallBusinessReportEndDateAtom };

export default smallBusinessReportDateRangeState;
