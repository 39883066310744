import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import StyledDatePicker from 'components/shared/StyledDatePicker/StyledDatePicker';
import { SmallBusinessClassificationName } from 'constants/classificationNames';
import { UPDATE_PROJECT } from 'graphql/projects';
import useToast from 'hooks/useToast';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router-dom';
import { LowerTierFileSummary } from 'types';
import {
  LowerTierParticipationClassificationSummary,
  NestedLowerTierParticipationFileSummary,
  SmallBusinessAgency,
  SmallBusinessClassification,
  SmallBusinessClient,
  SmallBusinessReportClassificationSummary,
  SmallBusinessReportContractSummary,
  SmallBusinessReportSettings,
  UploadedFile,
  useUpsertLowerTierParticipationMutation,
} from 'types/generated/graphql';
import { convertUTCDateToLocalDate, generateTransactionKey } from 'utils/general';

import { useApolloClient, useMutation } from '@apollo/client';
import { Grid, InputAdornment, SxProps, TextField, Theme, Typography } from '@mui/material';

import LowerTierFileSummaryDialog from '../LowerTierFileSummaryDialog';

const formStyles: SxProps<Theme> = {
  width: '100%',
};

const infoLabel: SxProps<Theme> = {
  lineHeight: '1.5',
  fontSize: '0.75rem',
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.6)',
  marginTop: '10px',
};

const errorMessageStyle: SxProps<Theme> = {
  color: '#cd0000',
  marginLeft: '14px',
  marginTop: '4px',
  fontSize: '.75rem',
};

const dateContainer: SxProps<Theme> = {
  marginRight: '50px',
};

const valueTotalInput: SxProps<Theme> = {
  margin: 0,
};

const addAgencyAndDocumentationButton: SxProps<Theme> = {
  width: 0,
};

type PickedSmallBusinessReportClassificationSummary = Pick<
  SmallBusinessReportClassificationSummary,
  'smallBusinessClassificationId' | 'goalPercent'
>;

type PickedSmallBusinessClassification = Pick<
  SmallBusinessClassification,
  'id' | 'name' | 'abbreviation' | 'sortOrder'
>;

type EditLowerTierParticipationDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  lowerTierParticipation?: LowerTierParticipationClassificationSummary | undefined;
  smallBusinessReportSettings?: SmallBusinessReportSettings;
  smallBusinessReportClassificationSummaries?: SmallBusinessReportClassificationSummary[];
  smallBusinessClient?: SmallBusinessClient | undefined;
  contractSummaryData?: SmallBusinessReportContractSummary | undefined;
};

type useParamsProps = {
  projectId?: string;
};

const EditLowerTierParticipationDialog: React.FC<EditLowerTierParticipationDialogProps & Record<string, any>> = ({
  isOpen,
  setIsOpen,
  lowerTierParticipation,
  allSmallBusinessClassifications,
  smallBusinessReportSettings,
  smallBusinessReportClassificationSummaries,
  smallBusinessClient,
  contractSummaryData,
}) => {
  const { displayToast } = useToast();
  const apolloClient = useApolloClient();
  const { projectId } = useParams<useParamsProps>();

  const contractValueTotal = contractSummaryData?.valueTotal as number;

  const lowerTierParticipationId = lowerTierParticipation?.lowerTierParticipationId;
  const contractId = lowerTierParticipation?.contractId;
  const lowerTierCompanyName = lowerTierParticipation?.lowerTierCompanyName;
  const valueTotal = lowerTierParticipation?.value;
  const effectiveDate = lowerTierParticipation?.effectiveDate;
  const lowerTierParticipationFileSummary = lowerTierParticipation?.lowerTierParticipationFileSummary;

  const initialLowerTierSummary: LowerTierFileSummary[] = [];

  lowerTierParticipationFileSummary?.forEach((summary) => {
    initialLowerTierSummary.push({
      id: summary?.id,
      Agency: summary?.smallBusinessAgency?.id as string,
      Classifications: summary?.lowerTierParticipationClassifications.map(
        (classification) => classification.smallBusinessClassification?.id,
      ) as string[],
      Documentation: [summary?.file],
    });
  });

  const [lowerTierFileSummary, setLowerTierFileSummary] = useState<LowerTierFileSummary[]>([]);
  const [updatedLowerTierFileSummary, setUpdatedLowerTierFileSummary] = useState<LowerTierFileSummary[]>([]);
  const [filesToAdd, setFilesToAdd] = useState<File[]>([]);
  const [fileSummariesToDeleteById, setFileSummariesToDeleteById] = useState<any[]>([]);
  const [checkIsFormValid, setCheckIsFormValid] = useState<boolean>(false);

  // Adds LowerTierFileSummaryDialog when opening edit dialog
  useEffect(() => {
    const lowerTierFileSummaryCopy: LowerTierFileSummary[] = [];

    lowerTierParticipationFileSummary?.forEach((summary) => {
      lowerTierFileSummaryCopy.push({
        id: summary?.id,
        Agency: summary?.smallBusinessAgency?.id as string,
        Classifications: summary?.lowerTierParticipationClassifications.map(
          (classification) => classification.smallBusinessClassification?.id,
        ) as string[],
        Documentation: [summary?.file],
      });
    });
    setLowerTierFileSummary(lowerTierFileSummaryCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const [editLowerTierParticipation, { loading: isLowerTierParticipationLoading }] =
    useUpsertLowerTierParticipationMutation();

  const smallBusinessAgencyList: SmallBusinessAgency[] | undefined = smallBusinessClient?.smallBusinessClientAgencies
    .map((agency) => agency.smallBusinessAgency)
    .sort((a, b) => a.name.localeCompare(b.name));

  const [updateProject] = useMutation(UPDATE_PROJECT, {
    awaitRefetchQueries: true,
  });
  interface LowerTierParticipationFormValues {
    lowerTierParticipationId: string | undefined;
    contractId: string | undefined;
    lowerTierCompanyName: string | undefined;
    valueTotal: number | undefined;
    effectiveDate: DateTime | undefined;
  }

  const defaultValues: LowerTierParticipationFormValues = {
    lowerTierParticipationId: lowerTierParticipationId,
    contractId: contractId,
    lowerTierCompanyName: lowerTierCompanyName,
    valueTotal: valueTotal,
    effectiveDate: effectiveDate,
  };

  const { handleSubmit, control, setValue, watch, reset, formState } = useForm({
    defaultValues,
  });

  const handleAddLowerTierFileSummary = () => {
    let newFileSummaryInputs = {
      transactionKey: generateTransactionKey(),
      Agency: undefined,
      Classifications: [],
      Documentation: [],
    };
    setLowerTierFileSummary([...lowerTierFileSummary, newFileSummaryInputs]);
    setUpdatedLowerTierFileSummary([...lowerTierFileSummary, newFileSummaryInputs]);
  };

  const handleRemoveLowerTierFileSummary = (index: number) => {
    const lowerTierFileSummariesToKeep = lowerTierFileSummary.filter(
      (summary) => summary.id !== lowerTierFileSummary[index].id,
    );
    const lowerTierFileSummariesToDelete = lowerTierFileSummary
      .filter((summary) => summary.id === lowerTierFileSummary[index].id)
      .map((deletedSummary) => {
        return {
          id: deletedSummary.id,
        };
      });

    setLowerTierFileSummary(lowerTierFileSummariesToKeep);
    setFileSummariesToDeleteById(lowerTierFileSummariesToDelete);
    setCheckIsFormValid(true);
  };

  const handleLowerTierFileSummaryChange = (value: any, idx: number, key: keyof LowerTierFileSummary) => {
    let lowerTierFileSummaryCopy: LowerTierFileSummary[] = lowerTierFileSummary;

    if (key === 'Agency') {
      lowerTierFileSummaryCopy[idx][key] = smallBusinessAgencyList?.find((agency) => {
        return agency?.id === value;
      })?.id;
    } else if (key === 'Classifications') {
      if (Array.isArray(value)) {
        lowerTierFileSummaryCopy[idx][key] =
          value?.map((classification: PickedSmallBusinessClassification) => classification?.id) ?? [];
      }
    } else if (key === 'Documentation') {
      if (Array.isArray(value)) {
        lowerTierFileSummaryCopy[idx][key] = value?.filter((file: File | UploadedFile) => file) ?? [];
      }
    } else {
      return value;
    }
    setLowerTierFileSummary(lowerTierFileSummaryCopy);
  };

  const { isDirty } = formState;

  useEffect(() => {
    //  using this if statement because even though typescript thinks its a MaterialUiPickersDate its for some reason a string
    if (typeof effectiveDate == 'string') {
      setValue('effectiveDate', DateTime.fromISO(effectiveDate));
    } else {
      setValue('effectiveDate', effectiveDate);
    }
    setValue('lowerTierCompanyName', lowerTierCompanyName ?? '');
    setValue('contractId', contractId ?? '');
    setValue('valueTotal', valueTotal);
    setValue('lowerTierParticipationId', lowerTierParticipationId ?? '');
  }, [lowerTierCompanyName, contractId, effectiveDate, lowerTierParticipationId, valueTotal, setValue]);

  const smallBusinessClassificationIdByName = allSmallBusinessClassifications?.reduce(
    (accumulator: any, classification: PickedSmallBusinessClassification) => {
      accumulator[classification.name] = classification.id;
      return accumulator;
    },
    {},
  );

  const lbId =
    smallBusinessClassificationIdByName && smallBusinessClassificationIdByName[SmallBusinessClassificationName.LB];

  const selectedLowerTierCompanyName = watch('lowerTierCompanyName') ?? undefined;
  const selectedValue = watch('valueTotal');
  const selectedEffectiveDate = watch('effectiveDate');

  const handleClose = () => {
    if (typeof effectiveDate == 'string') {
      reset({
        effectiveDate: DateTime.fromISO(effectiveDate),
        lowerTierParticipationId: lowerTierParticipationId,
        contractId: contractId,
        lowerTierCompanyName: lowerTierCompanyName,
        valueTotal: valueTotal,
      });
    } else {
      reset(defaultValues);
    }
    setIsOpen(false);
    setLowerTierFileSummary(initialLowerTierSummary);
    setFilesToAdd([]);
    setFileSummariesToDeleteById([]);
  };

  const hasClassifications = updatedLowerTierFileSummary.map((summary) => {
    if (summary.Classifications.length > 0) {
      return true;
    }
    return false;
  });

  const haveAgencyAndClassificationsChanged = initialLowerTierSummary.every((summary) => {
    return updatedLowerTierFileSummary.some((value) => {
      return (
        value.Agency !== summary.Agency ||
        JSON.stringify(value.Classifications) !== JSON.stringify(summary.Classifications)
      );
    });
  });

  const hasFiles = () => {
    if (filesToAdd.length) {
      return true;
    }
    if (updatedLowerTierFileSummary.some((updatedFileSummary) => updatedFileSummary.Documentation.length === 0)) {
      return false;
    }
    return !lowerTierParticipationFileSummary?.every((fileSummary) => {
      return fileSummariesToDeleteById?.some((deletedFileSummaryById) => {
        return deletedFileSummaryById.id === fileSummary.id;
      });
    });
  };

  const haveFilesChanged = () => {
    if (filesToAdd.length && fileSummariesToDeleteById.length) {
      return true;
    }
    return false;
  };

  const isSelectedValueValid = (value: string | number | undefined) => {
    return typeof value === 'string'
      ? +value?.replace(/,/g, '') <= contractValueTotal
      : selectedValue && selectedValue <= contractValueTotal;
  };

  const isFormValid = (): boolean => {
    if (
      hasFiles() &&
      selectedLowerTierCompanyName &&
      !hasClassifications.includes(false) &&
      selectedEffectiveDate !== null &&
      selectedEffectiveDate?.isValid &&
      isSelectedValueValid(selectedValue)
    ) {
      return true;
    }
    return false;
  };

  const onSubmit: SubmitHandler<LowerTierParticipationFormValues> = (data) => {
    const fileIndex = 0;

    if (!contractId) {
      displayToast(
        'Error: Something went wrong while trying to update the contract information. Please contact support.',
        'error',
      );
      return;
    }

    const value = data.valueTotal?.toString() as string;
    const smallBusinessClassificationIds: string[] = [];

    const lowerTierParticipationFileSummaryCopy: NestedLowerTierParticipationFileSummary[] = [];

    lowerTierFileSummary.forEach((summary) => {
      !summary.Agency
        ? smallBusinessClassificationIds.push(lbId)
        : smallBusinessClassificationIds.push(...summary.Classifications);

      const formattedFile =
        summary.Documentation[fileIndex]?.__typename === 'UploadedFile'
          ? new File(['file'], summary.Documentation[fileIndex]?.name, {
              type: summary.Documentation[fileIndex]?.contentType,
            })
          : [];

      lowerTierParticipationFileSummaryCopy.push({
        id: summary.id,
        transactionKey: summary.transactionKey,
        file: !summary.id ? filesToAdd[fileIndex] : formattedFile,
        smallBusinessAgencyId: summary.Agency || null,
        lowerTierParticipationClassifications: !summary.Agency
          ? [{ smallBusinessClassificationId: lbId }]
          : summary.Classifications.map((classification: string) => {
              return {
                smallBusinessClassificationId: classification,
              };
            }) ?? [],
      });
    });

    editLowerTierParticipation({
      variables: {
        input: {
          id: lowerTierParticipationId,
          contractId: contractId,
          value: data.valueTotal ? parseFloat(value.replaceAll(',', '')) : 0,
          lowerTierCompanyName: data.lowerTierCompanyName ?? '',
          effectiveDate: data.effectiveDate?.toISODate() ?? null,
          filesToAdd: lowerTierParticipationFileSummaryCopy ?? [],
          fileSummaryToDelete: fileSummariesToDeleteById ?? [],
        },
      },
    })
      .then(() => {
        const classificationsToAdd = smallBusinessClassificationIds?.map((id) => {
          return {
            smallBusinessClassificationId: id,
            goalPercent: 0,
          };
        }) as PickedSmallBusinessReportClassificationSummary[];

        const existingClassifications: PickedSmallBusinessReportClassificationSummary[] = [];

        smallBusinessReportClassificationSummaries
          ?.filter((classification) => classification?.name !== SmallBusinessClassificationName.SBT)
          .forEach((classification) => {
            existingClassifications.push({
              smallBusinessClassificationId: classification.smallBusinessClassificationId,
              goalPercent: parseFloat(`${classification.goalPercent}`),
            });
          });

        let classifications: PickedSmallBusinessReportClassificationSummary[] = [];
        if (existingClassifications) {
          classifications = existingClassifications;
        }

        if (classificationsToAdd) {
          classifications = classificationsToAdd;
        }

        if (classificationsToAdd && existingClassifications) {
          classifications = classificationsToAdd.concat(existingClassifications);
        }

        const classificationsToRemove: string[] = [];

        smallBusinessReportClassificationSummaries?.forEach((summary) => {
          return summary.goalPercent === 0 &&
            summary?.name !== SmallBusinessClassificationName.LB &&
            summary?.name !== SmallBusinessClassificationName.SBT &&
            summary.lowerTierParticipationIds?.includes(lowerTierParticipationId as string) &&
            !smallBusinessClassificationIds.includes(summary.smallBusinessClassificationId)
            ? classificationsToRemove.push(summary.smallBusinessClassificationId)
            : null;
        });

        updateProject({
          variables: {
            input: {
              id: projectId,
              smallBusinessReportSettings: {
                id: smallBusinessReportSettings?.id,
                classifications: classifications?.filter((classification) => {
                  return !classificationsToRemove.includes(String(classification.smallBusinessClassificationId));
                }),
              },
            },
          },
        }).then(() => {
          apolloClient.reFetchObservableQueries();
        });
      })
      .then(() => {
        displayToast('The lower tier participation information was updated successfully', 'success');
        handleClose();
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying to update the lower tier participation information. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <StyledDialog
      title={'Lower Tier Participation Contract Info'}
      isLoading={isLowerTierParticipationLoading}
      content={
        <Grid container>
          <Grid sx={formStyles}>
            <form>
              <Grid item>
                <Grid item>
                  <Typography sx={infoLabel}>Company Name</Typography>
                </Grid>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size="small"
                      error={!selectedLowerTierCompanyName}
                    />
                  )}
                  name="lowerTierCompanyName"
                  control={control}
                />
                <Grid sx={errorMessageStyle}>{!selectedLowerTierCompanyName ? 'Company is required' : null}</Grid>
              </Grid>
              <Grid container>
                <Grid item sx={dateContainer}>
                  <Grid item>
                    <Typography sx={infoLabel}>Effective Date</Typography>
                  </Grid>
                  <Controller
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <StyledDatePicker
                        value={value ? convertUTCDateToLocalDate(value) : null}
                        handleDateChange={(newDate) => {
                          onChange(newDate);
                        }}
                        shouldDisplayAsError={!!error || !selectedEffectiveDate}
                      />
                    )}
                    name="effectiveDate"
                    control={control}
                    rules={{
                      required: false,
                    }}
                  />
                  <Grid sx={errorMessageStyle}>{!selectedEffectiveDate ? 'Date is required' : null}</Grid>
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography sx={infoLabel}>Total</Typography>
                  </Grid>
                  <Controller
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        sx={valueTotalInput}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowLeadingZeros={false}
                        isNumericString={true}
                        thousandSeparator={true}
                        customInput={TextField}
                        margin="dense"
                        variant="outlined"
                        color="primary"
                        error={!selectedValue}
                        required={true}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    )}
                    name="valueTotal"
                    control={control}
                    rules={{ required: true, min: 0, max: contractValueTotal }}
                  />
                  <Grid sx={errorMessageStyle}>
                    {!selectedValue
                      ? 'Total is required'
                      : isSelectedValueValid(selectedValue) === false
                      ? `Total cannot be greater than the contract total: $${contractValueTotal}`
                      : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={errorMessageStyle}>
                {!lowerTierFileSummary.length ? 'Agency/ Documentation is required' : null}
              </Grid>
              <Grid item>
                {lowerTierFileSummary?.map((fileSummary, index) => {
                  return (
                    <LowerTierFileSummaryDialog
                      key={fileSummary.id ?? fileSummary.transactionKey}
                      control={control}
                      lowerTierFileSummary={fileSummary}
                      handleLowerTierFileSummaryChange={handleLowerTierFileSummaryChange}
                      lowerTierSummaryIndex={index}
                      smallBusinessAgencyList={smallBusinessAgencyList}
                      allSmallBusinessClassifications={allSmallBusinessClassifications}
                      handleRemoveLowerTierFileSummary={handleRemoveLowerTierFileSummary}
                      handleLowerTierFileSummaryInputFields={(
                        lowerTierFileSummaryInput: LowerTierFileSummary[],
                        addedFileValue: File[],
                      ) => {
                        setUpdatedLowerTierFileSummary(lowerTierFileSummaryInput);
                        setFilesToAdd(addedFileValue);
                      }}
                      isLowerTierDialogOpen={isOpen}
                      isAddingLowerTierParticipation={false}
                    />
                  );
                })}
              </Grid>
            </form>
          </Grid>
        </Grid>
      }
      actions={
        <>
          <Grid container justifyContent="center">
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <StyledButtonSecondary
                    disabled={isLowerTierParticipationLoading}
                    label={'cancel'}
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item>
                  <StyledButtonPrimary
                    label={'submit'}
                    type="submit"
                    disabled={
                      !isFormValid() ||
                      isLowerTierParticipationLoading ||
                      (!isDirty && !haveFilesChanged() && !haveAgencyAndClassificationsChanged && !checkIsFormValid) ||
                      !updatedLowerTierFileSummary.length
                    }
                    onClick={handleSubmit(onSubmit)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={addAgencyAndDocumentationButton} justifyContent={'flex-end'}>
            <Grid item>
              <StyledButtonSecondary
                disabled={isLowerTierParticipationLoading}
                label={'Add Agency/ Documentation'}
                onClick={handleAddLowerTierFileSummary}
              />
            </Grid>
          </Grid>
        </>
      }
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

export default EditLowerTierParticipationDialog;
