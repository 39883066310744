import MercuryFileDisplayImage from 'components/MercuryFileDisplayImage';
import PdfViewer from 'components/PdfViewer';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import { FC, MouseEvent } from 'react';
import { LowerTierParticipationClassificationSummary } from 'types/generated/graphql';
import { isPdfFile } from 'utils/general';

import { Grid, Typography } from '@mui/material';

type ViewLowerTierParticipationDialogProps = {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  lowerTierParticipation?: LowerTierParticipationClassificationSummary;
};

const ViewLowerTierParticipationDialog: FC<ViewLowerTierParticipationDialogProps> = ({
  isOpen,
  setIsOpen,
  lowerTierParticipation,
}) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const lowerTierParticipationFile = lowerTierParticipation?.lowerTierParticipationFileSummary?.map(
    (file) => file.file,
  );

  const handleFileDownloads = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    lowerTierParticipationFile?.forEach((file) => {
      const link = document.createElement('a');
      link.href = file.cachedUrl as string;
      link.setAttribute('download', file.name);
      link.click();
    });
  };

  const agencyName = lowerTierParticipation?.lowerTierParticipationFileSummary?.map(
    (file) => file.smallBusinessAgency?.name,
  ) as string[];

  return (
    <StyledDialog
      title={`${lowerTierParticipation?.lowerTierCompanyName} Documentation`}
      content={lowerTierParticipationFile?.map((file, index) => (
        <>
          <Typography>{agencyName[index]}</Typography>
          {isPdfFile(file) ? <PdfViewer file={file} /> : <MercuryFileDisplayImage file={file} />}
        </>
      ))}
      actions={
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <StyledButtonSecondary disabled={false} label={'cancel'} onClick={handleClose} />
              </Grid>
              <Grid item>
                <StyledButtonSecondary onClick={(event) => handleFileDownloads(event)} label={'download'} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      disableEscapeKeyDown={true}
    />
  );
};

export default ViewLowerTierParticipationDialog;
