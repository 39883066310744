import ReloadWindowButton from 'components/ReloadWindowButton';
import StyledButtonPrimary from 'components/shared/ButtonPrimary';
import StyledButtonSecondary from 'components/shared/ButtonSecondary';
import StyledDialog from 'components/shared/Dialog';
import StyledNotice, { NoticeTypeOption } from 'components/shared/Notice';
import { federalSmallBusinessReportOptions } from 'constants/federalSmallBusinessReportOptions';
import { SmallBusinessRequirementOption } from 'constants/smallBusinessRequirementOptions';
import { SAVE_SMALL_BUSINESS_REPORT } from 'graphql/smallBusinessReport';
import useToast from 'hooks/useToast';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Maybe,
  ProjectSmallBusinessReport,
  SmallBusinessReportSettings,
} from 'types/generated/graphql';
import { generateTransactionKey } from 'utils/general';

import { useMutation } from '@apollo/client';
import {
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

type SmallBusinessReportSaveDialogProps = {
  smallBusReportSaveDialogIsOpen: boolean;
  setSmallBusReportSaveDialogIsOpen: any;
  selectedReportOption?: string;
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  report: ProjectSmallBusinessReport;
  shouldShowTechnicalServicesMessage: boolean;
  smallBusinessReportSettings?: Maybe<SmallBusinessReportSettings> | undefined;
  commentsShouldBeRequired?: boolean;
  smallBusinessRequirements?: string | null;
};

const defaultValues = {
  type: '',
  form: '',
  comments: '',
};

type SaveDialogInput = {
  type?: string;
  form?: string;
  comments?: string;
};

const reportOptionAndRadioGroup: SxProps<Theme> = (theme: Theme) => ({
  marginLeft: theme.spacing(3),
});

const radioLabel: SxProps<Theme> = (theme: Theme) => ({
  fontSize: '0.875rem',
  marginTop: theme.spacing(0.5),
});

const SmallBusReportSaveDialog: FC<SmallBusinessReportSaveDialogProps> = ({
  smallBusReportSaveDialogIsOpen,
  setSmallBusReportSaveDialogIsOpen,
  selectedStartDate,
  selectedEndDate,
  selectedReportOption,
  shouldShowTechnicalServicesMessage,
  report,
  smallBusinessReportSettings,
  commentsShouldBeRequired,
  smallBusinessRequirements,
}: SmallBusinessReportSaveDialogProps) => {
  const [transactionKey, setTransactionKey] = useState(generateTransactionKey());
  const { handleSubmit, control, setValue, reset, formState, watch } = useForm<SaveDialogInput>({ defaultValues });
  const { isValid } = formState;
  const { displayToast } = useToast();
  const isFederalReport = smallBusinessRequirements === SmallBusinessRequirementOption.FEDERAL;

  const comments = watch('comments');

  const shouldShowCommentsRequiredWarning = comments === '';

  setValue('form', selectedReportOption);

  const [saveReport, { loading: isLoading }] = useMutation(SAVE_SMALL_BUSINESS_REPORT);

  const selectedReportOptionName = federalSmallBusinessReportOptions.find(
    (option) => option.value === selectedReportOption,
  )?.name;

  const selectedReportType = watch('type');

  const handleClose = () => {
    reset(defaultValues);
    setTransactionKey(generateTransactionKey());
    setSmallBusReportSaveDialogIsOpen(false);
  };

  const onSubmit: SubmitHandler<SaveDialogInput> = (data) => {
    return saveReport({
      variables: {
        comments: data.comments ?? undefined,
        smallBusinessReportSettingsId: smallBusinessReportSettings?.id,
        startDate: isFederalReport ? undefined : selectedStartDate,
        endDate: isFederalReport ? undefined : selectedEndDate,
        form: isFederalReport ? data.form : undefined,
        transactionKey: transactionKey,
        type: data.type,
      },
    })
      .then((result) => {
        const originalChecksum = report.checksum;
        const newChecksum = result?.data?.saveProjectSmallBusinessReport?.checksum;
        if (originalChecksum === newChecksum) {
          displayToast('The small business report has been saved', 'success');
        } else {
          displayToast(
            "Note: The saved report's contents are more current than the one you were viewing. You can review the updated report by reloading",
            'warning',
            ReloadWindowButton,
          );
        }
        handleClose();
      })
      .catch(() => {
        displayToast(
          'Error: Something went wrong while trying save the report. Please try again. If the problem persists, please contact support.',
          'error',
        );
      });
  };

  return (
    <>
      <StyledDialog
        title={'Save Report'}
        isOpen={smallBusReportSaveDialogIsOpen}
        handleClose={handleClose}
        isLoading={isLoading}
        content={
          <Grid container>
            <Grid item>
              <Grid container>
                {shouldShowTechnicalServicesMessage && (
                  <StyledNotice
                    type={NoticeTypeOption.Notice}
                    message={
                      <Typography>
                        If this project includes LBE Technical Services Contracts (architect-engineer) greater than
                        $750,000, a small business subcontracting plan from these companies must be uploaded to the
                        Small Business site on the Employee Portal.
                      </Typography>
                    }
                  />
                )}
                {selectedReportType === 'Final' && selectedReportOption && (
                  <StyledNotice
                    type={NoticeTypeOption.Notice}
                    message={
                      <Typography>When completing a final report, both a 294 and 295 must be submitted.</Typography>
                    }
                  />
                )}
                <Grid item xs={12}>
                  <Typography>Type</Typography>
                  <Controller
                    render={({ field }) => (
                      <RadioGroup aria-label="type" {...field} sx={reportOptionAndRadioGroup}>
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <FormControlLabel
                              value="Regular"
                              control={<Radio size="small" color="primary" />}
                              label={<Typography sx={radioLabel}>Regular</Typography>}
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              value="Revised"
                              control={<Radio size="small" color="primary" />}
                              label={<Typography sx={radioLabel}>Revised</Typography>}
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              value="Final"
                              control={<Radio size="small" color="primary" />}
                              label={<Typography sx={radioLabel}>Final</Typography>}
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    )}
                    name="type"
                    control={control}
                    rules={{ required: true }}
                  />
                  <br />
                  <Typography>Form</Typography>
                  <Typography sx={reportOptionAndRadioGroup}>
                    {selectedReportOptionName} (Determined by report option selected)
                  </Typography>
                  <br />
                  <InputLabel>Comments</InputLabel>
                  <Controller
                    render={({ field }) => <TextField {...field} variant="outlined" fullWidth multiline />}
                    name="comments"
                    control={control}
                    rules={{ required: commentsShouldBeRequired }}
                  />
                  {commentsShouldBeRequired && shouldShowCommentsRequiredWarning && (
                    <>
                      <StyledNotice
                        type={NoticeTypeOption.Notice}
                        message={<Typography>Comments are required when project goals are not being met</Typography>}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        actions={
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <StyledButtonSecondary disabled={isLoading} label={'cancel'} onClick={handleClose} />
                </Grid>
                <Grid item>
                  <StyledButtonPrimary
                    label={'submit'}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isValid || isLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default SmallBusReportSaveDialog;
