import BackToTopButton from 'components/shared/BackToTopButton';
import ErrorNotice from 'components/shared/ErrorNotice';
import LoadingSpinner from 'components/shared/LoadingSpinner/LoadingSpinner';
import SmallBusinessClassificationDisplayName from 'components/shared/SmallBusinessClassificationDisplayName';
import StyledTable from 'components/shared/Table';
import { GET_PAGINATED_COMPANIES } from 'graphql/companies';
import useDebounce from 'hooks/useDebounce';
import useTablePagination from 'hooks/useTablePagination';
import { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableState } from 'mui-datatables';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCompanyListTableState } from 'recoil/companyListTableState';
import { CompanyListTableState } from 'recoil/companyListTableState/atom';
import { Company, useGetSmallBusinessClassificationsQuery } from 'types/generated/graphql';
import { getFilterByColumnName } from 'utils/muiDataTablesHelper';

import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';

const CompanyListPage: FC = () => {
  const history = useHistory();
  const [companyListTableState, { handleCompanyListTableStateChange: handleTableStateChange }] =
    useCompanyListTableState();
  const [initialState] = useState(companyListTableState);

  const {
    error: companyError,
    data: companyData,
    loading: companyLoading,
    fetchMore,
  } = useQuery(GET_PAGINATED_COMPANIES, {
    variables: {
      first: initialState.first,
      skip: initialState.skip,
      filter: initialState.filter,
      search: initialState.search,
      order: initialState.order,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const { error: smallBusinessClassificationError, data: smallBusinessClassificationData } =
    useGetSmallBusinessClassificationsQuery();

  const companies = companyData?.companyListResult.items ?? [];

  const federalSmallBusinessClassifications =
    smallBusinessClassificationData?.smallBusinessClassificationList.filter(
      (classification) => classification.isFederal !== false,
    ) ?? [];
  const nonFederalSmallBusinessClassifications =
    smallBusinessClassificationData?.smallBusinessClassificationList.filter(
      (classification) => classification.isFederal !== true,
    ) ?? [];

  const handleFilterChange = ({
    muiTableState,
    state,
  }: {
    muiTableState: MUIDataTableState;
    state: CompanyListTableState;
  }) => {
    const federalClassificationsFilter = getFilterByColumnName<Company>(
      muiTableState,
      'federalSmallBusinessClassifications',
    ).map((filterItem) => JSON.parse(filterItem).id);

    const nonFederalClassificationsFilter = getFilterByColumnName<Company>(
      muiTableState,
      'smallBusinessClassifications',
    ).map((filterItem) => JSON.parse(filterItem).id);

    let selectedFilters = {};

    if (federalClassificationsFilter.length > 0) {
      selectedFilters = {
        ...selectedFilters,
        federalSmallBusinessClassificationList: {
          booleanOperator: 'OR',
          items: federalClassificationsFilter,
        },
      };
    }
    if (nonFederalClassificationsFilter.length > 0) {
      selectedFilters = {
        ...selectedFilters,
        smallBusinessClassificationList: {
          booleanOperator: 'OR',
          items: nonFederalClassificationsFilter,
        },
      };
    }

    handleTableStateChange({
      ...state,
      filter: selectedFilters,
      filterList: muiTableState.filterList,
      skip: 0,
      page: 0,
    });

    fetchMore({
      variables: {
        search: state.search,
        filter: selectedFilters,
        skip: 0,
        order: state.order,
      },
    });
  };

  const { debounced: debouncedHandleFilterChange } = useDebounce(handleFilterChange);

  const [companyListTableOptions] = useTablePagination({
    state: companyListTableState,
    handleTableStateChange: handleTableStateChange,
    fetchMore: fetchMore,
    handleFilterChange: debouncedHandleFilterChange,
  });
  companyListTableOptions.sortFilterList = false;

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Company Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'federalSmallBusinessClassifications',
      label: 'Federal Classifications',
      options: {
        filter: true,
        filterType: 'multiselect',
        filterOptions: {
          names: federalSmallBusinessClassifications.map((classification) => JSON.stringify(classification)),
          renderValue: (value) => JSON.parse(value).abbreviation,
          fullWidth: true,
        },
        customFilterListOptions: { render: (value) => `Federal Classification: ${JSON.parse(value).abbreviation}` },
        filterList: companyListTableState.filterList[2]?.length ? companyListTableState.filterList[2] : undefined,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const companyClassifications = [...(companies[dataIndex].federalSmallBusinessClassifications || [])];
          if (!companyClassifications.length) {
            companyClassifications.push({
              id: 'none',
              abbreviation: 'none',
              name: 'no classifications',
              sortOrder: -1,
            });
          }
          return (
            <Grid container direction="row" spacing={1}>
              {companyClassifications
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((classification, index, array) => {
                  return (
                    <Grid item key={classification.id}>
                      <SmallBusinessClassificationDisplayName
                        classification={classification}
                        displayFormat="abbreviation"
                        tooltipFormat="name"
                        displayFormatExtension={(formattedName) =>
                          `${formattedName}${index === array.length - 1 ? '' : ','}`
                        }
                      />
                    </Grid>
                  );
                })}
            </Grid>
          );
        },
      },
    },
    {
      name: 'smallBusinessClassifications',
      label: 'Non-Federal Classifications',
      options: {
        filter: true,
        filterType: 'multiselect',
        filterOptions: {
          names: nonFederalSmallBusinessClassifications.map((classification) => JSON.stringify(classification)),
          renderValue: (value) => JSON.parse(value).abbreviation,
          fullWidth: true,
        },
        customFilterListOptions: { render: (value) => `Non-Federal Classification: ${JSON.parse(value).abbreviation}` },
        filterList: companyListTableState.filterList[3]?.length ? companyListTableState.filterList[3] : undefined,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const companyClassifications = [...(companies[dataIndex].smallBusinessClassifications || [])];
          if (!companyClassifications.length) {
            companyClassifications.push({
              id: 'none',
              abbreviation: 'none',
              name: 'no classifications',
              sortOrder: -1,
            });
          }
          return (
            <Grid container direction="row" spacing={1}>
              {companyClassifications
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((classification, index, array) => {
                  return (
                    <Grid item key={classification.id}>
                      <SmallBusinessClassificationDisplayName
                        classification={classification}
                        displayFormat="abbreviation"
                        tooltipFormat="name"
                        displayFormatExtension={(formattedName) =>
                          `${formattedName}${index === array.length - 1 ? '' : ','}`
                        }
                      />
                    </Grid>
                  );
                })}
            </Grid>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...companyListTableOptions,
    count: companyData?.companyListResult?.count,
    rowsPerPageOptions: [],
    onRowClick: (_, { dataIndex }) => {
      history.push(`/companies/${companies[dataIndex].id}`);
    },
    download: false,
  };

  if (companyError) {
    console.error(companyError);
    return <ErrorNotice />;
  }

  if (companyLoading) {
    return <LoadingSpinner />;
  }

  if (smallBusinessClassificationError) {
    console.error(smallBusinessClassificationError);
    return <ErrorNotice />;
  }

  return (
    <>
      <StyledTable title="Company List" data={companies} columns={columns} options={options} />
      <BackToTopButton />
    </>
  );
};

export default CompanyListPage;
