import useRoles from 'hooks/useRoles';
import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Grid,
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

interface LinkProps {
  onClick: any;
  to: string;
  label: string;
}

const list: SxProps<Theme> = {
  width: 240,
};

const smallBusinessReportLink: SxProps<Theme> = {
  textDecoration: 'none',
};

const SideNavigationMenuList = ({ closeDrawer }: any) => {
  const MercuryMuiLink = ({ onClick, to, label }: LinkProps) => {
    return (
      <MuiLink component={RouterLink} onClick={onClick} to={to} underline="none">
        <ListItem button>
          <ListItemText>
            <Typography color="textPrimary">{label}</Typography>
          </ListItemText>
        </ListItem>
      </MuiLink>
    );
  };
  const ExternalLink = ({ onClick, to, label }: LinkProps) => {
    return (
      <Box component="a" onClick={onClick} href={to} target="_blank" rel="noreferrer" sx={smallBusinessReportLink}>
        <ListItem button>
          <ListItemText>
            <Typography color="textPrimary">{label}</Typography>
          </ListItemText>
        </ListItem>
      </Box>
    );
  };

  const { isEnterpriseAdmin } = useRoles();

  const masterProjectListPageRouteLink = '/projects';
  const masterCompanyListPageRouteLink = '/companies';
  const masterAdminPageRouteLink = '/adminSettings';
  const samDotGovPageLink = 'https://sam.gov/';

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <List component="nav" sx={list}>
          <Fragment>
            <MercuryMuiLink onClick={closeDrawer} to={masterProjectListPageRouteLink} label="Project List" />
            <MercuryMuiLink onClick={closeDrawer} to={masterCompanyListPageRouteLink} label="Company List" />
            {isEnterpriseAdmin && (
              <MercuryMuiLink onClick={closeDrawer} to={masterAdminPageRouteLink} label="Admin Settings" />
            )}
            <ExternalLink onClick={closeDrawer} to={samDotGovPageLink} label="SAM.gov" />
          </Fragment>
        </List>
      </Grid>
    </Grid>
  );
};

export default SideNavigationMenuList;
